.search-results{
	

	h2{
		font-size:18px !important;
		color:#000000 !important;
		font-weight:300 !important;
		margin-bottom:100px;

	}


	h3{
		font-size:22px !important;
		color:#000000;
		margin-bottom:10px;
	}

	p{
		color:#000000;
	}

	a{
		color:#000000 !important;
		margin-top:-10px;
		display:block;
		//opacity:0.5;
	}

	a:hover{
		color:#F76E83 !important;
		opacity:1;
		text-decoration:underline;

	}

	.search-result{
		margin-bottom:70px;
	}


	max-width:1000px;
	margin:0pt auto;
	margin-top:40px;
	margin-bottom:100px;


}



.article-page, .rte-content{
	

	h2{
		font-size:30px !important;
		color:#000000 !important;
		font-weight:bold !important;
		margin-bottom:25px;
	}


	h3{
		font-size:26px !important;
		color:#000000;
		margin-bottom:25px;
	}

	p{
		color:#000000;
        margin-bottom: 25px;
	}

	a{
		color:#F76E83 !important;
		margin-top:-10px;
		//display:block;
		//opacity:0.5;
	}

	a:hover{
		color:#000000 !important;
		opacity:1;
		text-decoration:underline;

	}

	.search-result{
		margin-bottom:70px;
	}


	max-width:1000px;
	margin:0pt auto;
	margin-top:20px;
	margin-bottom:100px;


}


