
/**
 * Slideout.js mobile menu styles
 * =================================
 * https://github.com/mango/slideout
 */

.site__slideout{
  display:none;
  background: #FFFFFF;
}


.pink-theme .slideout__logo{
  
  > g{
    fill:rgb(247, 110, 131) !important;
  }

}

.site__wrapper{

  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);


}

body {
  width: 100%;
  height: 100%;
}

.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 256px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none;
}

.slideout-menu-left {
  left: 0;
}

.slideout-menu-right {
  right: 0;
}

.slideout-panel {
  position: relative;
  z-index: 1;
  //will-change: transform;
  background-color: #FFF; /* A background-color is required */
  min-height: 100vh;
  overflow:hidden;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden;
}

.slideout-open .slideout-menu {
  display: block;
}

.js-search-toggle, .js-slideout-toggle{
  transition: all .5s;
  opacity:1;
}

.slideout-open .js-slideout-toggle, .slideout-open .js-search-toggle{  
  transition: all .5s;
  opacity:0;
}


.slideout{
  
  &__header{
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #fff;
    max-width: 1800px;
    width: 100%;
    z-index: 1;
    border-bottom: 1px solid #bababa;
    padding-left:20px;
    padding-right:20px;
    margin-bottom:20px;
  }

  &__nav{
    padding-left:20px;
    padding-right:20px;
  }

  &__logo{

      height:25px;
      color:$brand;
      width:160px;
      padding-top:2px;
      padding-bottom:2px;
      display:block;
      margin-top:4px;

      @include media("<mid") {
          margin-top:0;
      }

  }


  &__close{
    cursor:pointer;
    position:absolute;
    right:17px;
    top:17px;
  }

}