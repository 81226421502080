/**
 * Tables
 * =================================
 * - Associated Table Variables
 * - Default table Styles
 * – Table modifiers:
 *   - .table--striped
 * – .responsiveTable
 */

/**
 * Define associated Table variables
 */
$table-bgColor                : $color-bg !default; // Default background color used for all tables.
$table-bgColor--accent        : #f9f9f9 !default; // Background color used for `.table-striped`.
$table-bgColor--hover         : inherit !default; // Background color used for `.table-hover` – set to 'inherit' by default (so no hover effect)
$table-borderColor            : #ccc !default; // Border color for table and cell borders.

/**
 * Customizes the `.table` component with basic values, each used across all table variations.
 */
$table-cell-padding           : 8px !default; // Padding for `<th>`s and `<td>`s.


// Baseline styles
table {
	width: 100%;
	max-width: 100%;
	margin-bottom: $baseline;
	border-spacing: 0;
	border: 0px solid $table-borderColor;
	//background-color: $table-bgColor;
	table-layout: fixed; // https://css-tricks.com/fixing-tables-long-strings/

	// Cells
	th,
	td {
		padding: $table-cell-padding;
		line-height: $baseline;
		vertical-align: top;
		border: 0px solid $table-borderColor;

		padding: 10px;


		&:first-child {	border-left: 0;	}
		&:last-child {	border-right: 0;	}
	}



	// Bottom align for column headings
	> thead {
		th,
		td {
			vertical-align: bottom;
			border-bottom: 0px solid $table-borderColor;
		}
	}

	> tfoot td {
		border-top: 0px solid $table-borderColor;
		border-bottom: 0;
	}

	// Remove top border from thead by default
	> thead:first-child {
		> tr:first-child {
			> th,
			> td {
				border-top: 0;
			}
		}
	}

	// if a hover color has been set in the variables
	tr:hover {
		td, th {
			//background-color: $table-bgColor--hover;
		}
	}

	tr:nth-child(odd){
		//background: #f7f7f7 !important;
	}



	// Account for multiple tbody instances
	> tbody + tbody {
		border-top: 0px solid $table-borderColor;
	}

	// Nesting
	& table,
	& .table {
		background-color: $color-bg;
	}
}

th {
	text-align: left;
}

table td p:last-child{
	margin-bottom:0px;
}

.pricing-table tr:nth-child(odd){
	//background: white !important;
}

.pricing-table td{
	padding:8px !important;
}

.pricing-table .price-cell--bg span{
  white-space: nowrap;
}

.pricing-table .price-cell {
  width: 110px;
  text-align: right;
}

 
/**
 * Zebra-striping
 * Default zebra-stripe styles (alternating gray and transparent backgrounds)
 */
.table--striped {
	tbody :nth-child(odd) th,
	tbody :nth-child(odd) td {
		background-color: $table-bgColor--accent !important;
	}
}




/**
 * Responsive tables
 * Wrap your tables in `.responsiveTable` and we'll make them mobile friendly by enabling horizontal scrolling. Only applies <768px. Everything above that will display normally.
 *
 * If you need more responsive tables, consider Tablesaw by the Filament Group:
 * https://github.com/filamentgroup/tablesaw
 */
.responsiveTable {
    /*@include media('<mid') {
		width: 100%;
		margin-bottom: ($line-height-base * .75) + px;
		overflow-y: hidden;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid $table-borderColor;
		-webkit-overflow-scrolling: touch;

		// Tighten up spacing
		> table {
			margin-bottom: 0;
			border: 0;
			min-width:768px;

			// Ensure the content doesn't wrap
			> thead,
			> tbody,
			> tfoot {
				th,
				td {
					//white-space: nowrap;

					// Nuke the appropriate borders so that the parent can handle them
					&:first-child {	border-left: 0; }
					&:last-child {	border-right: 0; }
				}
			}

			// Only nuke the last row's bottom-border in `tbody` and `tfoot` since
			// chances are there will be only one `tr` in a `thead` and that would
			// remove the border altogether.
			> tbody,
			> tfoot tr:last-child {
				> th,
				> td {
					border-bottom: 0;
				}
			}
		}
	}*/
    table {
        @media only screen and (max-width: 949px) {
            width: 100% !important;
            max-width: 100% !important;
        }
    }

    thead {
        display: none;

        @media only screen and (min-width: 950px) {
            display: table-header-group;

            th {
                padding: 10px 10px 10px 0;
                border-bottom: 1px solid #e4e4e4;
            }
        }
    }

    tbody tr {
        display: block;

        @media only screen and (max-width: 949px) {
            border-bottom: 1px solid #e4e4e4;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }

        @media only screen and (min-width: 950px) {
            display: table-row;
        }
    }

    td {
        display: block;
        padding: 5px 10px 5px 0;

        &:before {
            content: attr(data-label);
            display: block;
            font-weight: bold;
        }

        @media only screen and (max-width: 949px) {
            width: 100% !important;
            max-width: 100% !important;
        }

        @media only screen and (min-width: 950px) {
            display: table-cell;
            padding: 10px 10px 10px 0;

            &:before {
                display: none;
            }
        }

        ul {
            margin-bottom: 0;
        }
    }
}

.pricing-table{
	tr{
		background: url(../img/line.png) !important;
		background-image: url(../img/line.png) !important;
    	background-repeat: no-repeat !important;
    	background-position: 0px 23px !important;
	}
}

