/**
 * Page sections
 * =================================
 */

.page-section{
	
	//overflow:hidden;

	padding-top:$page-section-gutter;
	
	header{
		max-width:600px;
		margin:0pt auto;
		padding-bottom:$page-section-gutter;

		@include media("<mid") {
			padding-bottom:40px;
		}

		p{
			font-size:24px;
			font-weight:300;
			color:#646464;
			line-height:1.4;
		}

		@include media("<mid") {
			p{
				font-size:20px;
				font-weight:300;
				color:#646464;
				line-height:1.4;
			}
		}

	}

	&--shaded{
		background-color:$grey;
	}

	&--hero{
		padding-top:60px;
		@include media("<mid") {
			padding-top:60px;
		}
	}

	p:last-child{
		margin-bottom:0px;
	}

	&-border--top{
		border-top:1px solid $dark-grey;
	}

	&--baseline{
		padding-bottom:$page-section-gutter;
	}

	&--baseline-double{
		padding-bottom:$page-section-gutter-double;
	}

	&--baseline-large{

		padding-bottom:$page-section-gutter-double;

	    @include media("<mid") {
			padding-bottom:15px !important;
		}
	}

	&--double-gutters{

		padding-top:$page-section-gutter-double !important;

		.g--baseline > .g-col {
		    padding-bottom: $page-section-gutter-double !important;
		}

	}

	&--triple-gutters{

		padding-top:$page-section-gutter-triple !important;

		.g--baseline > .g-col {
		    padding-bottom: $page-section-gutter-triple !important;
		}

	}

	&--gyniilearn{

		background:#F76E83;
		h1{
			color:white !important;
			margin-bottom:15px;

		}
		p{
			color:white !important;
			line-height:1.2 !important;
		}		

		header {
		    max-width: 100%;
		    margin: 0pt auto;
		    padding-bottom: 80px;
		}

		.l-container{
			overflow:hidden;
		}
	}


	&--gyniilearn-o{

		background:$brand;
		
		header {
		    padding-bottom: 80px;
		}

	}

	&--gyniilearn, &--hero{


		@include media("<mid") {
			padding-top:40px !important;
		}

		h1{
			font-size:50px !important;
		}

		p.sub{
			font-size:50px !important;
		}

		p{
			font-size:24px !important;
		}
			

		@include media("<mid") {

		 	h1{
				font-size:40px !important;
			}

			p.sub{
				font-size:40px !important;
			}

			p{
				font-size:22px !important;
			}
		}

		 @include media("<narrow") {

		 	h1{
				font-size:30px !important;
			}

			p.sub{
				font-size:30px !important;
			}

			p{
				font-size:20px !important;
			}
		}
	}

	&--gyniilearn{

		padding-top: 40px !important;

		header{
			padding-bottom:60px !important;
		}

		@include media("<mid") {
			padding-top:40px !important;
		}

		h1{
			font-size:34px !important;
		}

		p.sub{
			font-size:30px !important;
		}

		p{
			font-size:24px !important;
		}
			

		@include media("<mid") {

		 	h1{
				font-size:32px !important;
			}

			p.sub{
				font-size:30px !important;
			}

			p{
				font-size:22px !important;
			}
		}

		 @include media("<narrow") {

		 	h1{
				font-size:30px !important;
			}

			p.sub{
				font-size:30px !important;
			}

			p{
				font-size:20px !important;
			}
		}
	}

}






.home-hero{
	
	background:$brand;
	padding-top:$page-section-gutter;
	padding-bottom:$page-section-gutter-double;

	@include media("<mid") {
		padding-top:40px;
		padding-bottom:80px;
	}

	text-align:center;

	h1, p{
		color:white !important;
	}

	h1{
		font-size:60px !important;

		@include media("<mid") {
			font-size:40px !important;
		}

		@include media("<narrow") {
			font-size:30px !important;
		}

	}

	p{
		font-size:25px;
		line-height:30px;

		@include media("<narrow") {
			font-size:20px;
			line-height:20px;
		}

	}

	&__svg{
		svg{
			width:100%;
		}

		&--desktop{
			@include media("<mid") {
				display:none;
			}
			@include media(">=mid") {
				display:block;
			}
		}

		&--mobile{
			@include media("<mid") {
				display:block;
				padding-top:20px;
				padding-bottom:50px;
				padding-left:10%;
				padding-right:10%;
			}
			@include media(">=mid") {
				display:none;
			}
		}
		

	}

	&__headline{
		padding-left:10%;
		padding-right:15%;
		@include media("<mid") {
			padding-left:0px;
			padding-right:0px;
		}
	}

	&__form{

		width:100%;
		border:2px solid white;

		.form-input, select{
			background:transparent !important;
			color:white !important;
			border:0px solid white !important;
			border-right:2px solid white !important;
			padding-right:50px;
		}

		.g-col{
			padding-bottom:0px !important;
		}
		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: #ffffff;
		}
		::-moz-placeholder { /* Firefox 19+ */
		  color: #ffffff;
		}
		:-ms-input-placeholder { /* IE 10+ */
		  color: #ffffff;
		}
		:-moz-placeholder { /* Firefox 18- */
		  color: #ffffff;
		}
		.form-controlGroup-inputWrapper--select::after {
		  color: #ffffff;
		}

		@include media('>=950px', '<1300px') { 


			.form-input{
				border:0px solid white !important;
				border-bottom:2px solid white !important;
			}

			
			.home-hero__submit{
				height:64px;
			}

			.g-postcode{
				flex-basis: 100% !important;
    			max-width: 100% !important;
			}

			.g-treatment{
				flex-basis: 80% !important;
    			max-width: 80% !important;

    			.form-input{
					border:0px solid white !important;
					border-right:2px solid white !important;
				}

			}

			.g-submit{
				flex-basis: 20% !important;
    			max-width: 20% !important;
			}
			
		}

		@include media('<800px') { 

			.form-input{
				border:0px solid white !important;
				border-bottom:2px solid white !important;
			}

			
			.home-hero__submit{
				height:64px;
			}

			.g-postcode{
				flex-basis: 100% !important;
    			max-width: 100% !important;
			}

			.g-treatment{
				flex-basis: 80% !important;
    			max-width: 80% !important;

    			.form-input{
					border:0px solid white !important;
					border-right:2px solid white !important;
				}

			}

			.g-submit{
				flex-basis: 20% !important;
    			max-width: 20% !important;
			}
			.home-hero__submit{
				height:59px;
			}
			
		}

	}

	&__submit{
		height:100%;
		background-position:center center;
		background-size:25px;
		background-repeat:no-repeat;
		background-image:url('../img/search-icon.svg');
		background-color:transparent;
	}

}


.g--gutterdouble {
    margin-left: -20px;
    margin-right: -20px;
}

.g--gutterdouble > .g-col {
    padding-left: 20px;
    padding-right: 20px;
}





//typograpy responsive sizing.




		h1{
			font-size:50px !important;
		}

		h2{
			font-size:50px !important;
		}

		h3{
			font-size:35px !important;
		}
			

		@include media("<mid") {

		 	h1{
				font-size:40px !important;
			}

			h2{
				font-size:40px !important;
			}

			h3{
				font-size:30px !important;
			}
			
		}

		 @include media("<narrow") {

		 	h1{
				font-size:35px !important;
			}

			h2{
				font-size:30px !important;
			}

			h3{
				font-size:25px !important;
			}
			
		}




		.about-left-image{
			padding-left:40px !important;
			@include media("<mid") {
				padding-left:0px !important;
			}
		}

		.about-right-image{
			padding-right:40px  !important;
			@include media("<mid") {
				padding-left:0px !important;
				padding-right:0px !important;
			}
		}



