/**
 * Form
 * =================================
 */


 .has-floating-label{

    position:relative;

    .form__floating-label {
        position: absolute;
        left: 18px;
        top: 19px;
        pointer-events: none;
        transition: $default-transition;
        font-size:1rem;

        @include media("<mid") {
            top: 15px;
        }

    }

    /*
    label.error {
        position: absolute;
        bottom: 10px;
        left: 18px;
        color: $red;
        font-size:1rem;

    }*/


    input.js-hide-label,
    textarea.js-hide-label {
        ~ .form__floating-label {
            top: 8px;
            left: 18px;
            font-size:13px;

            height:19px;

        }
    }

    input[type=text]{
        padding-top:29px;
        padding-bottom:11px;
    }

}


.form--white{
    label{
        color:#ffffff;
    }

    option{
        color:#000000 !important;
    }

    .has-floating-label .form__floating-label {
        position: absolute;
        left: 18px;
        top: 18px;
        font-size:1rem;

        @include media("<mid") {
            left: 18px;
            top: 17px;
            font-size: 17px;
        }


    }

}


.form__floating-label{
    width:90%;
    overflow:hidden;
    text-align:left;
    height:30px;

     @include media("<mid") {
        height:30px;
    }

}