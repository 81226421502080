/**
 * Footer
 * =================================
 */
.footer {
	
	background-color:$grey;
	padding-top:$page-section-gutter;

	h3{
		font-weight:bold;
		line-height:1.4;
	}



	a{	

		color:black;
		font-weight:300;
		text-decoration:underline;

		&:hover{
			color:$brand;
		}
	}

	p{
	line-height:1.4;
	margin-bottom:20px;
	}


	.l-container > .g > .g-col{
		padding-bottom:$page-section-gutter;
	}


	&-base{

		.g-col{
			padding-bottom:$page-section-gutter-half;
		}

		padding-top:$page-section-gutter-half;
		border-top:1px solid $dark-grey;

		&-trademark{
			color:$dark-grey;
			font-weight:300;

		}

		&-links{
			float:right;

			a{
				margin-left:30px;
				margin-right:0px;
				color:$dark-grey;
				text-decoration:none;
				font-weight:300;
			}

			@include media("<mid") {
				float:left;

				a{
					margin-right:30px;
					margin-left:0px;
				}

			}
		
			
		}

	}

	.contact-header{
		margin-top:-10px;
	}

	.has-floating-label .form__floating-label{
		font-size:16px;
		top:20px;



		@include media(">1200px") {
				

				font-size:1rem;
				top:19px;

			}


			@include media("<950px") {
				

				font-size:1rem;
				top:17px;

			}


	}

}






