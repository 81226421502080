/**
 * Nav(s)
 * Main - dropdown 
 * Mobile - slide out single level 
 * =================================
 */

 $menu-green: $brand;

.nav{
	
	overflow:visible;

	a{
		font-weight:300;
	}

	/**
	 * Main - dropdown 
	 */

	.nav--main{

		list-style:none;
		position:relative;
		margin:0;
		padding:0;
		float:right;

		.dropdown {
			margin-left: 30px;
		}
		  
		.nav__item{
			position:relative;
			margin-left:40px;
			padding-bottom:30px;
			margin-bottom:-30px;
		}
		.nav__link{

			color:$menu-orange;
			font-size:18px;
			padding-top:10px;
			padding-bottom:10px;

			&:hover:after{
				content:'';
				display:block;
				width:100%;
				height:1px;
				background-color:$menu-orange;
				margin-bottom:-1px;
			}

			&.active:after{
				content:'';
				display:block;
				width:100%;
				height:1px;
				background-color:$menu-orange;
				margin-bottom:-1px;
			}

		}

		.sub {
			position: relative;
			margin: 3em;
			padding: 1em;
			box-sizing: border-box;
			background: #ffffff;
			box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
		}

		.sub::before{
		    content: "";
		    position: absolute;
		    width: 30px;
		    height: 30px;
		    top: 0px;
		    left: 20px;
		    box-sizing: border-box;
		    background: white;
		    transform-origin: 0 0;
		    transform: rotate(-45deg);
		    box-shadow: 0px 0px 5px 0 #D8D8D8;
		}

		.sub::after{
		    content: "";
		    position: absolute;
		    width: 50px;
		    height: 25px;
		    top:0px;
		    background:white;
		    left: 20px;
		}

		.sub{
			display:none;
			position:absolute;
			top:calc(100%);
			left:-20px;
			padding:0;
			margin:0px;
			padding:20px;
			background:white;
			background: #FFFFFF;
			box-shadow: 0 0 5px 0 #D8D8D8;
			border-radius: 2px;
			font-size:16px;
			width:250px;

			&__item{
				color:#a0a0a0;
				border-bottom:1px solid #bbbbbb;
				width:100%;
				padding-bottom:5px;
				margin-bottom:10px;
				padding-top:5px;
			}

			&__link{
				color:#bbbbbb;
			}

			&__item:hover{
				border-bottom:1px solid $menu-green;
			}

			&__item:hover .sub__link{
				color:$menu-green;
			}
		}

		/* Show dropdown menu on hover */

		& li:hover > ul {
			display:block;
		}

		/* Hide show header nav items / mobile items (burgers) */

		.nav__item{
			@include media("<mid") {
				display:none;
			}

			&--submenu {
				margin-left: 0 !important;	
			}
		}

		.nav__item--mobile{
			@include media("<mid") {
				display:inline-block;
				margin-left:20px;
				margin-right:0px;
				margin-top:3px;
				margin-bottom:-33px;
			}
			@include media(">=mid") {
				display:none;
			}
		}
	}


	/**
	 * Mobile - slideout 
	 */

	.nav--mobile{
		
		.nav__item{
			
			margin-right:0px;
			margin-top:0px;
			margin-bottom:20px;
			padding-bottom:0px;
			display:block;
		}

		color:#1B978E !important;

		.nav__link{
			color:$menu-mobile-grey;
			font-size:18px;
				width:100%;
				display:block;

			&:after{
			content:'';
				display:block;
				width:100%;
				height:1px;
				background-color:transparent;
				bottom:-10px;
			}

		}

		.nav__link:hover{
			color:$menu-green;
			font-size:18px;

			&:after{
			content:'';
				display:block;
				width:100%;
				height:1px;
				background-color:$menu-green;
				bottom:-10px;
			}
		}
	}
}

	
	