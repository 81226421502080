

.listings-search{
		
		&__form{
			overflow:hidden;
		}

		&__left{
			width: calc(50% - 75px);
			float:left;
			margin-right:20px;

			@include media("<600px") {
				width:100%;
				margin-bottom:15px;
			}
		}

		&__right{
			width: calc(50% - 75px);
			float:left;
			margin-right:20px;

			@include media("<600px") {
				width: calc(100% - 130px);
				margin-bottom:10px;
			}

		}

		&__submit{
			width:110px;
			float:left;
			height:66.5px;

			@include media("<mid") {
				
				height:59px;

			}

			
		}

		

	}
	
.listings{
	
	padding-top:50px;
	padding-bottom:30px;


	@include media("<600px") {
		padding-top:30px;
	}
		

	&__search{
		padding-bottom:30px;

		@include media("<600px") {
			padding-bottom:5px;
		}

	}


	
	.is-sticky{
	.listings__filters{
    border-bottom: 1px solid #bababa;
    background:#fafafa;
    }

    .listings__filters .compare-filters{
    //padding-bottom:10px !important;
    }

	}

	/* Filters */

	&__filters{


    margin-bottom: 20px;
    background: white;
    z-index: 999999 !important;



		margin-bottom:20px;

		.compare-filters{
			
			color:#a0a0a0;

			padding-top:10px;
			padding-bottom:20px;
			text-align:center;
			
			span{
				font-weight:500;
			}

			label {
			  display: inline-block;
			  border-bottom: solid 2px transparent;
			  cursor:pointer;
			}

			label, span{
				margin-left:30px;
				margin-right:30px;
				margin-top:10px;

				@include media("<mid") {
					margin-left:20px;
					margin-right:20px;
				}
				
			}

			input[type="radio"] {
			  display: none;
			}

			input[type="radio"]:checked + label {
			  border-bottom: solid 2px $brand;
			}

			input[type="radio"]:hover + label {
			  border-bottom: solid 2px #aaaaaa;
			}
		}
	}

	/* Results */

	&__results{

		margin-top:40px;

		//min-height:100vh;

		.listing-result{

			&.active{
				border:3px solid #3ADBB2;
			}

			min-height:240px !important;

			position:relative;

			line-height:1.3;
			border:3px solid #ffffff;
			padding:25px;
			padding-bottom:40px;
			overflow:hidden;
			box-shadow: 0px 0px 8px 0px rgba(216,216,216,1);

			margin-bottom:35px;

			.toggle{
				width:50px;
				height:100px;
				float:left;
				position:relative;
				opacity:0;

				.control-group{
					position: absolute;
					top: calc(50% - 10px);
					left: 0px;
				}

			}

			.toggle.show_toggle{
				opacity:1 !important;
			}

			.rating{
				width:300px;
				min-width:300px;
				float:left;
				padding-right:60px;
				text-align:center;

				span{
					font-weight:600;
				}

				&__rating{
					padding-top:10px;
					padding-bottom:10px;
				}

				&__stars{
					padding-left:10px;
					padding-right:10px;
					padding-top:10px;

					max-width: 200px;
				    margin: 0pt auto;
				    margin-bottom: 30px;

				}
			
			}

			.details{
				float:left;
				width: calc(100% - 550px);
				min-width: calc(100% - 550px);
				padding-right:30px;

				&__name{
					font-size:24px;
					font-weight:500;
					padding-bottom:15px;
				}

				&__discount{
					color:$brand;
					padding-bottom:10px;
					font-weight:600;
				}

				&__partner{
					font-weight:300;
					color:#F76E83;
					font-size:18px;
					display:inline-block;
					text-transform:uppercase;
				}

				&__clinic{
					padding-right:30px;
					font-weight:900;
				}

				&__phone{
					padding-bottom:10px;
				}

				&__address{
					padding-bottom:10px;
				}

				&__treatments{
					padding-bottom:10px;
					padding-left:10px;
				}

				&__nhs{
					padding-top:20px;
				}

				.patients{
					float:left;
					padding-right:20px;
					width:240px;

					.Media-body{
						flex-basis:auto !important;
						flex:0;
						
					}

					.Media-figure{
						margin-right:0px;
						margin-left:20px;
					}
				}
			}
		
			.ctas{
				width:200px;
				float:right;
				min-width:200px;

						@include media(">899px") {


				position: absolute;
			    right: 25px;
			    bottom: 40px;

			    }

				&__distance{
					color:#F76E83;
					font-weight:900;
					font-size:17px;
					padding-top:15px;
					padding-bottom:15px;
					background-image:url('../img/location.png');
					background-position:center right;
					background-size:contain;
					background-repeat:no-repeat;
				}

				&__discount{
					display:none !important;
					color:$brand;
					padding-top:20px;
					text-align:center;
					font-weight:600;
				}

				&__book-appointment{
					padding-top:30px;
				}

				&__nhs{
					display:none !important;
				}

			}

			.success{
				width:calc( 100% - 350px );
				min-width:calc( 100% - 350px );
				float:right;

				&__name{
					font-size:24px;
					font-weight:500;
					padding-bottom:15px;
				}

				&__title{
					p{
						margin:0px;
					}
					font-weight:300;
					padding-top:15px;
				}

				&__partner{
					font-weight:300;
					color:#F76E83;
					font-size:18px;
					display:inline-block;
					text-transform:uppercase;
				}

				&__updated{
					padding-bottom:20px;
					font-weight:300;
					color:#000000;
					font-size:14px;
					padding-left:10px;
				}

				&__clinic{
					padding-right:30px;
					font-weight:900;
				}

				&__charts{
					padding-bottom:20px;
				}

				&__footer{
					padding-bottom:20px;

					.success__key{
						width:200px;
						float:left;

						@include media("<1100px") {
							width:100%;
							padding-bottom:20px;
						}

						.success__key__rate {
							padding-left:25px;
							background-image:url('../img/grey_key.svg');
							background-position:center left;
							background-size:15px;
							background-repeat:no-repeat;
						}

						.success__key__rate--orange {
							padding-left:25px;
							background-image:url('../img/orange_key.svg');
							background-position:center left;
							background-size:15px;
							background-repeat:no-repeat;

                                &[data-label-value="0 0 0"] {
                                    display: none;
                                }
						}
			
					}

					.success__buttons{

						width:400px;
						float:right;

						@include media("<1100px") {
							width:100%;
						}

						a{
							width:calc(50% - 5px;);
							float:left;
							display:inline-block;
							margin:0px !important;
						}

						a:last-child{
							float:right;
						}
					}

				}

			}

			.price{
				width:calc( 100% - 350px );
				min-width:calc( 100% - 350px );
				float:right;

				&__name{
					font-size:24px;
					font-weight:500;
					padding-bottom:15px;
				}

				&__partner{
					font-weight:300;
					color:#F76E83;
					font-size:18px;
					display:inline-block;
					text-transform:uppercase;
				}

				&__clinic{
					padding-right:30px;
					font-weight:900;
				}

				&__tables{
					padding-bottom:0px;
					margin-left:-8px;
					margin-top:-8px;
				}


				&__footer{
					padding-bottom:20px;

					.price__buttons{

						width:400px;
						float:right;

						@include media("<1100px") {
							width:100%;
						}

						a{
							width:calc(50% - 5px;);
							float:left;
							display:inline-block;
							margin:0px !important;
						}

						a:last-child{
							float:right;
						}
					}



				}


			}


			@include media("<900px") {

				display:block;

				.toggle{
					display:none !important;
				}

				.rating{
					width:35%;
					min-width:35%;
					padding:0px;
					padding-right:10px;
				}

				.details, .success, .price{
					width:65%;
					min-width:65%;
					padding:0px;
					padding-left:10px;
				}

				.ctas{
					width:100%;
					padding:0px;

					&__distance{
						display:none;
					}

					&__book-appointment{
						a{
							width:calc(50% - 5px;);
							float:left;
							display:inline-block;
							margin:0px !important;
						}

						a:last-child{
							float:right;
						}


					}

				}

			}

			
			@include media("<600px") {

				padding:15px;

				.rating{
					width:100%;
					min-width:100%;
					padding:0px;
				}

				.details{
					width:100%;
					min-width:100%;
					padding:0px;

					&__discount{
						display:none !important;

					}

					&__nhs{
						display:none !important;
					}

				}

				.success, .price{
					width:100%;
					min-width:100%;
					padding:0px;			
				}

				.success-bottom, .price-bottom{
					width:100%;
					min-width:100%;
					float:left;	
					padding:0px;
					padding-top:20px;	
				}

				.ctas{
					&__book-appointment{
						a{
							font-size:14px;
						}

					}

					&__discount{
						display:block !important;
					}

					&__nhs{
						display:block !important;
						width: 100%;
    					float: left;
					}

				}

				.price__buttons, .success__buttons{
					a{
						font-size:14px;
					}
				}

				.patients{
					float:left;
					width:50%;
					padding-bottom:20px;
					padding-top:20px;
					width: calc(50% - 10px);
				}

				.patients:last-child{
					float:right;
					width: calc(50% - 10px);
					padding-bottom:20px;
					padding-top:20px;
				}

			}

			
		}

	}

	/* Pagination */

	&__pagination{

		padding:20px;
		padding-bottom:50px;
		padding-top:50px;
		background-color:rgba(250,250,250,1);
		text-align:center;
		margin-top:50px;

		ul{
			margin:0px !important;
		}

		li{
			font-size:16px;
			display:inline-block;
			padding-left:5px;
			padding-right:5px;
			
			overflow:hidden;

			a{
				height:40px;
				width:40px;
				display:block;
				padding-top:7px;
				color:#aaaaaa;
				overflow:hidden;
				border-radius:50%;
			}

			a.active{
				background:$brand;
				color:#ffffff;
			}

			a:hover{

				background-color:transparent;
				border:1px solid #aaaaaa;
				color:#aaaaaa;
				padding-top:6px;

			}

		}

		.double-prev{
			background-image:url('../img/double-left.svg');
			background-position:center center;
			background-size:contain;
			background-repeat:no-repeat;
			background-size:12px;
		}

		.single-prev{
			background-image:url('../img/left.svg');
			background-position:center center;
			background-size:contain;
			background-repeat:no-repeat;
			background-size:7px;
		}

		.double-next{
			background-image:url('../img/double-right.svg');
			background-position:center center;
			background-size:contain;
			background-repeat:no-repeat;
			background-size:12px;
		}

		.single-next{
			background-image:url('../img/right.svg');
			background-position:center center;
			background-size:contain;
			background-repeat:no-repeat;
			background-size:7px;
		}

		.dot-divider{
			background-image:url('../img/dot_divider.svg');
			background-position:center center;
			background-size:contain;
			background-repeat:no-repeat;
			background-size:15px;
			width:30px;
			height:40px;
			display:inline-block;
		}

	}

}










.help-tip{
	position: absolute;
    left: 35px;
    top: -21px;
    background: #3ADBB2;
    color:#ffffff;
    border: 3px solid #3ADBB2;
    font-size: 17px;
    padding: 10px;
    width: 280px;
    box-shadow: 0 0 12px 0 #D8D8D8;
    z-index:99;
}




.help-tip:after {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(136, 183, 213, 0);
	border-right-color: #3ADBB2;
	border-width: 10px;
	margin-top: -10px;
}


.control-group {

}

.control {
  display: block;
  position: relative;

  cursor: pointer;
  font-size: 18px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border:1px solid #a0a0a0;
  border-radius:50%;

}
.control--radio .control__indicator {
  border-radius: 50%;
}
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  //background: #ccc;
}
.control input:checked ~ .control__indicator {
  //background: #3ADBB2;
  //border-color:#3ADBB2;
}
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  //background: #000000;
}
.control input:disabled ~ .control__indicator {
  background: #3ADBB2;
  opacity: 0.6;
  pointer-events: none;
}
.control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control__indicator:after {
  display: block;
}
.control--checkbox .control__indicator:after {
  left: 3px;
  top: 3px;
  width: 12px;
  height: 12px;
  background:#3ADBB2;
  border-radius:50%;
}
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b;
}
.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff;
}
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b;
}
.select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%;
}
.select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: #e6e6e6;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.select select::-ms-expand {
  display: none;
}
.select select:hover,
.select select:focus {
  color: #000;
  background: #ccc;
}
.select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.select__arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  border-color: #7b7b7b transparent transparent transparent;
}
.select select:hover ~ .select__arrow,
.select select:focus ~ .select__arrow {
  border-top-color: #000;
}
.select select:disabled ~ .select__arrow {
  border-top-color: #ccc;
}


.chart-horizontal {
    width: 100%;
    height: 120px;
    position: relative;
    margin-bottom: 10px;

    &__inner {
        position: absolute;
        width: 100%;
        height: calc( 100% - 30px );
        left: 0px;
        top: 0px;
    }

    &__bar-top {
        position: absolute;
        left: 0%;
        top: 20%;
        width: 100%;
        height: 20%;

        .chart-horizontal__bar {
            width: 50%;
            background: #A0A0A0;
            height: 100%;
            border-radius: 0px 10px 10px 0px;
        }
    }

    &__bar-bottom {
        position: absolute;
        left: 0%;
        top: 60%;
        width: 100%;
        height: 20%;

        .chart-horizontal__bar {
            width: 50%;
            background: #FFA328;
            height: 100%;
            border-radius: 0px 10px 10px 0px;
        }
    }

    &__percentage {
        position: absolute;
        left: 0%;
        top: 0px;
        width: 60px;
        padding-left: 10px;
        font-size: 16px;


        &[data-label-value="0"] {
            display: none;
        }
    }



    &__label {
        position: absolute;
        bottom: 0px;
    }

    &__grid-line-1 {
        position: absolute;
        top: 0px;
        left: 20%;
        width: 1px;
        height: calc( 100% - 30px );
        background-color: #d8d8d8;
    }

    &__grid-line-2 {
        position: absolute;
        top: 0px;
        left: 40%;
        width: 1px;
        height: calc( 100% - 30px );
        background-color: #d8d8d8;
    }

    &__grid-line-3 {
        position: absolute;
        top: 0px;
        left: 60%;
        width: 1px;
        height: calc( 100% - 30px );
        background-color: #d8d8d8;
    }

    &__grid-line-4 {
        position: absolute;
        top: 0px;
        left: 80%;
        width: 1px;
        height: calc( 100% - 30px );
        background-color: #d8d8d8;
    }
}






.chart-vertical{

	width:100%;
	height:250px;
	position:relative;
	margin-bottom:10px;

	&__inner{
		position:absolute;
		width:100%;
		height:calc( 100% - 50px );
		left:0px;
		top:0px;
	}

	&__bar-top{
		position:absolute;
		left:35%;
		bottom:0%;
		width:10%;
		height:100%;

		max-width: 20px;

		.chart-vertical__bar{
			height:50%;
			background:#A0A0A0;
			width:100%;
			border-radius:10px 10px 0px 0px;
			bottom:0px;
			position:absolute;
		}

	}

	&__bar-bottom{
		position:absolute;
		bottom:0%;
		left:55%;
		height:100%;
		width:10%;

		max-width: 20px;

		.chart-vertical__bar{
			height:50%;
			background:#FFA328;
			width:100%;
			border-radius:10px 10px 0px 0px;
			bottom:0px;
			position:absolute;
		}
	}

	&__percentage{
		position:absolute;
		left:0%;
		bottom:0px;
		width:100%;
		text-align:center;
		margin-left:-3px;
		font-size:16px;
		padding-bottom:3px;

		@include media("<mid") {
			font-size:13px;
		}

        &[data-label-value="0"] {
            display: none;
        }

	}



	&__label{
		position:absolute;
		bottom:0px;
		width:100%;
		text-align:center;

	}

	&__grid-line-1{
		position:absolute;
		left:0px;
		top:20%;
		height:1px;
		width:calc( 100% );
		background-color:#d8d8d8;
	}

	&__grid-line-2{
		position:absolute;
		left:0px;
		top:40%;
		height:1px;
		width:calc( 100% );
		background-color:#d8d8d8;
	}

	&__grid-line-3{
		position:absolute;
		left:0px;
		top:60%;
		height:1px;
		width:calc( 100% );
		background-color:#d8d8d8;
	}

	&__grid-line-4{
		position:absolute;
		left:0px;
		top:80%;
		height:1px;
		width:calc( 100% );
		background-color:#d8d8d8;
	}

	&__grid-line-5{
		position:absolute;
		left:0px;
		top:100%;
		height:1px;
		width:calc( 100% );
		background-color:#d8d8d8;
	}
		
}





.pricing-table__wrapper{
	
	h3{
		color:$brand;
		font-weight:bold;
		margin-bottom:40px;
	}
	margin-bottom:80px;
}


.faq-table__wrapper{
	
	h3{
		color:$brand;
		font-weight:bold;
		margin-bottom:40px;
	}
	margin-bottom:80px;
}


.pricing-table {
    background: transparent;
    border: 0px solid white;

    @include media("<narrow") {
        font-size: .9rem;
    }


    .price-cell {
        width: 150px;
        min-width: 80px;
        text-align: right;


        @include media("<narrow") {
            width: 150px;
            min-width: 60px;
        }
    }

    .discounted-price {
        color: $brand;
        font-weight: 900;
        //background-color: $bright;
    }

    .original-price {
        text-decoration: line-through;
        color: #A0A0A0;
    }

    .no-strike {
        text-decoration: none !important;
    }

    .price-cell--sub {
        font-size: 15px;
        display: block;

        @include media("<narrow") {
            font-size: 13px;
            display: block;
            line-height: 20px;
        }
    }



    .price-cell--bg {
        //background-color:white;
        position: relative;

        &:before {
            content: '';
            background: white;
            position: absolute;
            left: -8px;
            top: 0px;
            width: calc(100% + 16px);
            height: 100%;
        }

        span {
            position: relative;
            white-space: normal;
        }
    }


    tr {
        background: url(../img/line.png) !important;
        background-image: url(../img/line.png) !important;
        background-repeat: no-repeat !important;
        background-position: 0px 23px !important;


        @include media("<1100px") {
            background: none !important;
        }
    }

    td {
        @media (max-width: 999px) {
            display: block;
            width: 100% !important;
        }
    }
}

.treatments{
	
	.g{
		padding-bottom:10px;
	}
}


.text-advert{
		
		text-align:center;
		max-width:700px;
		margin:0pt auto;
		padding-bottom:100px;

		h3{

			font-weight:bold !important;
			color:#F76E83;
		}

		.btn--secondary{
			padding-left:90px;
			padding-right:90px;
		}
}





.chart__key{
	width:100%;
	float:left;

	@include media("<1100px") {
		width:100%;
		padding-bottom:20px;
	}

	.chart__key__rate{
		padding-left:25px;
		background-image:url('../img/grey_key.svg');
		background-position:center left;
		background-size:15px;
		background-repeat:no-repeat;
	}

	.chart__key__rate--orange{
		padding-left:25px;
		background-image:url('../img/orange_key.svg');
		background-position:center left;
		background-size:15px;
		background-repeat:no-repeat;
	}

}


// Used for top chart to position commentry to the right hand side

.chart_inner-wrapper{
	width:50%;
	float:left;
	@include media("<mid") {
		width:100%;
	}
}


.chart{

	float:left;
	width:33.333%;

	@include media("<mid") {
		width:33.33%;
	}

	@include media("<narrow") {
		width:50%;
	}

	&__inner{
		

	}

}


.chart__wrapper--5{

	.chart{

		float:left;
		width:16.666%;

		@include media("<mid") {
			width:33.33%;
		}

		@include media("<narrow") {
			width:50%;
		}

		&__inner{
			

		}

	}

}


.chart{
		

	&__name{
		font-size:24px;
		font-weight:500;
		padding-bottom:15px;
	}

	&__title{
		p{
			margin:0px;
		}
		font-weight:300;
		padding-top:15px;
	}

	&__partner{
		font-weight:300;
		color:#F76E83;
		font-size:18px;
		display:inline-block;
		text-transform:uppercase;
	}

	&__updated{
		padding-bottom:20px;
		font-weight:300;
		color:#000000;
		font-size:14px;
	}

	&__clinic{
		padding-right:30px;
		font-weight:900;
	}

	&__charts{
		padding-bottom:20px;
	}

	&__footer{
		padding-bottom:20px;

		

	}

}




.chart__wrapper{

	.chart__title{
		width:100%;
		float:left;
		font-size:22px !important;
		margin-bottom:10px;
		font-weight:bold;
		color:#000000;
	}
	.chart__date{
		width:100%;
		float:left;
		font-size:18px !important;
		margin-bottom:10px;
		font-weight:300;
		color:#000000;
	}
	.chart__sub{
		width:100%;
		float:left;
		font-size:20px !important;
		margin-bottom:10px;
		font-weight:300;
		color:#000000;
		margin-bottom:20px;
	}

	.chart__footer{
		width:100%;
		float:left;
		margin-top:10px;
		margin-bottom:100px;
	}

	.chart__commentry{
		width:40%;
		float:left;
		padding-left:40px;

		h4{
			margin-bottom:10px;
		}

		@include media("<mid") {	
			width:100%;
			padding-left:0px;
			padding-top:20px;
		}
	}

	.chart__commentry--right{
		float:right !important;
				margin-bottom:70px;

			@include media("<mid") {
				

			}
	}

	.chart__commentry--full{
		width:100%;
		float:left;
		margin-top:20px;
		margin-bottom:70px;

		h4{
			margin-bottom:10px;
		}
	}



	.chart__footer--full{
		width:100%;
		float:left;
		margin-bottom:20px;
		margin-top:40px;

		.chart__key{
			text-align:center;

			> div{
				display:inline-block;
				padding-right:10px;
				padding-left:30px;
			}
		}

	}



	.chart__footer--right{

		margin-bottom:70px;

		@include media("<mid") {
				
		margin-bottom:20px !important;

			}


	}

}



.photo-gallery{
	width:100%;
	img{
		width:100%;
		dispay:block;
	}
}




.address-grid{
	

	.address-block{
		min-height:60px;
		padding-left:130px;
		background-image:url('../img/map__icon__1.png');
		background-position:40px top;
		background-size:60px 60px;
		background-repeat:no-repeat;
		padding-right:40px;
		margin-bottom:20px;


		@include media("<narrow") {
			padding-left:70px;
			background-size:30px 30px;
		background-position:20px top;

		}


	}

	
	.address__icon-3{
	background-image:url('../img/location-grey.svg');
	}

	.address__icon-2{
	background-image:url('../img/car.svg');
	}

	.address__icon-1{
	background-image:url('../img/train.svg');
	}


	.address-block__station{
		padding-left:30px;
		background-image:url('../img/underground.svg');
		background-position:left 5px;
		background-size:20px 20px;
		background-repeat:no-repeat;

		span{
			font-size:14px;
		}
	}


	.address-block__station.national__rail{
		background-image:url('../img/national_rail.svg');
	}



text-align:left;
	
	&__map{

	}

	&__directions{
	text-align:left;
	padding-left:40px;

	@include media("<mid") {
				
	padding-top:40px;
	padding-left:0px;

			}

	}
}

.help-tooltip{
	display:flex;
	align-items:top;
	position:relative;

	&--centered{
		justify-content:center;	}


	&__icon{

	    min-width: 20px;
    	max-width: 20px;

		img{
			display:block;
		}
		position:relative;
	}



	&__content{
		padding-right:10px;
	}

	&__tip{
		position:fixed;
		left:0px;
		top:0px;
		min-height:200px;
		padding:20px;
		background:white;
		width:50vw;
		border:1px solid black;
		max-width:300px;
	}


		&__icon:hover{
		img{
			opacity:0.33;
		}
		
	}



}


@include media("<mid") {
				
	.help-tooltip__icon{
	display:none !important
	}


	.help-tooltip--center{
		justify-content:center;
	}

}

.tooltip_templates { display: none; }

.tooltip_templates__inner{
	max-width:400px;
	padding:10px;
	font-family: Lato, sans-serif !important;
	font-weight:bold !important;
	line-height:1.3 !important;

	p:last-child{
		margin-bottom:0px;
	}
}






.rating__rating{
	




.help-tooltip{



	&__icon{

	    min-width: 22px;
    	max-width: 22px;

		img{
			display:block;
		}
		position:relative;
	}



	



}


}




.address-grid--contacts{

margin-bottom:50px;
	
	@include media("<mid") {
				width:100%;	
	max-width:400px;
	margin:0pt auto;

			}
}


			
#map{
	
	width:100%;
	height:600px;
}


.profile__compare{
	
	text-align:center;
	padding-bottom:70px;
	padding-top:20px;

	a{
	width:500px;
	max-width:90%;
	}
}


.contact-heading{
	margin-top:-25px;
 }


 .fixed-compare{
 	position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: white;
    border-top: 1px solid #aaaaaa;
    padding-top: 10px;
    z-index: 2000;
 }


 .fixed-compare-button{

 	position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: white;
    border-top: 1px solid #aaaaaa;
    padding-top: 30px;
    padding-bottom:30px;
    z-index: 2000;

    a{
		box-shadow: 0 0 12px 0 #D8D8D8;
    }
 }


#listings-select{
	margin-top:40px;
	margin-bottom:40px;

	@media (max-width: 552px) {
		margin-bottom:0px;
	}
}


 .listing-disclaimer{
      font-weight: 300;
      color: #000000;
      font-size: 14px !important;
      position:absolute;
      bottom:10px;
      right:25px;
  }

  .listing-disclaimer p{
      font-weight: 300;
      color: #000000;
      font-size: 14px !important;
      margin:0px;
      padding:0px;
  }



.explainer-strip{
	margin-bottom:20px;
	&__inner{
		text-align:center;
		font-size:15px;

		p{
			margin-bottom:0px;
			color:#F76E83;
		}
	}
}


.inline-hide-on-mobile {
  display: none !important; }


@media (min-width: 551px) {

  .inline-hide-on-mobile {
    display: inline-block !important; } 
  }


  .listings__results .listing-result{
  padding-bottom:40px !important;
  }

