/**
 * Print styles
 * =================================
 */

@media print {

	* {transition: none !important;font-family:Arial !important;}

	*,
	*:before,
	*:after,
	*:first-letter {
		background: transparent !important;
		color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
		box-shadow: none !important;
		text-shadow: none !important;
	}

	a {
		&:visited {
			text-decoration: underline;
		}

		&[href]:after {
			content: " (" attr(href) ")";
		}
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	/*
	 * Don't show links for images, or javascript/internal links
	 */
	.ir a:after,
	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content: "";
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group; /* h5bp.com/t */
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	@page {
		margin: .5cm;
	}

	p, h2, h3 {
		orphans: 3;
		widows: 3;
	}

	h2, h3 {
		page-break-after: avoid;
	}


	#header, .social-strip, .specialist__search, .overview__book, .other-specialists, #social-panel, .footer, #masthead, .print-page, .rating__specialist__photo, .image-and-content__image, .back-link, .listings__search, .overview__rating{
		display:none !important;
	}

	.specialist__specialist .g-span7--mid, .specialist__specialist .g-span4--mid{
		width:100% !important;
		max-width:100% !important;
		flex-basis:100% !important;

	}

	.specialist__heading{
		display:none;
	}

	.overview__details{
		//margin-left:30px;
	}

	.specialist__specialist .specialist__heading{
		text-align:left;
	}


	.specialist__specialist .g-col--spacer{
		display:none;
	}
	.printable-image{
		display:block !important;
	}

	.specialist__section{
		margin-top:-40px !important;
	}

	.publication__image{
		display:none !important;
	}

	.printable-logo{
		display:block !important;
		margin-left:35px;
		margin-top:30px;
	}

	.specialist__specialist{
		display:block !important;
	}

	.specialist__specialist .g-col, .specialist__specialist .g{
		width:100% !important;
		display:block !important;
		postion:relative;
		//page-break-inside:avoid !important;
	}




}
