

	.profile-search{
		
		&__form{
			overflow:hidden;
		}

		&__left{
			width: calc(50% - 55px);
			float:left;
			margin-right:20px;

			@include media("<600px") {
				width:100%;
				margin-bottom:15px;
			}
		}

		&__right{
			width: calc(50% - 55px);
			float:left;
			margin-right:20px;

			@include media("<600px") {
				width: calc(100% - 90px);
				margin-bottom:10px;
			}

		}

		&__submit{
			width:70px;
			float:left;
			height:66.5px;

			@include media("<mid") {
				
				height:60px;

			}

			
		}

		

	}


.profile {
    padding-top: 50px;
    padding-bottom: 50px;


    @include media("<600px") {
        padding-top: 30px;
    }


    &__search {
        padding-bottom: 30px;

        @include media("<600px") {
            padding-bottom: 5px;
        }
    }
    /* Filters */
    &__filters {
        margin-bottom: 40px;

        @media (max-width: 540px) {
            margin-bottom: 0;
        }

        .profile-filters {
            color: #a0a0a0;
            padding-top: 0px;
            padding-bottom: 0px;
            text-align: center;

            span {
                font-weight: 500;
            }

            label {
                display: inline-block;
                border-bottom: solid 2px transparent;
                cursor: pointer;
            }

            label, span {
                margin-top: 10px;
                margin-left: 30px;
                margin-right: 30px;

                @include media("<mid") {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }

            input[type="radio"] {
                display: none;
            }

            input[type="radio"]:checked + label {
                border-bottom: solid 2px $brand;
            }

            input[type="radio"]:hover + label {
                border-bottom: solid 2px #aaaaaa;
            }
        }
    }

    &__section {
    }

    &__overview {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1400px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }

        .overview__rating {
            width: 310px;
            float: left;
            padding-right: 80px;
            text-align: center;

            @include media("<mid") {
                width: 100%;
                padding-right: 0px;

                .rating__inner {
                    max-width: 250px;
                    margin: 0pt auto;
                    margin-bottom: 30px;
                }
            }

            .rating__logo {
                display: block;
            }

            .rating__rating {
                display: block;
                margin-bottom: 10px;

                span {
                    font-weight: bold;
                }
            }

            .rating__stars {
                display: block;
            }
        }


        .overview__details {
            width: calc(100% - 530px;
            );
            float: left;
            padding-right: 80px;

            @include media("<mid") {
                width: 100%;
                padding-right: 0px;
            }


            .overview__name {
                margin-bottom: 20px;
                font-weight: 900;
                display: block;
                color: #222222;
                font-size: 30px !important;

                /*@include media("<mid") {
                    text-align: center;
                }*/
            }

            .overview__link {
                margin-bottom: 20px;
                display: block;
                color: #222222;
            }



            .overview__decription {
            }
        }

        .overview__book {
            width: 220px;
            float: left;
            padding-top: 20px;
            padding-bottom: 40px;

            img {
                max-width: 100px;
            }

            @media (max-width: 540px) {
                padding-bottom: 0;
            }

            @include media("<mid") {
                width: 100%;
            }
        }
    }

    &__factsheet {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1400px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }


        .factsheet__map {
            padding-bottom: 60px;

            img {
                width: 100%;
                display: block;
                max-width: 300px;
                margin: 0pt auto;
            }
        }

        .factsheet__address {
            padding-bottom: 80px;

            @media (max-width: 540px) {
                padding-bottom: 30px;
            }

            p {
                white-space: pre-wrap;
            }
        }
    }



    &__success {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1400px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }
    }

    &__specialist {
        margin: 0pt auto;
        width: 100%;
        max-width: 1400px;
        padding-top: 40px;
        padding-bottom: 40px;



        .l-container {
            max-width: 1200px;
        }

        @include media("<mid") {
            width: 100%;
        }
    }

    &__photos {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1400px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }
    }

    &__prices {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1000px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }
    }

    &__maps {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1400px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }
    }

    &__FAQs {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1000px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }
    }
}


.similar-clinics{
	
	padding-bottom:50px;

	h3{

	color:$brand;
		font-weight:bold;
		margin-bottom:70px;
	}

	.similar-clinic{
		width:90%;
		max-width:250px !important;
		margin:0pt auto;
		display:block;

		transition: all .2s ease-in-out;

		&:hover{
			//transform: scale(1.1);
			z-index:0;
			position:relative;
		}

	}

	.similar-clinic__image{
		img{
		width:100%;
		}
	}

	.similar-clinic__name{
		font-size:20px;
		font-weight:bold;
		color:#000000;
		margin-top:10px;
		margin-bottom:10px;
	}

	.similar-clinic__rating{
		margin:0pt auto;

		width:90%;
		max-width:200px;
	}
}



.faq-table{
	
	h3{
		color:$brand;
		font-weight:bold;
		margin-bottom:50px;
	}
	margin-bottom:40px;

	&__question{
		//display:block;
		//float:left;
		p:last-child{
			margin-bottom:0px;
		}
	}

	&__answer{
		//display:block;
		//float:left;
		margin-bottom:40px;
	}

	&__prefix{
		display:block;
		float:left;
		padding-right:5px;
		font-weight:500;
	}

}


.back-link{

	padding-top:0px;
	padding-bottom:10px;

	a{
		color:#000000;
		font-size:16px !important;
		text-decoration:underline;
	}
}



.tooltip_content__title{margin-bottom:10px;font-size:18px !important;font-weight:bold;}
.tooltip_content__body{margin-bottom:10px;p{font-size:18px !important;}}
.tooltip_content__link{font-weight:normal !important; color:#222222 !important;font-size:16px !important;text-decoration:underline !important;}


.img-80{
	max-width: 80% !important;
    margin: 0pt auto !important;
}

.img-90{
	max-width: 80% !important;
    margin: 0pt auto !important;
}


.img-80 {
  max-width: 80% !important;
  margin: 0pt auto !important; }




.img-60 {
  max-width: 60% !important;
  margin: 0pt auto !important; }


.img-90 {
  max-width: 80% !important;
  margin: 0pt auto !important; }



.pre-wrap{
		
		p{
		white-space: pre-wrap;
		margin-bottom:0px;
		}


	}





.image-and-content {
    width: 100%;
    margin-bottom: 50px;

    @media (max-width: 540px) {
        margin-bottom: 20px;
    }

    &__inner {
        overflow: hidden;
    }

    &__content {
        float: left;
        width: calc( 100% - 150px );

        @include media("<500px") {
            width: 100%;
        }

        h3 {
            font-size: 26px !important;
            font-weight: bold;
            margin-bottom: 10px !important;
        }

        ul {
            margin-left: 20px !important;
        }
    }

    &__image {
        float: left;
        width: 150px;
        padding-right: 40px;

        img {
            width: 100%;
            display: block;
        }

        @include media("<500px") {
            width: 100%;
            padding-right: 0px;

            img {
                width: 20%;
                max-width: 40px !important;
                margin: 0px !important;
                display: block;
                margin-bottom: 20px !important;
            }
        }
    }
}


.do-not-show{
	display:none!important;
}

.do-not-show{
	display:none!important;
}

.form-input--select{
	line-height:22px !important;
}

.rating__logo img{
	max-height:175px;
}

.similar-clinic__logo img{
	//height:120px;
}