

.learn {
    padding-top: 50px;
    padding-bottom: 30px;

    @include media("<600px") {
        padding-top: 30px;
    }

    &__search {
        padding-bottom: 70px;
    }


    &__search--limited {
        padding-bottom: 30px;
    }



    .back-link {
        padding-bottom: 50px;
    }

    .learn__title {
        font-weight: bold;
    }

    .learn-search {

        &__form {
            overflow: hidden;
        }

        &__left {
            width: calc(100% - 130px);
            float: left;
            margin-right: 20px;
        }

        &__submit {
            width: 110px;
            float: left;
            height: 66.5px;

            @include media("<mid") {
                height: 59px;
            }
        }
    }
    //Learn section starts here...
    &__section {
        overflow: hidden;
        margin-bottom: 70px;

        .l-container--full {
            display: flex;

            @include media("<mid") {
                display: block;
            }
        }

        &--image {
            //float: left;
            width: 20%;
            border: 0px solid black;
            text-align: center;

            @include media("<mid") {
                width: 100%;
            }

            padding-right: 40px;

            .learn__icon {
                width: 50%;
                max-width: 100px;
            }

            .learn__arrow {
                margin-top: 60px;
            }

            @include media("<mid") {
                .learn__icon {
                    margin-bottom: 40px;
                }

                .learn__arrow {
                    display: none;
                }
            }
        }

        &--list {
            float: left;
            width: 80%;
            border: 0px solid black;
            padding-right: 40px;
            //@include media("<mid") {
            padding-right: 0px;

            //}
            .learn__title {
                font-size: 26px !important; //22px
                line-height: 1.4 !important;
                display: block;
                margin-bottom: 20px;
                color: $brand;
            }


            > ul {
                list-style: none;
                counter-reset: mycounter;
                padding: 0;
            }

            > ul > li > a {
                position: relative;
                margin-left: 38px;
                padding: 5px 0;
            }

            ul > li > a:before {
                content: counter(mycounter);
                counter-increment: mycounter;
                position: absolute;
                top: 50%;
                left: -38px;
                width: 25px;
                height: 25px;
                line-height: 25px;
                border-radius: 50%;
                color: #FFA328;
                background: whitesmoke;
                text-align: center;
                font-size: 14px;
                font-weight: bold;
                margin-top: -12.5px;

                @include media("<mid") {
                    //top: 15px;
                }
            }


            .learn__link {
                font-size: 20px !important; //18
                color: #000000;
                line-height: 24px !important; //22
                display: inline-block;
                margin-bottom: 15px;
                font-weight: normal;
                //@include media("<mid") {
                margin-bottom: 0px;
                display: block;
                padding-right: 50px;
                position: relative;

                &:after {
                    content: '';
                    width: 15px;
                    height: 15px;
                    background-image: url('../img/down-arrow.svg');
                    background-position: center right;
                    background-size: 15px;
                    background-repeat: no-repeat;
                    right: 5px;
                    top: 5px;
                    position: absolute;
                    -moz-transition: all 0.5s ease;
                    -webkit-transition: all 0.5s ease;
                    -o-transition: all 0.5s ease;
                    transition: all 0.5s ease;
                    //}
                }
            }



            .js-accordian-open {

                &:after {
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    -moz-transform: rotate(180deg);
                }
            }

            .learn__link:hover {
                color: #777777;
            }

            .learn__link.tooltip-active {
                color: #777777;
            }


            .learn__accordian {
                display: none;
                overflow: hidden;

                &__image {
                    float: right;
                    width: 120px;
                    padding-left: 20px;
                    text-align: center;
                }

                &__title {
                    width: calc(100% - 120px;
                    );
                    float: left;
                }

                &__body {
                    float: left;
                    width: 100%;

                    p {
                        margin-bottom: 20px;
                    }

                    p:last-child {
                        margin-bottom: 0px;
                    }
                }

                &__wrapper {
                    float: left;
                    width: calc(100% - 120px;
                    );
                }

                &__wrapper--full {
                    float: left;
                    width: 100%;
                }


                &__readmore {
                    margin-top: 15px;
                    float: left;
                    width: 100%;

                    a {
                        //color:#000000;
                    }

                    a:hover {
                        //color:$pink;
                        //text-decoration:underline;
                    }
                }
                //@include media(">=mid") {
                //display:none !important;
                //}
            }
            //@include media("<mid") {
            width: 100%;

            ul {
                li {
                    border-bottom: 1px solid #D8D8D8;
                    padding-bottom: 15px;
                    padding-top: 15px;
                }
            }

            .learn__accordian {
                margin-top: 20px;
                margin-bottom: 20px;
            }
            //}
        }

        &--faq {
            
        }
    }
}


.learn_wrapper {
    position: relative;
    padding-bottom: 100px;
}



.learn__tooltip__wrapper {
    display: none;
    position: absolute;
    width: 100%;
    border: 0px solid black;
    padding-left: 40px;
    right: 0px;
    transition: all .5s ease-out 0s;
    top: 0; /* start out at position 0 */
    z-index: 5;

    .learn__tooltip {
        //overflow:hidden;
        //display:none;
        &__image {
            float: right;
            width: 100px;
            padding-left: 20px;
        }

        &__title {
            color: #F76E83;
            font-size: 26px;
            line-height: 30px;
            margin-bottom: 20px;
            width: 100%;
            font-weight: bold;
        }

        &__body {
            width: calc(100% - 120px;
            );
            float: left;

            p {
                margin-bottom: 0px;
                font-size: 18px !important;
                font-weight: normal;
                line-height: 24px !important;
            }

            &--noimage {
                width: calc(100%);
            }

            p {
                margin-bottom: 20px;
            }

            p:last-child {
                margin-bottom: 0px;
            }
        }

        &__readmore {
            width: calc(100% - 120px;
            );
            float: left;
            margin-top: 15px;


            a {
                //color:#000000;
                //font-size:18px !important;
                //font-weight:normal
            }

            a:hover {
                //color:$pink;
                //text-decoration:underline;
            }
        }
    }

    //@include media("<mid") {
        display: none !important;
    //}
}


.learn__tooltip {
    position: relative;
    padding: 20px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 30px;
    background: white;
    background: #FFFFFF;
    box-shadow: 0 0 5px 0 #D8D8D8;
    border-radius: 2px;
    font-size: 16px;
    width: 100%;
}


.learn__tooltip:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    //top: 90px;
    //left: -20px;
    top: 0;
    left: 50%;
    margin-left: -15px;
    box-sizing: border-box;
    background: white;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: 0px 0px 5px 0 #D8D8D8;
}

.learn__tooltip:after {
    content: "";
    position: absolute;
    /*width: 25px;
    height: 50px;
    top:65px;*/
    background: white;
    //left: 0px;
    width: 50px;
    height: 25px;
    top: 0px;
    margin-left: -20px;
    left: 50%;
}




.form-input {
    font-weight: 300;
}


.overflow-hidden {
    overflow: hidden;
}

.notknown {
    box-sizing: border-box;
    background: white;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: 0px 0px 5px 0 #D8D8D8;
}

/*.learn__tooltip::after{
    content: "";
    position: absolute;
    width: 25px;
    height: 50px;
    top:65px;
    background:white;
    left: 0px;
}*/




.form-input {
    font-weight: 300;
}


.overflow-hidden {
    overflow: hidden;
}

.learn__heading {
    display: none;
    margin-bottom: 10px;
    font-size: 16px;
    color: #aaaaaa;
    line-height: 20px;

    @include media("<820px") {
        display: block;
    }
}


.specialist__overview .overview__rating--full {
    text-align: left;
    line-height: 1.2 !important;
}


.noimage {

    .learn__tooltip__body {
        width: 100%;
    }

    .learn__tooltip__image {
        display: none;
    }
}
