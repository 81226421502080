/**
 * Masthead
 * =================================
 */


.site__wrapper{
	max-width:1800px;
	margin:0pt auto;
}

.sticky .site__wrapper main{
	@include media("<=mid") {
		padding-top:0px;
	}
	@include media(">mid") {
		padding-top:70px;
	}
}


.logo:hover svg, .js-slideout-toggle:hover svg{
    opacity:0.7;
}

.masthead {
    border-bottom: 1px solid $dark-grey;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;
    max-width: 1800px;
    width: 100%;
    position: relative;
    z-index: 5;

    &-logo {
        color: $brand;
        width: 110px;
        height: 25px;
        display: block;
        margin-top: 4px;

        @include media("<mid") {
            margin-top: 2px;
            width: 90px;
            height: 25px;
            padding-top: 3px;
            padding-bottom: 2px;
        }
    }

    &.sticky {
        @include media(">mid") {
            position: fixed;
            z-index: 999;
            //animation:slide-down 0.7s;
        }
    }

    @include media("<mid") {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}





@keyframes slide-down {
    0% {
        opacity: 1;
        transform: translateY(-100%);
    } 
    100% {
        opacity: 1;
        transform: translateY(0);
    } 
}





/* Themes */

.pink-theme {
    
    $theme-color: #F76E83;
    $text-color: #ffffff;

    .masthead{
        background-color: $theme-color;
        border-bottom: $text-color;
    }

    .masthead-logo__inner{
        fill:$text-color;      
    }

    .nav--main > .nav__item > .nav__link{
        color:$text-color;
    }

    .nav--main > .nav__item > .nav__link:hover:after{
        background-color:$text-color !important;
    }

    .svg-burger__inner{
        stroke:$text-color;
    }

    .svg-search__inner{
        fill:$text-color !important;
    }

    .nav--mobile{
        .nav__link:hover{
            color:$theme-color !important;
            &:after{
            content:'';
                background-color:$theme-color !important;
            }
        }
    }

    .sub{
            
        &__item:hover{
            border-bottom:1px solid $theme-color !important;
        }

        &__item:hover .sub__link{
            color:$theme-color !important;
        }
    }

    h1, h2{
        color:$theme-color;
    }


    footer{

        a:hover {
            color:$theme-color !important;
            text-decoration: none;
        }
    }


    .nav__link{


            &.active:after{
                content:'';
                display:block;
                width:100%;
                height:1px;
                background-color:#ffffff !important;
                margin-bottom:-1px;
            }

        }


}






/* Themes */

.orange-theme {
    
    $theme-color: $brand;
    $text-color: #ffffff;

    .masthead{
        background-color: $theme-color;
        border-bottom: $text-color;
    }

    .masthead-logo__inner{
        fill:$text-color;      
    }

    .nav--main > .nav__item > .nav__link{
        color:$text-color;
    }


 .nav--main > .nav__item > .nav__link:hover:after{
        background-color:$text-color !important;
    }

    
    .svg-burger__inner{
        stroke:$text-color;
    }

    .svg-search__inner{
        fill:$text-color !important;
    }

    .nav--mobile{
        .nav__link:hover{
            color:$theme-color !important;
            &:after{
            content:'';
                background-color:$theme-color !important;
            }
        }
    }

    .sub{
            
        &__item:hover{
            border-bottom:1px solid $theme-color !important;
        }

        &__item:hover .sub__link{
            color:$theme-color !important;
        }
    }

    h1, h2{
        color:$theme-color;
    }

       .nav__link{

            &.active:after{
                content:'';
                display:block;
                width:100%;
                height:1px;
                background-color:#ffffff !important;
                margin-bottom:-1px;
            }

        }

}

@include media("<=mid") {
    .js-search-toggle{
        display:none !important;   
    }

    .nav__item.nav__item--mobile{
        float:right;
    }
}


.header--extended{
    max-width:800px !important;
}


.label-inline p{
    display:inline-block !important;
    line-height: 1.4;
    font-size: 18px;
}

