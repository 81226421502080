/**
 * Team member
 * 1. Tile
 * 2. Overlay
 * 3. Full page
 * =================================
 */


.team-member-tile {
    outline: none;
    display: block;
    color: #646464 !important;
    font-size: 19px;
    transition: all .2s ease-in-out;

    &:hover {
        //transform: scale(1.1);
        z-index: 0;
        position: relative;
    }

    &__name {
        font-weight: 900;
        display: block;
        width: 100%;
        margin-top: 15px;
    }

    &__title {
        font-weight: 300;
        display: block;
        width: 100%;
        margin-top: 5px;
    }


    &__button {
        margin-top: 20px;
        margin-bottom: 40px;

        @media (max-width: 540px) {
            padding-right: 10px;
            padding-left: 10px;
            font-size: 14px;
        }

        @include media("<mid") {
            margin-bottom: 15px;
        }
    }


    &__photo {
        width: 60%;
        padding-bottom: 60%;
        background-size: cover;
        display: inline-block;
        border-radius: 50%;

        @include media("<narrow") {
            width: 80%;
            padding-bottom: 80%;
        }
    }
}



.team-member-overlay{
		
	position: relative;
  	background: #FFF;
  	padding: 40px;
  	width: auto;
  	max-width: 800px;
  	margin: 20px auto;

  	/* Sketch Styles: */
	background: #FAFAFA;
	border: 1px solid #D8D8D8;
	box-shadow: 0 0 5px 0 #D8D8D8;
	border-radius: 2px;

	font-size:19px;

	@include media("<narrow") {
  		padding: 30px;
	}

	&__name{
		font-weight:500;
		width:100%;
	}

	&__title{
		font-weight:300;
		width:100%;
	}

	&__description{
		padding-top:15px;
		font-weight:300;
		width:100%;
	}

	&__photo{
		width:100%;
		padding-bottom:100%;		
		background-size:cover;
		display:inline-block;
		border-radius:50%;

		@include media("<narrow") {
			text-align:center;
			margin-bottom:20px;	
		}

	}

	&__link{
			
		padding-top:10px;

		a{
			color:#646464 !important;
			text-decoration:underline;
			font-weight:300;
		}
	}

}

.team-bio{
	padding-left:30px !important;
}


