/**
 * Helper classes
 * =================================
 * Non-semantic helper classes
 */

/**
 * Clearfix
 * http://www.cssmojo.com/latest_new_clearfix_so_far/
 * See mixins/_utility.scss for a mixin version of this
 */
.clearfix::after {
	content: "";
	display: table;
	clear: both;
}


// For background image replacement
.ir {
	background-color: transparent;
	background-repeat: no-repeat;
	border: 0;
	direction: ltr;
	display: block;
	overflow: hidden;
	text-align: left;
	text-indent: -999em;

	br {
		display: none;
	}
}


// Hide from both screenreaders and browsers: h5bp.com/u
.is-hidden {
	display: none !important;
	visibility: hidden !important;
}

.is-shown { display: block; }

// Hide only visually, but have it available for screenreaders: h5bp.com/v
.is-visuallyHidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;

	// Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p
	&.focusable:active,
	&.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
}

// Hide visually and from screenreaders, but maintain layout
.is-invisible { visibility: hidden; }

.l-floatLeft {
	float: left;
}

.l-floatRight {
	float: right;
}

.l-clear {
	clear: both;
	float: none;
}

.l-mb0 { margin-bottom: 0 !important; }
.l-mt0 { margin-top: 0 !important; }


* {
	-webkit-tap-highlight-color: transparent !important;
}

/* A hack for HTML5 contenteditable attribute on mobile */
textarea[contenteditable] {
	-webkit-appearance: none;
}

.promote-layer {
	/*
	This may promote the layer to a composited
	layer.

	Replace with will-change when available

	#perfmatters
	*/
	backface-visibility: hidden;
}

// Add the default transition to any element
.transition {
	transition: $default-transition;
}

// Faster taps on mobile devices
// See https://adactio.com/journal/10019 for more info
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
	touch-action: manipulation;
}


.muted{
	opacity:.5;
}


.svg-full{
	width:100%;
	height:100px;
	max-width:80px !important;
}

.svg-plus{
	width:50%;
	height:50%;
}

.hide-mid{
	@include media("<mid") {
		display:none !important;
	}
}


.feature__text{
	min-width:135px;
}


.l-container{
	//overflow:hidden;
}


.l-container--r{
	max-width:70%;
}


.feature-strip{

	max-width:1000px;
	margin:0pt auto;

	
	.feature-strip__icon{
		width:24%;
		float:left;
	}

	.feature-strip__plus{
		width:7%;
		float:left;
		margin-left:3%;
		margin-right:4%;
	}

	> div{
		padding-bottom:40px;
	}

	@include media("<850px") {


		max-width:270px;
	margin:0pt auto;

		.feature-strip__plus{
			display:none;
		}

		.feature-strip__icon{
			width:100%;
			float:left;
		}

	}

}

footer, .contact-strip__form, .learn-search__form, .listings-search__form, .listings__filters, .profile__filters{

.has-floating-label .form__floating-label{
	    color: #aaaaaa;
	}

.form-input{
	    color: #aaaaaa !important;

}


.form-controlGroup-inputWrapper--select .form-input--select {
	    color: #aaaaaa;
	    //font-size:20px !important;
}

}



.form-input--select {

padding-right:50px;
}


.contact-strip{
	
	$form-border-width: 1px;
	$form-text-color: #d8d8d8;
	$form-placeholder-color: 1px;

	
	&__form{
		width:100%;
		//height:70px;

		.form-input, select{
			background:transparent !important;
			color:$form-text-color !important;
			border:1px solid #a0a0a0 !important;
		}

		.g-col{
			padding-bottom:0px !important;
		}

		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: $form-placeholder-color;
		}
		::-moz-placeholder { /* Firefox 19+ */
		  color: $form-placeholder-color;
		}
		:-ms-input-placeholder { /* IE 10+ */
		  color: $form-placeholder-color;
		}
		:-moz-placeholder { /* Firefox 18- */
		  color: $form-placeholder-color;
		}

		.form-controlGroup-inputWrapper--select::after {
		  color: $form-placeholder-color;
		}

	}

	&__submit{
		height:100%;
		background-position:center center;
		background-size:25px;
		background-repeat:no-repeat;
		background-image:url('../img/right-arrow.svg');
		background-color:transparent !important;
		border:1px solid #aaaaaa !important;

		&:hover{
			background-color:rgba(0,0,0,0.05) !important;
		}
	}
}

.search_submit{
    min-height: 50px;
	height:100%;
		background-position:center center;
		background-size:25px;
		background-repeat:no-repeat;
		background-image:url('../img/search-icon-grey.svg');
		background-color:transparent !important;
		border:1px solid #aaaaaa !important;

		&:hover{
			background-color:rgba(0,0,0,0.05) !important;
		}

		
}



@include media("<mid") {
	.half-baseline{
		padding-bottom:20px !important;
	}
}


.l-container--full{
	width:100%;
}


.contact-strip__submit{
	
	min-height:59px;
}

#how-we-can-help .l-container{
	overflow:hidden;
}


.grey-link{
	color:#999 !important;	
	text-decoration:underline !important;
}

.limit-center{
	
	width:100%;
	max-width:720px;
	margin:0pt auto;
	margin-bottom:-30px;
}


.bold{
	font-weight:bold !important;
}

.not-bold{
	font-weight:300 !important;
}


.specialist__bio a{
	color:#000000;
	text-decoration:underline;
}

.image-and-content__content{
	
	table tr td{
		padding:0px !important;
	}

	table tr{
		background:transparent !important;
	}

}


.sitemap{
	margin-top: 30px;
    margin-bottom: 60px;
}

.sitemap h1{
	font-size:30px !important;
}


.pre-wrap-all{
	
	white-space: pre-wrap;

}

#how-we-can-help h2{
	//font-size:40px !important;
}

@include media(">mid", "<1200px") {

.footer-form-left{
	width:100%;
	min-width:100%;
}

.footer-form-right{
	width:100%;
	min-width:100%;
}
}




.photo-gallery{

	&__wrapper{
	padding-left:80px;
	padding-right:80px;
	position:relative;

	@include media("<mid") {
		padding-left:0px;
		padding-right:0px;
	}

	}

	&__controls{
		
		list-style:none;

		.prev{
			display:block;
			width:50px;
			height:50px;
			background:black;
			position:absolute;
			left:0px;
			top:50%;
			margin-top:-25px;
			background-position:center center;
			background-size:15px;
			background-repeat:no-repeat;
			background-image:url('../img/arrow_grey.svg');
			background-color:transparent !important;
			cursor:pointer;
			
-webkit-transform: rotate(180deg);
		    -moz-transform: rotate(180deg);
		    -ms-transform: rotate(180deg);
		    -o-transform: rotate(180deg);
		    transform: rotate(180deg);
		    @include media("<mid") {
				display:none !important;
			}
		}

		.next{
			display:block;
			width:50px;
			height:50px;
			background:black;
			position:absolute;
			right:0px;
			top:50%;
			margin-top:-25px;
			background-position:center center;
			background-size:15px;
			background-repeat:no-repeat;
			background-image:url('../img/arrow_grey.svg');
			background-color:transparent !important;
			cursor:pointer;

			-webkit-transform: rotate(0deg);
		    -moz-transform: rotate(0deg);
		    -ms-transform: rotate(0deg);
		    -o-transform: rotate(0deg);
		    transform: rotate(0deg);


			@include media("<mid") {
				display:none !important;
			}

		}
	}

}


.g-recaptcha{
	margin-top:30px;
}





	.show-on-mobile{
	
		display:block;
	}

	.hide-on-mobile{
		
		display:none;
	}




@include media(">550px") {

	.show-on-mobile{
	
		display:none !important;
	}

	.hide-on-mobile{
		
		display:block !important;
	}

}


.negative-top{
	margin-top:-8px;
}