  .Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Aligner-item {
    flex: 1;
  }

  .Aligner-item--top {
    align-self: flex-start;
  }

  .Aligner-item--bottom {
    align-self: flex-end;
  }

  .Aligner-item--fixed {
    flex: none;
    max-width: 50%;
  }

  .Aligner > p{
  	flex: 1;
  }



.Media {
  display: flex;
  align-items: flex-start;
}

.Media-figure {
  margin-right: 1em;
}

.Media-body {
  flex: 1;
}