
.specialist {
    padding-top: 50px;


    @include media("<600px") {
        padding-top: 30px;
    }


    &__search {
        padding-bottom: 30px;

        @include media("<600px") {
            padding-bottom: 5px;
        }
    }

    &__heading {
        text-align: center;
        font-weight: bold;
        color: rgb(255, 163, 40);
        margin-bottom: 80px !important;

        @media (max-width: 540px) {
            margin-bottom: 40px !important;
        }
    }

    .specialist-search {

        &__form {
            overflow: hidden;
        }

        &__left {
            width: calc(50% - 55px);
            float: left;
            margin-right: 20px;

            @include media("<600px") {
                width: 100%;
                margin-bottom: 15px;
            }
        }

        &__right {
            width: calc(50% - 55px);
            float: left;
            margin-right: 20px;

            @include media("<600px") {
                width: calc(100% - 90px);
                margin-bottom: 10px;
            }
        }

        &__submit {
            width: 70px;
            float: left;
            height: 66.5px;

            @include media("<mid") {
                height: 60px;
            }
        }
    }
    /* Filters */
    &__filters {
        margin-bottom: 40px;

        .specialist-filters {
            color: #a0a0a0;
            padding-top: 20px;
            padding-bottom: 20px;
            text-align: center;

            span {
                font-weight: 500;
            }

            label {
                display: inline-block;
                border-bottom: solid 2px transparent;
                cursor: pointer;
            }

            label, span {
                margin-top: 10px;
                margin-left: 30px;
                margin-right: 30px;

                @include media("<mid") {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }

            input[type="radio"] {
                display: none;
            }

            input[type="radio"]:checked + label {
                border-bottom: solid 2px $brand;
            }

            input[type="radio"]:hover + label {
                border-bottom: solid 2px #aaaaaa;
            }
        }
    }

    &__section {
    }

    &__overview {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1400px;
        padding-top: 30px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }

        .overview__extra {
            p {
                margin-bottom: 15px !important;
            }

            margin-bottom: 25px !important;

            @media (max-width: 540px) {
                margin-bottom: 0;
            }
        }

        .overview__rating {
            width: 310px;
            float: left;
            padding-right: 80px;
            text-align: center;



            &--full {
                text-align: left;
                line-height: 1.5 !important;

                h4 {
                    line-height: 2 !important;
                    margin: 0px;
                }

                ul {
                    margin-bottom: 0px;
                }
            }

            @include media("<mid") {
                width: 100%;
                padding-right: 0px;

                .rating__inner {
                    max-width: 250px;
                    margin: 0pt auto;
                    margin-bottom: 30px;
                }
            }

            .rating__social {
                margin-top: 30px;
            }
        }




        .overview__details {
            width: calc(100% - 530px;
            );
            float: left;
            padding-right: 80px;


            &--full {
                width: calc(100% - 330px;
                );
            }

            @include media("<mid") {
                width: 100%;
                padding-right: 0px;
            }


            .overview__name {
                margin-bottom: 15px;
                font-weight: bold;
                display: block;
                color: #222222;
                font-size: 30px !important;
            }

            .overview__link {
                margin-bottom: 20px;
                display: block;
                color: #222222;
            }

            .overview__qualifications {
                margin-bottom: 20px;
                display: block;
                color: #222222;

                .qualification {
                    text-decoration: underline;
                    display: inline-block;
                    margin-right: 10px;
                }

                ul {
                    margin: 0px !important
                }

                li {
                    display: inline-block !important;
                }
            }

            .overview__decription {
            }
        }

        .overview__book {
            width: 220px;
            float: left;
            padding-top: 20px;
            padding-bottom: 40px;

            img {
                max-width: 100px;
            }

            @media (max-width: 540px) {
                padding-bottom: 0;
            }

            @include media("<mid") {
                width: 100%;
            }
        }
    }

    &__specialist {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1400px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }

        .specialist__bio {
            padding-bottom: 40px;

            @media (max-width: 540px) {
                padding-bottom: 0;

                &:last-child {
                    padding-bottom: 40px;
                }
            }

            h3 {
                font-size: 26px !important;
                font-weight: bold;
                margin-bottom: 10px !important;
            }

            h4 {
                margin-bottom: 5px;
            }
        }


        .specialist__list {
            padding-top: 30px;
            margin: 0px !important;

            h4 {
                font-size: 18px !important;
                font-weight: bold;
                margin-bottom: 10px !important;
            }

            p {
            }
        }



        .specialist__address {
            padding-bottom: 40px;
        }
    }

    &__success {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1400px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }
    }

    &__specialist {
        margin: 0pt auto;
        width: 100%;
        max-width: 1400px;
        padding-top: 40px;
        padding-bottom: 40px;

        @media (max-width: 540px) {
            padding-top: 10px;
        }

        .l-container {
            max-width: 1200px;
        }

        @include media("<mid") {
            width: 100%;
        }
    }

    &__photos {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1400px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }
    }

    &__prices {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1000px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }
    }

    &__maps {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1400px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }
    }

    &__FAQs {
        margin: 0pt auto;
        overflow: hidden;
        width: 100%;
        max-width: 1000px;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media("<mid") {
            width: 100%;
        }
    }
}


.other-specialists{
	
	padding-bottom:50px;
	margin-top:-130px;

	h3{

	color:$brand;
		font-weight:bold;
		margin-bottom:70px;
	}

	.similar-clinic{
		width:90%;
		max-width:250px !important;
		margin:0pt auto;
		display:block;

		transition: all .2s ease-in-out;

		&:hover{
			//transform: scale(1.1);
			z-index:0;
			position:relative;
		}

	}

	.similar-clinic__image{
		img{
		width:100%;
		}
	}

	.similar-clinic__name{
		font-size:20px;
		font-weight:bold;
		color:#000000;
		margin-top:10px;
		margin-bottom:10px;
	}

	.similar-clinic__rating{
		margin:0pt auto;

		width:90%;
		max-width:200px;
	}
}



.other-team-members{
	
	padding-bottom:50px;
	margin-top:-40px;

	h3{

	color:$brand;
		font-weight:bold;
		margin-bottom:70px;
	}

	.similar-clinic{
		width:90%;
		max-width:250px !important;
		margin:0pt auto;
		display:block;

		transition: all .2s ease-in-out;

		&:hover{
			transform: scale(1.1);
			z-index:0;
			position:relative;
		}

	}

	.similar-clinic__image{
		img{
		width:100%;
		}
	}

	.similar-clinic__name{
		font-size:20px;
		font-weight:bold;
		color:#000000;
		margin-top:10px;
		margin-bottom:10px;
	}

	.similar-clinic__rating{
		margin:0pt auto;

		width:90%;
		max-width:200px;
	}
}



.faq-table{
	
	h3{
		color:$brand;
		font-weight:bold;
		margin-bottom:50px;
	}
	margin-bottom:40px;

	&__question{
		//display:block;
		//float:left;
        font-weight: bold;

		p:last-child{
			margin-bottom:0px;
		}

		p{
             font-weight: bold;
			font-size:22px !important;
		}

		.faq-table__prefix{
		line-height:33px !important;
        font-weight: bold !important;
		
		}
	}

	&__answer{
		//display:block;
		//float:left;
		margin-bottom:40px;
	}

	&__prefix{
		display:block;
		float:left;
		padding-right:5px;
		font-weight:300 !important;
	}

}

.print-page{
	font-size:16px;
	color:#333333 !important;
	text-decoration:underline !important;
}

.read_more{
		p{font-size:17px;}
		a{
			color:#333333 !important;
			text-decoration:underline !important;
		}
}

.read_more--large{
		p{font-size:1rem}
}

.publications{
	margin:0px !important;
}

.publication__image{
	
	img{
		max-width:150px;
		width:100%;
		margin-bottom:8px;
	}

}

.specialist__bio{
	margin-bottom:40px;

	> p:last-child{
		margin-bottom:0px;
	}

	> ul:last-child{
		margin-bottom:0px !important;;
	}
}


.bio-contacts .bio-languages{
	
	margin:0px !important;

}


.rating__specialist__photo{
	width:100%;
	padding-bottom:100%;
	background-size:cover;
	border-radius:50%;
}
