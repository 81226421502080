/**
 * Page sections
 * =================================
 */



.full-width-hero{
	width:100%;	
	background-image:url(../img/hero-about.png);
	background-size:130%;
	background-repeat:no-repeat;
	background-position:center center;
	padding-bottom:13%;	

	margin-bottom:80px;

	@include media("<mid") {
		background-size:200%;
		padding-bottom:20%;	
	}

	@include media(">huge") {
		background-size:100%;
		padding-bottom:10%;	
	}


	@include media("<narrow") {
		background-size:250%;
		padding-bottom:25%;	
	}


	.nav-item{
		@include media("<mid") {
			display:none;
		}
	}

}



.full-width-hero--gynii{
	width:100%;	
	background-image:url(../img/gyniilearnhero.svg);
	background-size:contain;
	background-repeat:no-repeat;
	background-position:center center;
	padding-bottom:13%;	
	margin-left:0%;
	margin-bottom:80px;
	margin-top:-50px;

	@include media("<narrow") {
		background-image:url(../img/gyniilearnhero--mobile.svg);
		padding-bottom:40%;	
	}


}






.g-content-l{
	
	@include media(">mid") {
		width:85%;
	}
	
}

.g--reverse{

-webkit-flex-direction: row-reverse; /* Safari 6.1+ */
    display: flex;
    flex-direction: row-reverse; 




.g-content-l{
	
	@include media(">mid") {
		float:right;
		margin-top:5%;

	}
	
}


    }




.g--baseline > .g-col{
	padding-bottom:$page-section-gutter;
}



.image-block{
	width:100%;
	display:block;
}

.g-col--spacer{
	
	@include media("<mid") {
		display:none;	
	}
}


.content-l-c{
	
	width:65%;
	margin:0pt auto;

	h4{
	margin-bottom:20px;
	}
	@include media("<narrow") {
	width:100%;
	}

}


//Does this go into the about us page?

.team-members{
	
	header{
		margin-bottom:20px;

	}

}


.heading-flush{
	margin:0px;
}


.right-border{
	
	border-right:1px solid $dark-grey;
}


.gutter-half{
	margin-bottom:30px;
}

.gutter-bottom{
	margin-bottom:20px;
}

.image-block-negative{
	
@include media("<mid") {
	margin-top:-50px;
	}


}


