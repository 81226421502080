/**
 * Browser upgrade message
 * =================================
 */
.browserupgrade {
	margin: .2em 0;
	background: #ccc;
	color: #000;
	padding: .2em 0;
}
