/**
 * Global app/site styles
 * =================================
 */
body {
	background-color: $color-bg;
	min-width:320px;
}

/**
 * Default layout container
 */
.l-container {
	width: 90%;
	max-width: #{$layout-max-width}px;
	margin: 0 auto;
}


.l-container-contact{

	width: 90%;
	max-width: 1100px;
	margin: 0 auto;


	h1{
		margin-bottom:30px;
	}
}

.l-container-restricted{

	width: 90%;
	max-width: #{$layout-max-width-restricted}px;
	margin: 0 auto;

}


.l-container-huge{

	width: 90%;
	max-width: #{$layout-max-width--huge}px;
	margin: 0 auto;

}