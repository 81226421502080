.appointment-form{
	
	min-height:100%;
	
	background:#fafafa;

	padding-top:50px;

	&__inner{
		width:90%;
		//max-width:900px;
		margin:0pt auto;
	}

	table{
		width:300px
	}

	header{

		padding-bottom:50px;

		h1{
			font-size:30px !important;
			color:#000000;
			font-weight:normal;
			margin-bottom:20px !important;
		}

		p{
			font-size:18px !important;
			color:#000000;
			font-weight:300;
		}
	}

	form{
		overflow:hidden;


		textarea{
			height:150px;
			padding-top:28px;
		}
		h2{
			font-size:16px !important;
			font-weight:300 !important;
			color:#000000;
			font-weight:normal !important;
			margin-bottom:10px;
		}


		label{
			font-size:16px;
			font-weight:300 !important;
			color:#aaaaaa;
			margin-bottom:10px;
			font-weight:normal;
			margin-top:0px;
		}

		input{
			color:#aaaaaa;
		}


	}

	&__left{
		float:left;
		width:32%;

		@include media("<mid") {
			width:100%;
		}


	}

	&__right{
		float:right;
		width:56%;

		@include media("<mid") {
			width:100%;
		}

	}

	&__submit{
		float:left;
		text-align:center;
		padding-top:50px;
		padding-bottom:50px;
		width:100%;

		button{
		max-width:400px;
		display:inline-block;
		}

		@include media("<mid") {
			button{
			max-width:100%;
			}
		}

	}

	footer{

		padding-bottom:50px;
		padding-top:50px;
		text-align:left;

		font-weight:300 !important;
			color:#000000;
			font-weight:normal;
			margin-bottom:10px;

			p{
			font-size:16px !important;
			}
	}


	.logo-and-address{

		overflow:hidden;
		margin-top:20px;

		&__image{
			float:left;
			width:240px;
			//height:110px;
			margin-top:5px;

			img{
				width:100%;
				display:block;
			}
		}	

		&__content{

			padding-left:290px;

		}


		@include media("<600px") {
		
			.logo-and-address__image{
				width:140px;
				
			}	

			.logo-and-address__content{

				padding-left:0px;
				width:100%;
				float:left;
				margin-top:20px;
			}


		}

	}


}


.field-validation-error{
	
	font-size: 14px;
    color: red;
    padding-top: 5px;
    display: block;
    
}
	
	.contact-box-label{

		float:left;
		width:100%;
		margin-top:50px;

	}

	.contact-box-heading p{

		float:left;
		width:100%;
		margin-top:0px;

		font-size:30px !important;
		line-height:44px;
		margin-bottom:10px;


		@include media("<mid") {
			font-size:25px !important;
			line-height:40px;
					margin-bottom:0px;

		}


		@include media("<narrow") {
			font-size:20px !important;
			line-height:30px;
		}

		font-weight:300;
		color:#666666 !important;
		
	}


	.contact-team{
	@include media("<mid") {
			margin-top:-30px;
		}


	}
	.contact-box{

		float:left;
		width:50%;


		@include media("<500px") {
			width:100%;
		}


		&--full{
			width:100%;
		}

		display:block;
		margin-top:20px;

		p{
		display:block;
		width:100%;
		min-height:40px;
		}
		&__image{
			float:left;
			width:40px;
			height:40px;
			margin-top:5px;

			img{
				width:100%;
				display:block;
			}
		}	

		&__content{

			padding-left:60px;
			padding-top:5px;

		}

		a{
			color:#000000;
			&:hover{
				text-decoration:underline;
			}
		}

	}



	.g-col-filler{


	display:block;
	height:100%;

	}


.newsletter-opt-in{
	display: flex;
    align-items: center;
    margin-top: 10px;
}
