/**
 * Links
 * =================================
 */

a {
	
	//transition: $default-transition;

	&, &:link {
		color: $color-link-default;
		text-decoration: none;
	}

	&:visited {
		color: $color-link-visited;
		text-decoration: none;
	}

	&:hover, &:focus {
		color: $color-link-hover;
		//text-decoration: underline;
	}

	&:active {
		color: $color-link-active;
		//text-decoration: underline;
	}
}


