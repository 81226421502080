/**
 * Kickoff reset
 * =================================
 * We use normalize.css for the bulk of our 'reset'
 */

@import "normalize";
// https://github.com/necolas/normalize.css
// https://github.com/JohnAlbin/normalize-scss (Sass port of normalize.css)

html {
	box-sizing: border-box;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

@include normalize();
