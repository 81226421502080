/**
 * ██╗  ██╗██╗ ██████╗██╗  ██╗ ██████╗ ███████╗███████╗
 * ██║ ██╔╝██║██╔════╝██║ ██╔╝██╔═══██╗██╔════╝██╔════╝
 * █████╔╝ ██║██║     █████╔╝ ██║   ██║█████╗  █████╗
 * ██╔═██╗ ██║██║     ██╔═██╗ ██║   ██║██╔══╝  ██╔══╝
 * ██║  ██╗██║╚██████╗██║  ██╗╚██████╔╝██║     ██║
 * ╚═╝  ╚═╝╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝
 * By Zander Martineau & Ashley Nolan
 * http://trykickoff.com
 *
 * Project dependencies
 * =================================
 * - Mixins, helpers and functions
 * - Core variables
 * - CSS resets
 * - Typography styles
 * - Components
 * - Partials
 * - Views
 * - Global styles
 * - Helper/utility classes
 * - Print styles
 *
 * Read about our distinction between views, partials and components at
 * https://trykickoff.com/learn/css.html#views-partials--components
 *
 * ## Yeoman
 * The Yeoman generator can also be used to add new views, partials and
 * components. e.g. `yo kickoff:sass:component foo` will create a new file:
 * `scss/components/_component.scss` and add the `@import` statement in this
 * file. Install the generator by running `npm i -g yo generator-kickoff`
 *
 */


/*
 * Mixins, helpers and functions
 */
@import "kickoff-utils"; // From github.com/mrmartineau/kickoff-utils.scss
@import "include-media"; // From include-media.com


/**
 * Core variables
 * Edit your color palette and app vars before your begin
 */
@import "color-palette";
@import "variables";


/**
 * CSS resets
 */
@import "reset";


/**
 * Typography styles
 * See https://trykickoff.com/demos/typography.html for more info
 */
@import "typography";


/**
 * Components
 * See https://trykickoff.com/demos/components.html for more info
 *
 * Note: place all 3rd party components at the top of the list
 */
@import "kickoff-fluidVideo"; // https://github.com/mrmartineau/kickoff-fluidVideo.css
@import "kickoff-grid"; // https://github.com/TryKickoff/kickoff-grid.css - docs: trykickoff.com/docs/grid.html & demo: trykickoff.com/demos/grids.html
@import "components/forms/forms"; // https://trykickoff.com/demos/forms.html
@import "components/links";
@import "components/buttons";
@import "components/lists";
@import "components/embedded-content"; // images etc
@import "components/icons";
@import "components/skip-navigation";
@import "components/code";
@import "components/tables";
@import "components/slideoutJs"; // SlideoutJS
@import "components/page-section";  
@import "components/page-hero"; 
@import "components/aligner"; 
@import "components/team-member"; 
@import "components/navs"; 
@import "components/social-strip"; 
@import "components/pagination"; 
@import "components/learn"; 
@import "components/profile"; 
@import "components/specialists"; 
@import "components/search-result"; 
@import "components/appointment-form"; 
@import "components/floating-labels"; 
@import "components/form"; 
@import "components/check"; 
@import "components/tooltipster"; 



// @import "components/scrollbars";
// yo:start -- component
// yo:end -- component


/**
 * Partials
 */
@import "partials/masthead";
@import "partials/footer";
@import "partials/browser-upgrade";
@import "partials/modal";
// yo:start -- partial
// yo:end -- partial


/**
 * Views
 */
//@import "views/home";
//@import "views/contact";
@import "views/listings";
@import "views/opinion";
// yo:start -- view
// yo:end -- view


/**
 * Global styles
 */
@import "global";


/**
 * Helper/utility classes
 */
@import "helper-classes";

/**
 * Print styles
 */
@import "views/print";
