/**
 * Global color palette
 * =================================
 * - Generic colors
 * - Color scheme
 * - Common colors
 * - Links
 * - Text selection
 * - <hr>
 * - Any others..
 */

$brand                        : #FFA328;

// Generic colors
$blue                         : #288ad6;
$green                        : #16c98d;
$red                          : #fa5e5b;
$yellow                       : #feef6d;
$orange                       : #FFA328;
$pink                         : #ff708e;
$purple                       : #8e44ad;
$gray                         : #fafafa;
$grey                         : #fafafa;
$dark-grey					  : #bababa;

$menu-grey : #A0A0A0;
$menu-green : #1B978E;
$menu-orange : #FFA328;
$menu-mobile-grey : #646464;



$dark                         : #000;
$bright                       : #fff;

$color-kickoff-blue           : #181830;
$color-kickoff-orange         : #fe7c08;

// Color Scheme
$color-primary                : #181830;
$color-secondary              : $orange;
$color-highlight              : $brand;

// Common colors
$color-text                   : #444;
$color-bg                     : #ffffff;
$color-border                 : #ddd;


// Links
$color-link-default           : $color-highlight;
$color-link-secondary         : #F76E83;
$color-link-visited           : $color-link-default;
$color-link-hover             : darken($color-link-default, 20%);
$color-link-active            : darken($color-link-default, 20%);

// Text Selection
$color-selection              : #fff;
$color-selection-bg           : $color-primary;


// <hr> border color
$hr-color                     : lighten(#000, 70%);

// 3rd party brand colors - find more at brandcolors.net
$color-twitter                : #55acee;
$color-facebook               : #3b5998;
$color-youtube                : #cd201f;
$color-pinterest              : #bd081c;
$color-instagram              : #e4405f;
$color-whatsapp               : #25d366;
