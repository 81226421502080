.author-center {
	padding: 10px;
	text-align: center;

	.author {

		h5 {
			font-size: 0.8rem;
		}
	}
}