/**
 * Social strip
 * =================================
 */


#social-panel{
	
	padding-top:90px !important;
}
 .social-strip{
		
	padding-bottom:90px !important;

	@include media("<950px") {
		.text-right,
		.text-left {
			text-align: center !important;
		}
	}


	&--small{

	padding-bottom:0px !important;	

	.l-list--centred{
	text-align:left !important;
	}
	}


	.l-list--centred{

		margin:0px;

		li{
			padding-left:10px;
			padding-right:10px;
		}

		.linkedin-logo, .link--twitter, .link--facebook, .link--instagram, .link--google{
			display:inline-block;
		}

		.link--twitter:hover #FOOTER{
			fill:#55acee;
			transition: all .2s ease-in-out;
		}

		.link--facebook:hover .facebook-logo{
			transition: all .2s ease-in-out;
			fill:#3b5998;
		}

		.link--linkedin:hover #ABOUT-US---ROLLOVER{
			transition: all .2s ease-in-out;
			fill:#007bb5;
		}

		.link--instagram:hover #FOOTER{
			transition: all .2s ease-in-out;
			fill:#8a3ab9;
		}


		.link--google:hover #FOOTER{
			transition: all .2s ease-in-out;
			fill:#dd4b39;
		}

		


	}

}


.rating__social{
		.social-strip{
			padding-bottom:0px !important;
		}

}

.share-header {
	padding-top: 20px;
}