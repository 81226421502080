@charset "UTF-8";
/**
 * ██╗  ██╗██╗ ██████╗██╗  ██╗ ██████╗ ███████╗███████╗
 * ██║ ██╔╝██║██╔════╝██║ ██╔╝██╔═══██╗██╔════╝██╔════╝
 * █████╔╝ ██║██║     █████╔╝ ██║   ██║█████╗  █████╗
 * ██╔═██╗ ██║██║     ██╔═██╗ ██║   ██║██╔══╝  ██╔══╝
 * ██║  ██╗██║╚██████╗██║  ██╗╚██████╔╝██║     ██║
 * ╚═╝  ╚═╝╚═╝ ╚═════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝     ╚═╝
 * By Zander Martineau & Ashley Nolan
 * http://trykickoff.com
 *
 * Project dependencies
 * =================================
 * - Mixins, helpers and functions
 * - Core variables
 * - CSS resets
 * - Typography styles
 * - Components
 * - Partials
 * - Views
 * - Global styles
 * - Helper/utility classes
 * - Print styles
 *
 * Read about our distinction between views, partials and components at
 * https://trykickoff.com/learn/css.html#views-partials--components
 *
 * ## Yeoman
 * The Yeoman generator can also be used to add new views, partials and
 * components. e.g. `yo kickoff:sass:component foo` will create a new file:
 * `scss/components/_component.scss` and add the `@import` statement in this
 * file. Install the generator by running `npm i -g yo generator-kickoff`
 *
 */
/*
 * Mixins, helpers and functions
 */
/**
 * Colour functions
 */
/*
$color-palette: (
  white: (
    base: #ffffff
  ),

  sky: (
    lighter: #f9fafb,
    light: #f4f6f8,
    base: #dfe3e8,
    dark: #c4cdd5
  )
);
*/
/**
 * Color
 * Returns the color value for a given color name and group.
 * HT Shopify Polaris team
 *
 * @param {String} $hue - The color's hue.
 * @param {String} $value - The darkness/lightness of the color. Defaults to base.
 * @param {Color} $for-background - The background color on which this color will
 * appear. Applies a multiply filter to ensure appropriate contrast.
 * @return {Color} The color value.
 *
 * Usage (based on the Sass map above):
 * ko-color(white)
 * ko-color(sky, dark)
 * ko-color(sky, lighter)
 *
 */
/**
 * Darkens the foreground color by the background color. This is the same as the
 * “multiply” filter in graphics apps.
 *
 * @param {Color} $foreground - The color to darken.
 * @param {Color} $background - The background to base darkening on.
 * @return {Color} The modified color.
 */
/**
 * Tint
 * Add percentage of white to a color
 * Usage:
 * background-color: ko-color-tint(blue, 20%);
 */
/**
 * Shade
 * Add percentage of black to a color
 * Usage:
 * background-color: ko-color-shade(blue, 20%);
 */
/**
 * Usage:
 *
 * ko-getVal(100, $map) - uses an integer
 * ko-getVal(mid, $map) - uses a Sass map
 * ko-getVal($foo, $map) - uses a Sass variable
 */
/**
 * Get breakpoints
 *
 * ko-bp()
 *
 * Usage:
 * ko-bp(100) - uses an integer
 * ko-bp(mid) - uses the $breakpoints Sass map
 * ko-bp($foo) - uses a Sass variable
 */
/**
 * Get type size
 *
 * ko-type()
 *
 * Usage:
 * ko-type(100) - uses an integer
 * ko-type(mid) - uses the $type Sass map
 * ko-type($foo) - uses a Sass variable
 */
/**
 * Get font-family
 *
 * ko-font()
 *
 * Usage:
 * ko-font(system) - uses 'system' font stack
 * ko-font(sans) - uses the 'sans' font stack
 */
/**
 * Get z-index value
 *
 * ko-zIndex()
 *
 * Usage:
 * ko-zIndex(low) - uses 'low' z-index value
 * ko-zIndex(mid) - uses 'mid' z-index value
 */
/**
 * Usage:
 *
 $grid-configuration: (
   'columns': 12,
   'layouts': (
     'small': 800px,
     'medium': 1000px,
     'large': 1200px,
   ),
 );

 div {
   font-size: ko-map-deep-get($grid-configuration, 'columns');
   width: ko-map-deep-get($grid-configuration, 'layouts', 'medium');
 }
 */
/**
 * Modular scale
 * =================================
 * Usage: ko-modular-scale($font-size-base, 3, $type-scale)
 * or with the shorthand version: ko-ms(3)
 */
/**
 * Shorthand modular scale
 * Uses the $font-size-base and $type-scale framework variables so that you
 * only need to provide the scale increment
 *
 * e.g. ko-ms(5)
 */
/**
 * Multiply
 * =================
 * Multiply any value
 *
 * Usage:
 * ko-multiply(15, 2)
 * ko-multiply($baseline, 1.5)
 */
/**
 * Sass Mixins
 * - Responsive
 * - Dimensions
 * - Positioning
 * - Utility
 * - Forms
 * - Module Naming Helpers
 */
/**
 * Responsive media query mixins
 * - These are used so that we can define separate media-query content
 * - Arguments can use:
 *   - a named breakpoint in the $breakpoints map, e.g. mid
 *   - a pixel value e.g. 500px
 *   - a unitless value that is eqivalent to a pixel e.g. 500
 */
/**
 * Min-width media query:
 * - Equivalent to: @media screen and (min-width: 20em) { ... }
 * - Usage: @include ko-respond-min(mid) { ... };
 */
/**
 * Max-width media query:
 * - Equivalent to: @media screen and (max-width: 20em) { ... }
 * - Usage: @include ko-respond-max(mid) { ... };
 */
/**
 * Min-max-width media query:
 * - Equivalent to: @media screen and (min-width: 10em) and (max-width: 20em) { ... }
 * - Usage: @include ko-respond-min-max(narrow, 600) { ... };
 */
/**
 *  HiDPI mixin.
 *  @include ko-hidpi { ... };
 *  Default value set to 1.3 to target Google Nexus 7 (http://bjango.com/articles/min-device-pixel-ratio/)
 */
/**
 * Hidpi with a minimum width media query
 * @include ko-hidpi-min(bp(mid), 1.5) { ... };
 */
/**
 * Hidpi with a max width media query
 * @include ko-hidpi-max(bp(mid), 1.3) { ... };
 */
/**
 * Dimensions-based mixins
 * - REM calculation
 * - Typography mixins based on the REM mixin
 *   - font-size
 *   - line-height
 *   - font-size-ems
 */
/**
 * Typography mixins
 */
/**
 * ko-position
 * Usage:
 * @include ko-position(absolute, 10px 20px 30px 10px);
 */
/**
 * vertical-center
 * Vertically center any element. Needs support for CSS tranforms.
 * Usage:
 * @include ko-vertical-center;
 */
/**
 * Utility Mixins
 * - clearfix
 * - font-face
 * -
 * - sizing shortcuts
 *   - size
 *   - square
 * - resizable
 */
/**
 * Sizing shortcuts
 */
/**
 * bemify [modified for Kickoff]
 * https://gtihub.com/franzheidl/bemify
 * Franz Heidl 2015
 *
 * Usage: https://gist.github.com/mrmartineau/0cd2010bf265d712bafb
 */
/**
 * Core variables
 * Edit your color palette and app vars before your begin
 */
/**
 * Global color palette
 * =================================
 * - Generic colors
 * - Color scheme
 * - Common colors
 * - Links
 * - Text selection
 * - <hr>
 * - Any others..
 */
/**
 * Variables
 * =================================
 * - Typography
 * - Breakpoints
 * - Paths
 * - Layout & Grid
 * - App-specific
 */
/* stylelint-disable indentation */
/**
 * Typography
 * =================================
 *
 * Base sizes:
 * Set this in pixels (but do not add px),
 * the font-size mixin will convert to REMS
 */
@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900");
/**
 * Breakpoints
 * =================================
 * There are no common breakpoints so these are just a suggestions
 * You'll need to define your own breakpoints to suit your design
 * Add your breakpoints to this Sass map
 * Used in the Kickoff grid
 */
/**
 * Grid
 * =================================
 * These variables will control the grid module.
 *
 * if your project doesnt use Kickoff’s default grid, you can delete these
 */
/**
 * Path variables
 * =================================
 */
/**
 * Z-index variables
 * =================================
 */
/**
 * App-specific variables
 * =================================
 */
/**
 * Page sections vars
 * =================================
 */
h2.smaller {
  font-size: 40px;
  margin-bottom: 10px; }

/**
 * CSS resets
 */
/**
 * Kickoff reset
 * =================================
 * We use normalize.css for the bulk of our 'reset'
 */
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

/**
 * Typography styles
 * See https://trykickoff.com/demos/typography.html for more info
 */
/**
 * Typography
 * =================================
 * - Base
 * - Paragraphs
 * - Headings
 * - Type sizes
 * - Misc
 * - Utilities
 *
 * Body font size, leadings etc have been set in _variables.scss
 *
 * See http://modularscale.com for
 *
 */
html {
  font-size: 17px;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 951px) {
    html {
      font-size: 19px; } }

body {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #444;
  font-weight: 300; }

/**
 * Paragraphs
 */
p {
  font-family: "Lato", sans-serif;
  font-size: 1rem;
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: 300; }

/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  line-height: 1.1;
  text-rendering: optimizelegibility;
  margin-top: 0;
  margin-bottom: 30px; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    font-weight: normal; }

h1,
.h1,
.alpha {
  font-size: 2.44141rem;
  margin-bottom: 30px;
  color: #FFA328; }

h2,
.h2,
.beta {
  font-size: 2.44141rem;
  margin-bottom: 30px;
  color: #FFA328; }

h3,
.h3,
.gamma {
  font-size: 1.95313rem;
  font-weight: 300; }

h4,
.h4,
.delta {
  font-size: 1rem; }

h5,
.h5,
.epsilon,
h6,
.h6,
.zeta {
  font-size: 1rem;
  margin-bottom: 0; }

* + h1,
* + .h1,
* + .alpha,
* + h2,
* + .h2,
* + .beta,
* + h3,
* + .h3,
* + .gamma,
* + h4,
* + .h4,
* + .delta {
  margin-top: 30px; }

small {
  font-size: 80%; }

/**
 * Miscellaneous
 */
strong,
b {
  font-weight: bold; }

em,
i {
  font-style: italic; }

abbr[title] {
  border-bottom: 1px dotted #ddd;
  cursor: help; }

/**
 * Blockquotes
 */
blockquote {
  padding-left: 10px;
  margin: 30px;
  border-left: 4px solid #cccccc; }
  blockquote p {
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 300; }
  blockquote small {
    display: block;
    color: #b3b3b3; }
    blockquote small::before {
      content: '\2014 \00A0'; }

q::before, q::after,
blockquote::before,
blockquote::after {
  content: ""; }

cite {
  font-style: normal; }

dfn {
  font-style: italic; }

mark {
  background: #feef6d;
  padding: 2px 4px;
  border-radius: 3px; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

samp {
  font-family: "Lato", sans-serif; }

/**
 * Utilities
 */
.text-centre,
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

::-moz-selection {
  color: #fff;
  background: #181830;
  text-shadow: none; }

::selection {
  color: #fff;
  background: #181830;
  text-shadow: none; }

hr {
  margin: 30px 0;
  border: 0;
  height: 1px;
  background-color: #b3b3b3; }

.hyphenate {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto; }

/**
 * Components
 * See https://trykickoff.com/demos/components.html for more info
 *
 * Note: place all 3rd party components at the top of the list
 */
.fluidVideo {
  position: relative;
  height: 0;
  padding-bottom: 56.25%; }
  .fluidVideo--4-3 {
    padding-bottom: 75%; }

.fluidVideo-item,
.fluidVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/**
 * The Kickoff Flexbox Grid
 * =================================
 * Default grid classes
 * Grid gutter options
 * Modifier Classes (column height and cell alignment)
 * Legacy fallbacks for flexbox
 * Grid span classes
 * Breakpoint stacking
 */
/* Import Grid helpers and mixins */
/**
 * Grid Variables
 * =================================
 * These are defined here so that the grid can run as a standalone module (outside of Kickoff),
 * as well as allowing these files to be generated into standard CSS.
 *
 * When importing the grid into your own project, copy the variables from this file into your own variables.scss
 * They will then override the values specified here (as long as the grid is imported below where your own variables are defined).
 */
/**
 * Kickoff grid helpers
 */
/**
 * Grid stacking
 * Stack grids based on a given breakpoint
 *
 * Usage:
 * .g--stack--narrow
 * .g--stack--wide
 */
/**
 * Gutter Calculations
 * Default: percent
 * Usage: ko-gutterCalc() or ko-gutterCalc(false)
 * When show-unit is true, returns the percentage, otherwise return a decimal
 */
/**
 * Grid columns width calculations
 * This is where the magic of working out the column widths happens
 *
 * $column-span: define the width for the number of columns required
 * $show-unit: Switch return value between percentage (default) and decimal
 * $include-gutters: if gutters should be included in the calculations. Default = false
 * $legacy-calc: if we are working out a legacy column width calculation, or not. Default = false
 */
/**
 * Column width mixin
 * Usage:
 * @include column(2);
 */
/**
 * Basic Usage:
 * =================================
	<div class="g">
		<div class="g-col g-span4 g-span6--mid"></div>
		<div class="g-col g-span8 g-span6--mid"></div>
	</div>
 */
.g {
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row; }
  .g img {
    max-width: 100%; }

.g-col {
  display: block;
  box-sizing: border-box;
  flex: 1 0 0;
  min-width: 0; }

/**
 * Gutter calcs – applied to grid columns in our grid (direct descendants only)
 * Default: pixels (can look at changing to percentage)
 * Usage: gutterCalc() or gutterCalc(false)
 */
.g--gutter {
  margin-left: -10px;
  margin-right: -10px; }
  .g--gutter > .g-col {
    padding-left: 10px;
    padding-right: 10px; }

.g--gutter--scaled > .g-col {
  margin-left: 1.42857%;
  margin-right: 0; }
  .g--gutter--scaled > .g-col:first-child {
    margin-left: 0; }

.g--stack > .g-col {
  flex-basis: 100%;
  max-width: 100%; }

.g--stack.g--gutter--scaled > .g-col {
  margin-left: 0; }

/**
 * .g--equalHeight – Equal Height Child Elements
 */
.g--equalHeight > .g-col {
  display: flex; }
  .g--equalHeight > .g-col > * {
    flex-basis: 100%; }

/**
 * Alignment
 * Modifier classes to move our grid elements around
 */
.g--alignTop {
  align-items: flex-start; }

.g--alignBottom {
  align-items: flex-end; }

.g--alignSelfBottom {
  align-self: flex-end; }

.g--alignRight {
  justify-content: flex-end; }

.g--alignCenter {
  justify-content: center; }

.g--alignCenter--v {
  align-items: center; }

/**
 * Centering
 * Centers an individual column, rather than the entire grid
 */
.g-col--centered {
  margin: 0 auto; }

/**
 * Shrinking Content
 * Shrink a .g-col down to only the space it needs (flexbox only)
 *
 * Effectively just changes it’s values back to the default flex properties
 */
.g-col--shrink {
  flex: 0 1 auto; }

/**
 * Add fallbacks for non-flexbox supporting browsers (for example, IE8/9)
 */
.no-flexbox .g {
  display: block !important; }
  .no-flexbox .g:after {
    content: "";
    display: table;
    clear: both; }

.no-flexbox .g-col {
  float: left;
  min-height: 1px;
  clear: none;
  box-sizing: border-box; }

.no-flexbox .g--stack .g-col {
  width: 100%; }

.no-flexbox .g--equalHeight > .g-col {
  display: block; }

/**
 * Grid Span classes (for different breakpoints)
 *
 * Applied by using .g-spanx to .g-col elements, where x is the number of columns
 */
.g-span1 {
  flex-basis: 8.33333% !important;
  flex-grow: 0;
  max-width: 8.33333% !important; }
  .g-span1.g-holdWidth {
    min-width: 116.66667px; }
    .g--gutter--scaled .g-span1.g-holdWidth {
      min-width: 96.66667px; }
  .g--gutter--scaled .g-span1 {
    flex-basis: 7.02381% !important;
    max-width: 7.02381%;
    margin-left: 1.42857%; }
  .no-flexbox .g-span1 {
    width: 8.33333% !important; }

.g-offset1 {
  margin-left: 8.33333% !important; }

.g-span2 {
  flex-basis: 16.66667% !important;
  flex-grow: 0;
  max-width: 16.66667% !important; }
  .g-span2.g-holdWidth {
    min-width: 233.33333px; }
    .g--gutter--scaled .g-span2.g-holdWidth {
      min-width: 213.33333px; }
  .g--gutter--scaled .g-span2 {
    flex-basis: 15.47619% !important;
    max-width: 15.47619%;
    margin-left: 1.42857%; }
  .no-flexbox .g-span2 {
    width: 16.66667% !important; }

.g-offset2 {
  margin-left: 16.66667% !important; }

.g-span3 {
  flex-basis: 25% !important;
  flex-grow: 0;
  max-width: 25% !important; }
  .g-span3.g-holdWidth {
    min-width: 350px; }
    .g--gutter--scaled .g-span3.g-holdWidth {
      min-width: 330px; }
  .g--gutter--scaled .g-span3 {
    flex-basis: 23.92857% !important;
    max-width: 23.92857%;
    margin-left: 1.42857%; }
  .no-flexbox .g-span3 {
    width: 25% !important; }

.g-offset3 {
  margin-left: 25% !important; }

.g-span4 {
  flex-basis: 33.33333% !important;
  flex-grow: 0;
  max-width: 33.33333% !important; }
  .g-span4.g-holdWidth {
    min-width: 466.66667px; }
    .g--gutter--scaled .g-span4.g-holdWidth {
      min-width: 446.66667px; }
  .g--gutter--scaled .g-span4 {
    flex-basis: 32.38095% !important;
    max-width: 32.38095%;
    margin-left: 1.42857%; }
  .no-flexbox .g-span4 {
    width: 33.33333% !important; }

.g-offset4 {
  margin-left: 33.33333% !important; }

.g-span5 {
  flex-basis: 41.66667% !important;
  flex-grow: 0;
  max-width: 41.66667% !important; }
  .g-span5.g-holdWidth {
    min-width: 583.33333px; }
    .g--gutter--scaled .g-span5.g-holdWidth {
      min-width: 563.33333px; }
  .g--gutter--scaled .g-span5 {
    flex-basis: 40.83333% !important;
    max-width: 40.83333%;
    margin-left: 1.42857%; }
  .no-flexbox .g-span5 {
    width: 41.66667% !important; }

.g-offset5 {
  margin-left: 41.66667% !important; }

.g-span6 {
  flex-basis: 50% !important;
  flex-grow: 0;
  max-width: 50% !important; }
  .g-span6.g-holdWidth {
    min-width: 700px; }
    .g--gutter--scaled .g-span6.g-holdWidth {
      min-width: 680px; }
  .g--gutter--scaled .g-span6 {
    flex-basis: 49.28571% !important;
    max-width: 49.28571%;
    margin-left: 1.42857%; }
  .no-flexbox .g-span6 {
    width: 50% !important; }

.g-offset6 {
  margin-left: 50% !important; }

.g-span7 {
  flex-basis: 58.33333% !important;
  flex-grow: 0;
  max-width: 58.33333% !important; }
  .g-span7.g-holdWidth {
    min-width: 816.66667px; }
    .g--gutter--scaled .g-span7.g-holdWidth {
      min-width: 796.66667px; }
  .g--gutter--scaled .g-span7 {
    flex-basis: 57.7381% !important;
    max-width: 57.7381%;
    margin-left: 1.42857%; }
  .no-flexbox .g-span7 {
    width: 58.33333% !important; }

.g-offset7 {
  margin-left: 58.33333% !important; }

.g-span8 {
  flex-basis: 66.66667% !important;
  flex-grow: 0;
  max-width: 66.66667% !important; }
  .g-span8.g-holdWidth {
    min-width: 933.33333px; }
    .g--gutter--scaled .g-span8.g-holdWidth {
      min-width: 913.33333px; }
  .g--gutter--scaled .g-span8 {
    flex-basis: 66.19048% !important;
    max-width: 66.19048%;
    margin-left: 1.42857%; }
  .no-flexbox .g-span8 {
    width: 66.66667% !important; }

.g-offset8 {
  margin-left: 66.66667% !important; }

.g-span9 {
  flex-basis: 75% !important;
  flex-grow: 0;
  max-width: 75% !important; }
  .g-span9.g-holdWidth {
    min-width: 1050px; }
    .g--gutter--scaled .g-span9.g-holdWidth {
      min-width: 1030px; }
  .g--gutter--scaled .g-span9 {
    flex-basis: 74.64286% !important;
    max-width: 74.64286%;
    margin-left: 1.42857%; }
  .no-flexbox .g-span9 {
    width: 75% !important; }

.g-offset9 {
  margin-left: 75% !important; }

.g-span10 {
  flex-basis: 83.33333% !important;
  flex-grow: 0;
  max-width: 83.33333% !important; }
  .g-span10.g-holdWidth {
    min-width: 1166.66667px; }
    .g--gutter--scaled .g-span10.g-holdWidth {
      min-width: 1146.66667px; }
  .g--gutter--scaled .g-span10 {
    flex-basis: 83.09524% !important;
    max-width: 83.09524%;
    margin-left: 1.42857%; }
  .no-flexbox .g-span10 {
    width: 83.33333% !important; }

.g-offset10 {
  margin-left: 83.33333% !important; }

.g-span11 {
  flex-basis: 91.66667% !important;
  flex-grow: 0;
  max-width: 91.66667% !important; }
  .g-span11.g-holdWidth {
    min-width: 1283.33333px; }
    .g--gutter--scaled .g-span11.g-holdWidth {
      min-width: 1263.33333px; }
  .g--gutter--scaled .g-span11 {
    flex-basis: 91.54762% !important;
    max-width: 91.54762%;
    margin-left: 1.42857%; }
  .no-flexbox .g-span11 {
    width: 91.66667% !important; }

.g-offset11 {
  margin-left: 91.66667% !important; }

.g-span12 {
  flex-basis: 100% !important;
  flex-grow: 0;
  max-width: 100% !important; }
  .g-span12.g-holdWidth {
    min-width: 1400px; }
    .g--gutter--scaled .g-span12.g-holdWidth {
      min-width: 1380px; }
  .g--gutter--scaled .g-span12 {
    flex-basis: 100% !important;
    max-width: 100%;
    margin-left: 0; }
  .no-flexbox .g-span12 {
    width: 100% !important; }

@media (min-width: 401px) {
  .g-span1--narrow {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--narrow.g-holdWidth--narrow {
      min-width: 116.66667px; }
      .g--gutter--scaled .g-span1--narrow.g-holdWidth--narrow {
        min-width: 96.66667px; }
    .g--gutter--scaled .g-span1--narrow {
      flex-basis: 7.02381% !important;
      max-width: 7.02381%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span1--narrow {
      width: 8.33333% !important; }
  .g-offset1--narrow {
    margin-left: 8.33333% !important; }
  .g-span2--narrow {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--narrow.g-holdWidth--narrow {
      min-width: 233.33333px; }
      .g--gutter--scaled .g-span2--narrow.g-holdWidth--narrow {
        min-width: 213.33333px; }
    .g--gutter--scaled .g-span2--narrow {
      flex-basis: 15.47619% !important;
      max-width: 15.47619%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span2--narrow {
      width: 16.66667% !important; }
  .g-offset2--narrow {
    margin-left: 16.66667% !important; }
  .g-span3--narrow {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--narrow.g-holdWidth--narrow {
      min-width: 350px; }
      .g--gutter--scaled .g-span3--narrow.g-holdWidth--narrow {
        min-width: 330px; }
    .g--gutter--scaled .g-span3--narrow {
      flex-basis: 23.92857% !important;
      max-width: 23.92857%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span3--narrow {
      width: 25% !important; }
  .g-offset3--narrow {
    margin-left: 25% !important; }
  .g-span4--narrow {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--narrow.g-holdWidth--narrow {
      min-width: 466.66667px; }
      .g--gutter--scaled .g-span4--narrow.g-holdWidth--narrow {
        min-width: 446.66667px; }
    .g--gutter--scaled .g-span4--narrow {
      flex-basis: 32.38095% !important;
      max-width: 32.38095%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span4--narrow {
      width: 33.33333% !important; }
  .g-offset4--narrow {
    margin-left: 33.33333% !important; }
  .g-span5--narrow {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--narrow.g-holdWidth--narrow {
      min-width: 583.33333px; }
      .g--gutter--scaled .g-span5--narrow.g-holdWidth--narrow {
        min-width: 563.33333px; }
    .g--gutter--scaled .g-span5--narrow {
      flex-basis: 40.83333% !important;
      max-width: 40.83333%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span5--narrow {
      width: 41.66667% !important; }
  .g-offset5--narrow {
    margin-left: 41.66667% !important; }
  .g-span6--narrow {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--narrow.g-holdWidth--narrow {
      min-width: 700px; }
      .g--gutter--scaled .g-span6--narrow.g-holdWidth--narrow {
        min-width: 680px; }
    .g--gutter--scaled .g-span6--narrow {
      flex-basis: 49.28571% !important;
      max-width: 49.28571%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span6--narrow {
      width: 50% !important; }
  .g-offset6--narrow {
    margin-left: 50% !important; }
  .g-span7--narrow {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--narrow.g-holdWidth--narrow {
      min-width: 816.66667px; }
      .g--gutter--scaled .g-span7--narrow.g-holdWidth--narrow {
        min-width: 796.66667px; }
    .g--gutter--scaled .g-span7--narrow {
      flex-basis: 57.7381% !important;
      max-width: 57.7381%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span7--narrow {
      width: 58.33333% !important; }
  .g-offset7--narrow {
    margin-left: 58.33333% !important; }
  .g-span8--narrow {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--narrow.g-holdWidth--narrow {
      min-width: 933.33333px; }
      .g--gutter--scaled .g-span8--narrow.g-holdWidth--narrow {
        min-width: 913.33333px; }
    .g--gutter--scaled .g-span8--narrow {
      flex-basis: 66.19048% !important;
      max-width: 66.19048%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span8--narrow {
      width: 66.66667% !important; }
  .g-offset8--narrow {
    margin-left: 66.66667% !important; }
  .g-span9--narrow {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--narrow.g-holdWidth--narrow {
      min-width: 1050px; }
      .g--gutter--scaled .g-span9--narrow.g-holdWidth--narrow {
        min-width: 1030px; }
    .g--gutter--scaled .g-span9--narrow {
      flex-basis: 74.64286% !important;
      max-width: 74.64286%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span9--narrow {
      width: 75% !important; }
  .g-offset9--narrow {
    margin-left: 75% !important; }
  .g-span10--narrow {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--narrow.g-holdWidth--narrow {
      min-width: 1166.66667px; }
      .g--gutter--scaled .g-span10--narrow.g-holdWidth--narrow {
        min-width: 1146.66667px; }
    .g--gutter--scaled .g-span10--narrow {
      flex-basis: 83.09524% !important;
      max-width: 83.09524%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span10--narrow {
      width: 83.33333% !important; }
  .g-offset10--narrow {
    margin-left: 83.33333% !important; }
  .g-span11--narrow {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--narrow.g-holdWidth--narrow {
      min-width: 1283.33333px; }
      .g--gutter--scaled .g-span11--narrow.g-holdWidth--narrow {
        min-width: 1263.33333px; }
    .g--gutter--scaled .g-span11--narrow {
      flex-basis: 91.54762% !important;
      max-width: 91.54762%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span11--narrow {
      width: 91.66667% !important; }
  .g-offset11--narrow {
    margin-left: 91.66667% !important; }
  .g-span12--narrow {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--narrow.g-holdWidth--narrow {
      min-width: 1400px; }
      .g--gutter--scaled .g-span12--narrow.g-holdWidth--narrow {
        min-width: 1380px; }
    .g--gutter--scaled .g-span12--narrow {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--narrow {
      width: 100% !important; } }

@media (min-width: 950px) {
  .g-span1--mid {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--mid.g-holdWidth--mid {
      min-width: 116.66667px; }
      .g--gutter--scaled .g-span1--mid.g-holdWidth--mid {
        min-width: 96.66667px; }
    .g--gutter--scaled .g-span1--mid {
      flex-basis: 7.02381% !important;
      max-width: 7.02381%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span1--mid {
      width: 8.33333% !important; }
  .g-offset1--mid {
    margin-left: 8.33333% !important; }
  .g-span2--mid {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--mid.g-holdWidth--mid {
      min-width: 233.33333px; }
      .g--gutter--scaled .g-span2--mid.g-holdWidth--mid {
        min-width: 213.33333px; }
    .g--gutter--scaled .g-span2--mid {
      flex-basis: 15.47619% !important;
      max-width: 15.47619%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span2--mid {
      width: 16.66667% !important; }
  .g-offset2--mid {
    margin-left: 16.66667% !important; }
  .g-span3--mid {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--mid.g-holdWidth--mid {
      min-width: 350px; }
      .g--gutter--scaled .g-span3--mid.g-holdWidth--mid {
        min-width: 330px; }
    .g--gutter--scaled .g-span3--mid {
      flex-basis: 23.92857% !important;
      max-width: 23.92857%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span3--mid {
      width: 25% !important; }
  .g-offset3--mid {
    margin-left: 25% !important; }
  .g-span4--mid {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--mid.g-holdWidth--mid {
      min-width: 466.66667px; }
      .g--gutter--scaled .g-span4--mid.g-holdWidth--mid {
        min-width: 446.66667px; }
    .g--gutter--scaled .g-span4--mid {
      flex-basis: 32.38095% !important;
      max-width: 32.38095%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span4--mid {
      width: 33.33333% !important; }
  .g-offset4--mid {
    margin-left: 33.33333% !important; }
  .g-span5--mid {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--mid.g-holdWidth--mid {
      min-width: 583.33333px; }
      .g--gutter--scaled .g-span5--mid.g-holdWidth--mid {
        min-width: 563.33333px; }
    .g--gutter--scaled .g-span5--mid {
      flex-basis: 40.83333% !important;
      max-width: 40.83333%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span5--mid {
      width: 41.66667% !important; }
  .g-offset5--mid {
    margin-left: 41.66667% !important; }
  .g-span6--mid {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--mid.g-holdWidth--mid {
      min-width: 700px; }
      .g--gutter--scaled .g-span6--mid.g-holdWidth--mid {
        min-width: 680px; }
    .g--gutter--scaled .g-span6--mid {
      flex-basis: 49.28571% !important;
      max-width: 49.28571%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span6--mid {
      width: 50% !important; }
  .g-offset6--mid {
    margin-left: 50% !important; }
  .g-span7--mid {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--mid.g-holdWidth--mid {
      min-width: 816.66667px; }
      .g--gutter--scaled .g-span7--mid.g-holdWidth--mid {
        min-width: 796.66667px; }
    .g--gutter--scaled .g-span7--mid {
      flex-basis: 57.7381% !important;
      max-width: 57.7381%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span7--mid {
      width: 58.33333% !important; }
  .g-offset7--mid {
    margin-left: 58.33333% !important; }
  .g-span8--mid {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--mid.g-holdWidth--mid {
      min-width: 933.33333px; }
      .g--gutter--scaled .g-span8--mid.g-holdWidth--mid {
        min-width: 913.33333px; }
    .g--gutter--scaled .g-span8--mid {
      flex-basis: 66.19048% !important;
      max-width: 66.19048%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span8--mid {
      width: 66.66667% !important; }
  .g-offset8--mid {
    margin-left: 66.66667% !important; }
  .g-span9--mid {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--mid.g-holdWidth--mid {
      min-width: 1050px; }
      .g--gutter--scaled .g-span9--mid.g-holdWidth--mid {
        min-width: 1030px; }
    .g--gutter--scaled .g-span9--mid {
      flex-basis: 74.64286% !important;
      max-width: 74.64286%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span9--mid {
      width: 75% !important; }
  .g-offset9--mid {
    margin-left: 75% !important; }
  .g-span10--mid {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--mid.g-holdWidth--mid {
      min-width: 1166.66667px; }
      .g--gutter--scaled .g-span10--mid.g-holdWidth--mid {
        min-width: 1146.66667px; }
    .g--gutter--scaled .g-span10--mid {
      flex-basis: 83.09524% !important;
      max-width: 83.09524%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span10--mid {
      width: 83.33333% !important; }
  .g-offset10--mid {
    margin-left: 83.33333% !important; }
  .g-span11--mid {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--mid.g-holdWidth--mid {
      min-width: 1283.33333px; }
      .g--gutter--scaled .g-span11--mid.g-holdWidth--mid {
        min-width: 1263.33333px; }
    .g--gutter--scaled .g-span11--mid {
      flex-basis: 91.54762% !important;
      max-width: 91.54762%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span11--mid {
      width: 91.66667% !important; }
  .g-offset11--mid {
    margin-left: 91.66667% !important; }
  .g-span12--mid {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--mid.g-holdWidth--mid {
      min-width: 1400px; }
      .g--gutter--scaled .g-span12--mid.g-holdWidth--mid {
        min-width: 1380px; }
    .g--gutter--scaled .g-span12--mid {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--mid {
      width: 100% !important; } }

@media (min-width: 1000px) {
  .g-span1--wide {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--wide.g-holdWidth--wide {
      min-width: 116.66667px; }
      .g--gutter--scaled .g-span1--wide.g-holdWidth--wide {
        min-width: 96.66667px; }
    .g--gutter--scaled .g-span1--wide {
      flex-basis: 7.02381% !important;
      max-width: 7.02381%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span1--wide {
      width: 8.33333% !important; }
  .g-offset1--wide {
    margin-left: 8.33333% !important; }
  .g-span2--wide {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--wide.g-holdWidth--wide {
      min-width: 233.33333px; }
      .g--gutter--scaled .g-span2--wide.g-holdWidth--wide {
        min-width: 213.33333px; }
    .g--gutter--scaled .g-span2--wide {
      flex-basis: 15.47619% !important;
      max-width: 15.47619%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span2--wide {
      width: 16.66667% !important; }
  .g-offset2--wide {
    margin-left: 16.66667% !important; }
  .g-span3--wide {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--wide.g-holdWidth--wide {
      min-width: 350px; }
      .g--gutter--scaled .g-span3--wide.g-holdWidth--wide {
        min-width: 330px; }
    .g--gutter--scaled .g-span3--wide {
      flex-basis: 23.92857% !important;
      max-width: 23.92857%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span3--wide {
      width: 25% !important; }
  .g-offset3--wide {
    margin-left: 25% !important; }
  .g-span4--wide {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--wide.g-holdWidth--wide {
      min-width: 466.66667px; }
      .g--gutter--scaled .g-span4--wide.g-holdWidth--wide {
        min-width: 446.66667px; }
    .g--gutter--scaled .g-span4--wide {
      flex-basis: 32.38095% !important;
      max-width: 32.38095%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span4--wide {
      width: 33.33333% !important; }
  .g-offset4--wide {
    margin-left: 33.33333% !important; }
  .g-span5--wide {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--wide.g-holdWidth--wide {
      min-width: 583.33333px; }
      .g--gutter--scaled .g-span5--wide.g-holdWidth--wide {
        min-width: 563.33333px; }
    .g--gutter--scaled .g-span5--wide {
      flex-basis: 40.83333% !important;
      max-width: 40.83333%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span5--wide {
      width: 41.66667% !important; }
  .g-offset5--wide {
    margin-left: 41.66667% !important; }
  .g-span6--wide {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--wide.g-holdWidth--wide {
      min-width: 700px; }
      .g--gutter--scaled .g-span6--wide.g-holdWidth--wide {
        min-width: 680px; }
    .g--gutter--scaled .g-span6--wide {
      flex-basis: 49.28571% !important;
      max-width: 49.28571%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span6--wide {
      width: 50% !important; }
  .g-offset6--wide {
    margin-left: 50% !important; }
  .g-span7--wide {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--wide.g-holdWidth--wide {
      min-width: 816.66667px; }
      .g--gutter--scaled .g-span7--wide.g-holdWidth--wide {
        min-width: 796.66667px; }
    .g--gutter--scaled .g-span7--wide {
      flex-basis: 57.7381% !important;
      max-width: 57.7381%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span7--wide {
      width: 58.33333% !important; }
  .g-offset7--wide {
    margin-left: 58.33333% !important; }
  .g-span8--wide {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--wide.g-holdWidth--wide {
      min-width: 933.33333px; }
      .g--gutter--scaled .g-span8--wide.g-holdWidth--wide {
        min-width: 913.33333px; }
    .g--gutter--scaled .g-span8--wide {
      flex-basis: 66.19048% !important;
      max-width: 66.19048%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span8--wide {
      width: 66.66667% !important; }
  .g-offset8--wide {
    margin-left: 66.66667% !important; }
  .g-span9--wide {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--wide.g-holdWidth--wide {
      min-width: 1050px; }
      .g--gutter--scaled .g-span9--wide.g-holdWidth--wide {
        min-width: 1030px; }
    .g--gutter--scaled .g-span9--wide {
      flex-basis: 74.64286% !important;
      max-width: 74.64286%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span9--wide {
      width: 75% !important; }
  .g-offset9--wide {
    margin-left: 75% !important; }
  .g-span10--wide {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--wide.g-holdWidth--wide {
      min-width: 1166.66667px; }
      .g--gutter--scaled .g-span10--wide.g-holdWidth--wide {
        min-width: 1146.66667px; }
    .g--gutter--scaled .g-span10--wide {
      flex-basis: 83.09524% !important;
      max-width: 83.09524%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span10--wide {
      width: 83.33333% !important; }
  .g-offset10--wide {
    margin-left: 83.33333% !important; }
  .g-span11--wide {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--wide.g-holdWidth--wide {
      min-width: 1283.33333px; }
      .g--gutter--scaled .g-span11--wide.g-holdWidth--wide {
        min-width: 1263.33333px; }
    .g--gutter--scaled .g-span11--wide {
      flex-basis: 91.54762% !important;
      max-width: 91.54762%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span11--wide {
      width: 91.66667% !important; }
  .g-offset11--wide {
    margin-left: 91.66667% !important; }
  .g-span12--wide {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--wide.g-holdWidth--wide {
      min-width: 1400px; }
      .g--gutter--scaled .g-span12--wide.g-holdWidth--wide {
        min-width: 1380px; }
    .g--gutter--scaled .g-span12--wide {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--wide {
      width: 100% !important; } }

@media (min-width: 1250px) {
  .g-span1--huge {
    flex-basis: 8.33333% !important;
    flex-grow: 0;
    max-width: 8.33333% !important; }
    .g-span1--huge.g-holdWidth--huge {
      min-width: 116.66667px; }
      .g--gutter--scaled .g-span1--huge.g-holdWidth--huge {
        min-width: 96.66667px; }
    .g--gutter--scaled .g-span1--huge {
      flex-basis: 7.02381% !important;
      max-width: 7.02381%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span1--huge {
      width: 8.33333% !important; }
  .g-offset1--huge {
    margin-left: 8.33333% !important; }
  .g-span2--huge {
    flex-basis: 16.66667% !important;
    flex-grow: 0;
    max-width: 16.66667% !important; }
    .g-span2--huge.g-holdWidth--huge {
      min-width: 233.33333px; }
      .g--gutter--scaled .g-span2--huge.g-holdWidth--huge {
        min-width: 213.33333px; }
    .g--gutter--scaled .g-span2--huge {
      flex-basis: 15.47619% !important;
      max-width: 15.47619%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span2--huge {
      width: 16.66667% !important; }
  .g-offset2--huge {
    margin-left: 16.66667% !important; }
  .g-span3--huge {
    flex-basis: 25% !important;
    flex-grow: 0;
    max-width: 25% !important; }
    .g-span3--huge.g-holdWidth--huge {
      min-width: 350px; }
      .g--gutter--scaled .g-span3--huge.g-holdWidth--huge {
        min-width: 330px; }
    .g--gutter--scaled .g-span3--huge {
      flex-basis: 23.92857% !important;
      max-width: 23.92857%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span3--huge {
      width: 25% !important; }
  .g-offset3--huge {
    margin-left: 25% !important; }
  .g-span4--huge {
    flex-basis: 33.33333% !important;
    flex-grow: 0;
    max-width: 33.33333% !important; }
    .g-span4--huge.g-holdWidth--huge {
      min-width: 466.66667px; }
      .g--gutter--scaled .g-span4--huge.g-holdWidth--huge {
        min-width: 446.66667px; }
    .g--gutter--scaled .g-span4--huge {
      flex-basis: 32.38095% !important;
      max-width: 32.38095%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span4--huge {
      width: 33.33333% !important; }
  .g-offset4--huge {
    margin-left: 33.33333% !important; }
  .g-span5--huge {
    flex-basis: 41.66667% !important;
    flex-grow: 0;
    max-width: 41.66667% !important; }
    .g-span5--huge.g-holdWidth--huge {
      min-width: 583.33333px; }
      .g--gutter--scaled .g-span5--huge.g-holdWidth--huge {
        min-width: 563.33333px; }
    .g--gutter--scaled .g-span5--huge {
      flex-basis: 40.83333% !important;
      max-width: 40.83333%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span5--huge {
      width: 41.66667% !important; }
  .g-offset5--huge {
    margin-left: 41.66667% !important; }
  .g-span6--huge {
    flex-basis: 50% !important;
    flex-grow: 0;
    max-width: 50% !important; }
    .g-span6--huge.g-holdWidth--huge {
      min-width: 700px; }
      .g--gutter--scaled .g-span6--huge.g-holdWidth--huge {
        min-width: 680px; }
    .g--gutter--scaled .g-span6--huge {
      flex-basis: 49.28571% !important;
      max-width: 49.28571%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span6--huge {
      width: 50% !important; }
  .g-offset6--huge {
    margin-left: 50% !important; }
  .g-span7--huge {
    flex-basis: 58.33333% !important;
    flex-grow: 0;
    max-width: 58.33333% !important; }
    .g-span7--huge.g-holdWidth--huge {
      min-width: 816.66667px; }
      .g--gutter--scaled .g-span7--huge.g-holdWidth--huge {
        min-width: 796.66667px; }
    .g--gutter--scaled .g-span7--huge {
      flex-basis: 57.7381% !important;
      max-width: 57.7381%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span7--huge {
      width: 58.33333% !important; }
  .g-offset7--huge {
    margin-left: 58.33333% !important; }
  .g-span8--huge {
    flex-basis: 66.66667% !important;
    flex-grow: 0;
    max-width: 66.66667% !important; }
    .g-span8--huge.g-holdWidth--huge {
      min-width: 933.33333px; }
      .g--gutter--scaled .g-span8--huge.g-holdWidth--huge {
        min-width: 913.33333px; }
    .g--gutter--scaled .g-span8--huge {
      flex-basis: 66.19048% !important;
      max-width: 66.19048%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span8--huge {
      width: 66.66667% !important; }
  .g-offset8--huge {
    margin-left: 66.66667% !important; }
  .g-span9--huge {
    flex-basis: 75% !important;
    flex-grow: 0;
    max-width: 75% !important; }
    .g-span9--huge.g-holdWidth--huge {
      min-width: 1050px; }
      .g--gutter--scaled .g-span9--huge.g-holdWidth--huge {
        min-width: 1030px; }
    .g--gutter--scaled .g-span9--huge {
      flex-basis: 74.64286% !important;
      max-width: 74.64286%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span9--huge {
      width: 75% !important; }
  .g-offset9--huge {
    margin-left: 75% !important; }
  .g-span10--huge {
    flex-basis: 83.33333% !important;
    flex-grow: 0;
    max-width: 83.33333% !important; }
    .g-span10--huge.g-holdWidth--huge {
      min-width: 1166.66667px; }
      .g--gutter--scaled .g-span10--huge.g-holdWidth--huge {
        min-width: 1146.66667px; }
    .g--gutter--scaled .g-span10--huge {
      flex-basis: 83.09524% !important;
      max-width: 83.09524%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span10--huge {
      width: 83.33333% !important; }
  .g-offset10--huge {
    margin-left: 83.33333% !important; }
  .g-span11--huge {
    flex-basis: 91.66667% !important;
    flex-grow: 0;
    max-width: 91.66667% !important; }
    .g-span11--huge.g-holdWidth--huge {
      min-width: 1283.33333px; }
      .g--gutter--scaled .g-span11--huge.g-holdWidth--huge {
        min-width: 1263.33333px; }
    .g--gutter--scaled .g-span11--huge {
      flex-basis: 91.54762% !important;
      max-width: 91.54762%;
      margin-left: 1.42857%; }
    .no-flexbox .g-span11--huge {
      width: 91.66667% !important; }
  .g-offset11--huge {
    margin-left: 91.66667% !important; }
  .g-span12--huge {
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important; }
    .g-span12--huge.g-holdWidth--huge {
      min-width: 1400px; }
      .g--gutter--scaled .g-span12--huge.g-holdWidth--huge {
        min-width: 1380px; }
    .g--gutter--scaled .g-span12--huge {
      flex-basis: 100% !important;
      max-width: 100%;
      margin-left: 0; }
    .no-flexbox .g-span12--huge {
      width: 100% !important; } }

@media (max-width: 400px) {
  .g--stack--narrow {
    flex-flow: column nowrap; }
    .g--stack--narrow > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--narrow > .g-col {
        width: 100% !important; } }

@media (max-width: 949px) {
  .g--stack--mid {
    flex-flow: column nowrap; }
    .g--stack--mid > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--mid > .g-col {
        width: 100% !important; } }

@media (max-width: 999px) {
  .g--stack--wide {
    flex-flow: column nowrap; }
    .g--stack--wide > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--wide > .g-col {
        width: 100% !important; } }

@media (max-width: 1249px) {
  .g--stack--huge {
    flex-flow: column nowrap; }
    .g--stack--huge > .g-col {
      flex-basis: auto !important;
      max-width: 100% !important;
      margin-left: 0; }
      .no-flexbox .g--stack--huge > .g-col {
        width: 100% !important; } }

/**
 * Forms
 * =================================
 * Kickoff's forms has support for themes. See below for info about them.
 *
 * The styles here are base styles that apply to both themes,
 * they are more of a reset before you make stylistic amends to form fields
 *
 */
/**
 * Form themes
 * This file contains the base form styles and each theme file contains
 * overrides and additions.
 *
 * Choose from:
 * - `standard`: the original Kickoff form theme
 * - `material`: basic version of Google's Material design forms
 *
 * Usage: (choose from)
 * @import "form-theme-standard";
 * @import "form-theme-material";
 */
/**
 * Kickoff forms: Standard theme
 * ================================= *
 * Demo: http://trykickoff.com/demos/forms.html#layout-example-labels-above
 * Code: https://gist.github.com/mrmartineau/6712577#file-labels-above-inputs-html
 * Usage:
  	<div class="form-controlGroup">
  		<label for="name" class="form-label">Name</label>
  		<div class="form-controlGroup-inputWrapper">
  			<input type="text" id="name" placeholder="Zander Martineau" class="form-input"/>
  		</div>
  	</div>
 *
 * Contents:
 * - Theme variables
 * - Theme styles
 */
/**
 * Generic form styles
 * =================================
 * Demo: http://trykickoff.com/demos/forms.html#layout-example-labels-above
 * Code: https://gist.github.com/mrmartineau/6712577#file-labels-above-inputs-html
 * Usage:
  	<div class="form-controlGroup">
  		<label for="name" class="form-label">Name</label>
  		<div class="form-controlGroup-inputWrapper">
  			<input type="text" id="name" placeholder="Zander Martineau" class="form-input"/>
  		</div>
  	</div>
 */
.form {
  margin: 0 0 30px; }

.form-fieldset {
  padding: 0;
  margin: 0;
  border: 0; }

.form-legend {
  padding: 0;
  margin-bottom: 30px;
  font-size: 1.25rem;
  line-height: 1.6;
  color: #aaaaaa;
  border: 0;
  border-bottom: 1px solid #eee;
  white-space: normal; }
  .form-legend small {
    font-size: 0.8rem;
    color: #b3b3b3; }

/**
 * Form inputs and groups
 */
.form-controlGroup {
  margin-bottom: 21px; }

.form-legend + .form-controlGroup {
  margin-top: 30px; }

.form-label {
  color: #aaaaaa; }
  .form-label small {
    font-weight: normal;
    color: #f7f7f7; }

textarea {
  line-height: 1.6 !important; }

.form-input {
  height: 3.5em;
  padding: 1em;
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  line-height: 1;
  color: #aaaaaa;
  font-weight: normal;
  background-color: #fff;
  border: 1px solid #bababa;
  border-radius: 2px;
  background-clip: padding-box;
  transition: border 200ms ease-in-out; }
  .form-input:focus {
    border-color: #888888; }
  .form-input[type="image"], .form-input[type="checkbox"], .form-input[type="radio"], .form-input[type="range"] {
    margin: 3px 0; }
  .form-input[type="file"] {
    background-color: #fff; }
  .form-input[type="color"] {
    width: 40px;
    height: 40px;
    overflow: hidden;
    padding: 2px; }
  .form-input[disabled], .form-input[readonly] {
    cursor: not-allowed; }

.form-input--select {
  background-color: #fff; }

.form-input-file {
  font-size: 1rem; }

/**
 * Form actions
 */
.form-actions {
  padding: 30px 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd; }
  .form-actions .btn {
    margin-right: 10px; }
    .form-actions .btn:last-child {
      margin-right: 0; }

/**
 * Form validation messages
 */
.form-controlGroup .form-message {
  padding-top: 0.5em;
  padding-left: 0.5em; }

.form-actions .form-message {
  padding: 0.5em; }

/**
 * Form field feedback states
 */
.has-warning {
  color: #f4aa47; }

.form-controlGroup.has-warning .form-label {
  color: #f4aa47; }

.form-controlGroup.has-warning .form-input {
  color: #f4aa47;
  border-color: #f4aa47; }
  .form-controlGroup.has-warning .form-input:focus {
    border-color: #f19417; }

.form-controlGroup.has-warning .form-message {
  display: block;
  color: #f4aa47; }

.has-error {
  color: #B94A4D; }

.form-controlGroup.has-error .form-label {
  color: #B94A4D; }

.form-controlGroup.has-error .form-input {
  color: #B94A4D;
  border-color: #B94A4D; }
  .form-controlGroup.has-error .form-input:focus {
    border-color: #963a3c; }

.form-controlGroup.has-error .form-message {
  display: block;
  color: #B94A4D; }

.has-success {
  color: #16c98d; }

.form-controlGroup.has-success .form-label {
  color: #16c98d; }

.form-controlGroup.has-success .form-input {
  color: #16c98d;
  border-color: #16c98d; }
  .form-controlGroup.has-success .form-input:focus {
    border-color: #119b6d; }

.form-controlGroup.has-success .form-message {
  display: block;
  color: #16c98d; }

.has-info {
  color: #288ad6; }

.form-controlGroup.has-info .form-label {
  color: #288ad6; }

.form-controlGroup.has-info .form-input {
  color: #288ad6;
  border-color: #288ad6; }
  .form-controlGroup.has-info .form-input:focus {
    border-color: #206eab; }

.form-controlGroup.has-info .form-message {
  display: block;
  color: #288ad6; }

/**
 * Placeholder text
 * =================================
 * Gets special styles; can't be bundled together though for some reason
 */
:-ms-input-placeholder {
  color: #b3b3b3; }

:-moz-placeholder {
  color: #b3b3b3; }

::-webkit-input-placeholder {
  color: #b3b3b3; }

@media (min-width: 951px) {
  /**
	 * Horizontal-specific styles
	 * =================================
	 * add .form--horizontal to the <form> element
	 * Demo: http://trykickoff.com/demos/forms.html#layout-example-labels-inline
	 * Code: https://gist.github.com/mrmartineau/6712577#file-labels-inline-html
	 */
  .form--horizontal .form-controlGroup {
    display: flex; }
    .form--horizontal .form-controlGroup > .form-controlGroup-inputWrapper {
      width: 100%; }
  .form--horizontal .form-label {
    width: 140px;
    margin-right: 30px;
    padding-top: 0.6em; }
  .form--horizontal .form-actions {
    padding-left: 170px; } }

/**
 * Custom controls
 * - Radios/checkboxes
 * - Select element
 * - File upload
 */
/**
 * Custom Checkboxes and radios
 * =================================
 * IE9+
 *
 * --------------------------------
 *
 * Checkbox button example HTML
 *
 *	<label class="control control--custom" for="checkbox1">
		<input class="control--custom-input" type="checkbox" id="checkbox1" name="myCheckbox" value="A value"/>
			<span class="control-indicator control-indicator--checkbox"></span>
			My Checkbox Label Text
		</label>
 *
 * -------------------------------
 *
 * Radio button example HTML
 *
 *	<label class="control control--custom" for="radio1">
 *		<input class="control--custom-input" type="radio" id="radio1" name="myRadioBtn" value="A value"/>
 *		<span class="control-indicator control-indicator--radio"></span>
 *		My Radio Button Label Text
 * 	</label>
 *
 * ================================= */
.control--custom {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
  padding-left: 2.3em;
  height: 1.3em;
  line-height: 1.3em !important; }

.control--custom--inline {
  display: inline-block;
  padding-right: 2em; }

.control--custom-input {
  position: absolute;
  opacity: 0;
  z-index: -1; }

.control-indicator {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 1.3em;
  height: 1.3em;
  transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1);
  border: 1px solid #ccc;
  background-color: #ccc;
  vertical-align: middle;
  margin-top: 0;
  box-shadow: 0 0 0 2px transparent, 0 0 0 0 transparent; }

.control--custom-input:focus ~ .control-indicator {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 3px #181830; }

.control-indicator--checkbox,
.control-indicator--tickbox {
  border-radius: .2em; }
  .control-indicator--checkbox:before, .control-indicator--checkbox:after,
  .control-indicator--tickbox:before,
  .control-indicator--tickbox:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    display: block;
    opacity: 0;
    transition: all 250ms ease-in-out; }

.control-indicator--checkbox:before, .control-indicator--checkbox:after {
  top: 50%;
  width: 90%;
  height: 0.1em;
  background-color: #ffffff; }

.control-indicator--checkbox:before {
  transform: translate(-50%, -50%) rotate(45deg) scale(0); }

.control-indicator--checkbox:after {
  transform: translate(-50%, -50%) rotate(-45deg) scale(0); }

.control-indicator--tickbox:before {
  top: 0;
  transform: translateX(-50%) rotate(45deg) scale(0);
  border: 0.1em solid #ffffff;
  background-color: transparent;
  width: 40%;
  height: 80%;
  border-top: 0;
  border-left: 0; }

.control-indicator--tickbox:after {
  display: none; }

.control--custom-input:checked ~ .control-indicator--checkbox,
.control--custom-input:checked ~ .control-indicator--tickbox {
  background-color: #181830;
  border: 0.1em solid #181830; }
  .control--custom-input:checked ~ .control-indicator--checkbox:before, .control--custom-input:checked ~ .control-indicator--checkbox:after,
  .control--custom-input:checked ~ .control-indicator--tickbox:before,
  .control--custom-input:checked ~ .control-indicator--tickbox:after {
    opacity: 1; }

.control--custom-input:checked ~ .control-indicator--tickbox:before, .control--custom-input:checked ~ .control-indicator--tickbox:after {
  transform: translateX(-50%) rotate(45deg) scale(1); }

.control--custom-input:checked ~ .control-indicator--checkbox:before {
  transform: translate(-50%, -50%) rotate(45deg) scale(1); }

.control--custom-input:checked ~ .control-indicator--checkbox:after {
  transform: translate(-50%, -50%) rotate(-45deg) scale(1); }

.control-indicator--radio {
  border-radius: 50%; }

.control--custom-input:checked ~ .control-indicator--radio {
  background-color: #ffffff;
  border: 0.3em solid #181830; }

/**
 * Custom select element
 * =================================
 * Firefox 39+, IE10+
 *
 *	<div class="form-controlGroup">
			<label for="cs-choice" class="form-label">Custom select</label>
			<div class="form-controlGroup-inputWrapper form-controlGroup-inputWrapper--select">
				<select id="cs-choice" name="cs-choice" class="form-input form-input--select">
					<option value="--">Select title</option>
					<option value="Mr">Mr</option>
					<option value="Mrs">Mrs</option>
				</select>
			</div>
	</div>
 */
.form-controlGroup-inputWrapper--select {
  position: relative;
  display: block;
  padding: 0;
  /* Custom arrow sits on top of the select - could be an image, SVG, icon font, etc. or the arrow could just baked into the bg image on the select */ }
  .form-controlGroup-inputWrapper--select:after {
    background-repeat: no-repeat;
    background-image: url("../img/down-arrow.svg");
    color: #181830;
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    padding: 0;
    position: absolute;
    right: 1.2em;
    top: 1.2em;
    z-index: 2;
    /* These hacks make the select behind the arrow clickable in some browsers */
    -ms-pointer-events: none;
    pointer-events: none; }
    @media (max-width: 949px) {
      .form-controlGroup-inputWrapper--select:after {
        top: 1.1em; } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .form-controlGroup-inputWrapper--select:after {
      display: none; } }
  .form-controlGroup-inputWrapper--select .form-input--select {
    appearance: none; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .form-controlGroup-inputWrapper--select .form-input--select:focus::-ms-value {
        background: transparent;
        color: #222; } }

.form--white .form-controlGroup-inputWrapper--select {
  position: relative;
  display: block;
  padding: 0;
  /* Custom arrow sits on top of the select - could be an image, SVG, icon font, etc. or the arrow could just baked into the bg image on the select */ }
  .form--white .form-controlGroup-inputWrapper--select:after {
    background-repeat: no-repeat;
    background-image: url("../img/down-arrow-white.svg");
    color: #181830;
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    padding: 0;
    position: absolute;
    right: 1.2em;
    top: 1.2em;
    z-index: 2;
    /* These hacks make the select behind the arrow clickable in some browsers */
    -ms-pointer-events: none;
    pointer-events: none; }
    @media (max-width: 949px) {
      .form--white .form-controlGroup-inputWrapper--select:after {
        top: 1.1em; } }

option {
  font-weight: normal; }

/**
 * Custom file input
 * =================================
 * <div class="form-controlGroup">
		<label class="form-label" for="file">File upload</label>

		<div class="form-controlGroup-inputWrapper">
			<label class="form-input form-input--file">
				<span class="form-input--file-text">Choose file..</span>
				<span class="form-input--file-button">Browse</span>
				<input class="form-input--file-input" type="file" id="file" accept="image/*" size="14" />
			</label>
		</div>
	</div>
 */
/**
 * Custom file input
 */
.form-input--file {
  position: relative;
  overflow: hidden;
  padding-right: 120px;
  text-overflow: ellipsis;
  white-space: nowrap; }

.form-input--file-input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0; }

.form-input--file-button {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  padding: .44em 1em 0;
  background-color: #d9d9d9; }
  .form-input--file-button:hover {
    background-color: #ccc; }

/**
 * Base form styles
 * =================================
 * Demo: http://trykickoff.com/demos/forms.html#layout-example-labels-above
 * Code: https://gist.github.com/mrmartineau/6712577#file-labels-above-inputs-html
 * Usage:
  	<div class="form-controlGroup">
  		<label for="name" class="form-label">Name</label>
  		<div class="form-controlGroup-inputWrapper">
  			<input type="text" id="name" placeholder="Zander Martineau" class="form-input"/>
  		</div>
  	</div>
 */
.form-legend {
  display: block;
  width: 100%; }

/**
 * Form inputs and groups
 */
.form-legend + .form-controlGroup {
  -webkit-margin-top-collapse: separate; }

.form-label {
  display: block; }

.form-input {
  display: block;
  width: 100%;
  vertical-align: baseline; }
  .form-input:focus {
    outline: 0;
    outline: thin dotted \9; }
  .form-input[type="image"], .form-input[type="checkbox"], .form-input[type="radio"], .form-input[type="range"] {
    width: auto;
    height: auto;
    padding: 0;
    line-height: normal;
    cursor: pointer;
    border-radius: 0;
    box-sizing: border-box; }
  .form-input[type="image"] {
    border: 0; }
  .form-input[type="file"] {
    width: auto;
    height: 28px;
    padding: initial;
    line-height: initial;
    border: initial;
    background-color: initial; }
  .form-input[type="hidden"] {
    display: none; }
  .form-input[type="password"] {
    font-family: sans-serif; }
  .form-input[type="range"] {
    width: 100%; }
  .form-input > .radio:first-child,
  .form-input > .checkbox:first-child {
    padding-top: 5px; }
  .form-input[disabled], .form-input[readonly] {
    cursor: not-allowed; }

.form-input--textarea {
  height: auto;
  min-height: 5em;
  resize: vertical; }

.form-input--textarea--uneditable {
  width: auto;
  height: auto; }

.form-input-file {
  display: inline-block;
  width: 100%;
  margin: 0;
  vertical-align: middle;
  line-height: 1em; }
  .form-input-file:focus {
    outline: none; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  width: auto;
  cursor: pointer;
  -webkit-appearance: button; }

/**
 * Checkboxes:
 * =================================
	<label class="control">
		<input type="checkbox" value="check1">
		This is a checkbox
	</label>
	<label class="control control--inline">
		<input type="checkbox" value="check1">
		This is a checkbox
	</label>

 * Radios:
 * =================================
	<label class="control">
		<input type="radio" name="optionsRadios" id="optionsRadios1" value="option1" checked>
		This is a radio
	</label>
	<label class="control control--inline">
		<input type="radio" name="optionsRadios" id="optionsRadios1" value="option2" checked>
		This is a radio
	</label>
 */
.control {
  display: block;
  line-height: 1; }
  .control + .control {
    margin-top: 15px; }
  .control input {
    vertical-align: top;
    display: inline;
    line-height: 1;
    margin-right: 0.5em; }

.control--inline {
  display: inline-block;
  vertical-align: middle; }
  .control--inline + .control--inline {
    margin-top: 0;
    margin-left: 1.5em; }

/**
 * Form validation messages
 */
.form-controlGroup .form-message {
  display: none; }

/**
 * Links
 * =================================
 */
a, a:link {
  color: #FFA328;
  text-decoration: none; }

a:visited {
  color: #FFA328;
  text-decoration: none; }

a:hover, a:focus {
  color: #c16e00; }

a:active {
  color: #c16e00; }

/**
 * Buttons
 * =================================
 * - Associated Button Variables
 * - Base button styling
 * – Button Modifiers
 * 	 – btn--primary
 *   – btn--block
 */
/**
 * Define associated Button variables
 */
/**
 * Base button styles – Based on csswizardry.com/beautons
 *
 * 1. Allow us to better style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Stop buttons wrapping and looking broken.
 * 4. Make buttons inherit font styles.
 * 5. Force all elements using beautons to appear clickable.
 * 6. Normalise box model styles.
 * 7. If the button’s text is 1em, and the button is (3 * font-size) tall, then
 *    there is 1em of space above and below that text. We therefore apply 1em
 *    of space to the left and right, as padding, to keep consistent spacing.
 * 8. Fixes odd inner spacing in IE7.
 * 9. Don’t allow buttons to have underlines; it kinda ruins the illusion.
 *10. Prevents from inheriting default anchor styles.
 */
.btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  white-space: normal;
  /* [3] */
  font-family: inherit;
  /* [4] */
  font-size: 100%;
  /* [4] */
  cursor: pointer;
  /* [5] */
  border: none;
  /* [6] */
  margin: 0;
  /* [6] */
  padding-top: 0;
  /* [6] */
  padding-bottom: 0;
  /* [6] */
  line-height: 1.5;
  /* [7] */
  padding-right: 30px;
  /* [7] */
  padding-left: 30px;
  /* [7] */
  padding-top: 10px;
  /* [7] */
  padding-bottom: 10px;
  /* [7] */
  overflow: visible;
  /* [8] */
  text-align: center;
  border-radius: 0px;
  user-select: none;
  background-color: #fafafa; }
  .btn, .btn:link, .btn:visited {
    color: #fff; }
  .btn:hover, .btn:active, .btn:focus {
    background-color: #e1e1e1; }
  .btn, .btn:hover, .btn:active, .btn:focus, .btn:visited {
    text-decoration: none; }
  .btn:active, .btn:focus {
    outline: none; }
  .btn.disabled, .btn[disabled] {
    cursor: default;
    background-image: none;
    opacity: .5; }

.btn--primary {
  border: 1px solid #FFA328;
  background-color: #FFA328; }
  .btn--primary, .btn--primary:link, .btn--primary:visited {
    color: #fff; }
  .btn--primary:hover, .btn--primary:active, .btn--primary:focus {
    background-color: #f48c00; }

.btn--secondary {
  background-color: #F76E83;
  border: 1px solid #F76E83; }
  .btn--secondary, .btn--secondary:link, .btn--secondary:visited {
    color: #fff; }
  .btn--secondary:hover, .btn--secondary:active, .btn--secondary:focus {
    background-color: #f43e5a; }

.btn--green {
  background-color: #3ADBB2;
  border: 1px solid #3ADBB2; }
  .btn--green, .btn--green:link, .btn--green:visited {
    color: #fff; }
  .btn--green:hover, .btn--green:active, .btn--green:focus {
    background-color: #23bf97; }

.btn--outline {
  background-color: transparent;
  border: 1px solid #FFA328; }
  .btn--outline, .btn--outline:link, .btn--outline:visited {
    color: #FFA328; }
  .btn--outline:hover, .btn--outline:active, .btn--outline:focus {
    background-color: rgba(255, 163, 40, 0.1); }

.btn--block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0; }
  .btn--block + .btn--block {
    margin-top: 10px; }

input[type="submit"].btn--block,
input[type="reset"].btn--block,
input[type="button"].btn--block {
  width: 100%; }

/**
 * Lists
 * =================================
 * - (Un)ordered lists
 * - Unstyled lists
 * - Definition lists
 * - Centred lists
 * - Floated lists
 */
ul {
  list-style-type: disc; }

ol {
  list-style-type: decimal; }

ul, ol {
  padding: 0;
  margin: 0 0 20px 30px; }
  ul ul,
  ul ol, ol ul,
  ol ol {
    margin-left: 60px; }
  ul ul, ol ul {
    list-style-type: circle; }
    ul ul ul, ol ul ul {
      list-style-type: square; }
  ul ol, ol ol {
    list-style-type: upper-roman; }
    ul ol ol, ol ol ol {
      list-style-type: lower-roman; }
  ul ul, ul ol, ol ul, ol ol {
    margin-bottom: 0; }

/**
 * Unstyled lists
 */
.unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
  list-style-image: none; }

/**
 * Definition lists
 */
dl {
  margin-bottom: 30px; }

dt {
  font-weight: normal; }

dd {
  margin-left: 30px; }

/**
 * Centred lists
 *
 * Usage:
	<ul class="l-list--centred">
		<li></li>
	</ul>
 */
.l-list--centred, .l-list--centered {
  text-align: center; }
  .l-list--centred li, .l-list--centered li {
    display: inline; }

.l-list--left li {
  display: inline; }

/**
 * Floated lists
 * Usage:
	<ul class="l-list--floated">
		<li></li>
	</ul>
 */
.l-list--floated:after {
  content: "";
  display: table;
  clear: both; }

.l-list--floated li {
  float: left;
  display: inline-block; }

.l-list--floatedReversed:after {
  content: "";
  display: table;
  clear: both; }

.l-list--floatedReversed li {
  float: right;
  display: inline-block; }

/**
 * Embedded content
 * =================================
 */
img {
  font-style: italic;
  color: #c00;
  border: 0;
  -ms-interpolation-mode: bicubic; }

img,
object,
embed,
video {
  max-width: 100%; }

/**
 * Icons
 * =================================
 * For an example of how to use these, see
 * https://gist.github.com/mrmartineau/314b01b300fa20a5a820a4d6889ffd42
 * http://codepen.io/mrmartineau/pen/amkZBb?editors=1100
 *
 * SVG icon usage:
   <svg title="Whatsapp" class="icon icon--whatsapp">
     <use xlink:href="/assets/dist/img/icons.svg#logo-whatsapp"/>
   </svg>
 */
[class*="icon"] {
  speak: none; }

.icon--large {
  display: block;
  width: 120px;
  height: 120px; }

.icon--small {
  display: inline-block;
  width: 25px;
  height: 25px; }

/**
 * Included Social Media and other icons
 */
.icon--twitter {
  color: #55acee; }

.icon--facebook {
  color: #3b5998; }

.icon--instagram {
  color: #e4405f; }

.icon--pinterest {
  color: #bd081c; }

.icon--youtube {
  color: #cd201f; }

.icon--whatsapp {
  color: #25d366; }

.icon--email {
  color: #288ad6; }

.icon--kickoff {
  fill: #181830;
  color: #fe7c08; }

.icon--tmw {
  color: #ef7521;
  fill: #8a8b8c; }

/**
 * Skip to main content link
 * =================================
 * <div class="skipToContent">
	 	<a href="#mainContent" class="btn btn--primary">Skip to main content</a>
	 </div>
 */
.skipToContent {
  position: relative;
  text-align: center;
  height: 0; }
  .skipToContent a {
    position: relative;
    top: -100px;
    z-index: 100;
    background-color: #fff;
    transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1); }
    .skipToContent a:focus {
      top: 5px; }

/**
 * Preformatted code
 * =================================
 * Code typography styles for the <code> and <pre> elements
 */
/**
 * Define associated Code variables
 */
code,
pre {
  padding: 0 3px 2px;
  font-family: "Lato", sans-serif;
  font-size: 0.8rem;
  color: #444;
  border-radius: 3px;
  tab-size: 3; }

code {
  padding: 2px 4px;
  color: #444;
  background-color: #ddd; }

pre {
  display: block;
  padding: 15px;
  margin: 0 0 30px;
  color: #444;
  background-color: #ddd;
  border-radius: 4px;
  white-space: pre; }
  pre code {
    padding: 0;
    color: #444;
    background-color: #ddd;
    border: 0; }

.pre--scrollable {
  max-height: 340px;
  overflow-y: scroll; }

/**
 * Tables
 * =================================
 * - Associated Table Variables
 * - Default table Styles
 * – Table modifiers:
 *   - .table--striped
 * – .responsiveTable
 */
/**
 * Define associated Table variables
 */
/**
 * Customizes the `.table` component with basic values, each used across all table variations.
 */
table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 30px;
  border-spacing: 0;
  border: 0px solid #ccc;
  table-layout: fixed; }
  table th,
  table td {
    padding: 8px;
    line-height: 30px;
    vertical-align: top;
    border: 0px solid #ccc;
    padding: 10px; }
    table th:first-child,
    table td:first-child {
      border-left: 0; }
    table th:last-child,
    table td:last-child {
      border-right: 0; }
  table > thead th,
  table > thead td {
    vertical-align: bottom;
    border-bottom: 0px solid #ccc; }
  table > tfoot td {
    border-top: 0px solid #ccc;
    border-bottom: 0; }
  table > thead:first-child > tr:first-child > th,
  table > thead:first-child > tr:first-child > td {
    border-top: 0; }
  table > tbody + tbody {
    border-top: 0px solid #ccc; }
  table table,
  table .table {
    background-color: #ffffff; }

th {
  text-align: left; }

table td p:last-child {
  margin-bottom: 0px; }

.pricing-table td {
  padding: 8px !important; }

.pricing-table .price-cell--bg span {
  white-space: nowrap; }

.pricing-table .price-cell {
  width: 110px;
  text-align: right; }

/**
 * Zebra-striping
 * Default zebra-stripe styles (alternating gray and transparent backgrounds)
 */
.table--striped tbody :nth-child(odd) th,
.table--striped tbody :nth-child(odd) td {
  background-color: #f9f9f9 !important; }

/**
 * Responsive tables
 * Wrap your tables in `.responsiveTable` and we'll make them mobile friendly by enabling horizontal scrolling. Only applies <768px. Everything above that will display normally.
 *
 * If you need more responsive tables, consider Tablesaw by the Filament Group:
 * https://github.com/filamentgroup/tablesaw
 */
.responsiveTable {
  /*@include media('<mid') {
		width: 100%;
		margin-bottom: ($line-height-base * .75) + px;
		overflow-y: hidden;
		overflow-x: auto;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid $table-borderColor;
		-webkit-overflow-scrolling: touch;

		// Tighten up spacing
		> table {
			margin-bottom: 0;
			border: 0;
			min-width:768px;

			// Ensure the content doesn't wrap
			> thead,
			> tbody,
			> tfoot {
				th,
				td {
					//white-space: nowrap;

					// Nuke the appropriate borders so that the parent can handle them
					&:first-child {	border-left: 0; }
					&:last-child {	border-right: 0; }
				}
			}

			// Only nuke the last row's bottom-border in `tbody` and `tfoot` since
			// chances are there will be only one `tr` in a `thead` and that would
			// remove the border altogether.
			> tbody,
			> tfoot tr:last-child {
				> th,
				> td {
					border-bottom: 0;
				}
			}
		}
	}*/ }
  @media only screen and (max-width: 949px) {
    .responsiveTable table {
      width: 100% !important;
      max-width: 100% !important; } }
  .responsiveTable thead {
    display: none; }
    @media only screen and (min-width: 950px) {
      .responsiveTable thead {
        display: table-header-group; }
        .responsiveTable thead th {
          padding: 10px 10px 10px 0;
          border-bottom: 1px solid #e4e4e4; } }
  .responsiveTable tbody tr {
    display: block; }
    @media only screen and (max-width: 949px) {
      .responsiveTable tbody tr {
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 15px;
        margin-bottom: 15px; } }
    @media only screen and (min-width: 950px) {
      .responsiveTable tbody tr {
        display: table-row; } }
  .responsiveTable td {
    display: block;
    padding: 5px 10px 5px 0; }
    .responsiveTable td:before {
      content: attr(data-label);
      display: block;
      font-weight: bold; }
    @media only screen and (max-width: 949px) {
      .responsiveTable td {
        width: 100% !important;
        max-width: 100% !important; } }
    @media only screen and (min-width: 950px) {
      .responsiveTable td {
        display: table-cell;
        padding: 10px 10px 10px 0; }
        .responsiveTable td:before {
          display: none; } }
    .responsiveTable td ul {
      margin-bottom: 0; }

.pricing-table tr {
  background: url(../img/line.png) !important;
  background-image: url(../img/line.png) !important;
  background-repeat: no-repeat !important;
  background-position: 0px 23px !important; }

/**
 * Slideout.js mobile menu styles
 * =================================
 * https://github.com/mango/slideout
 */
.site__slideout {
  display: none;
  background: #FFFFFF; }

.pink-theme .slideout__logo > g {
  fill: #f76e83 !important; }

.site__wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }

body {
  width: 100%;
  height: 100%; }

.slideout-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 256px;
  min-height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 0;
  display: none; }

.slideout-menu-left {
  left: 0; }

.slideout-menu-right {
  right: 0; }

.slideout-panel {
  position: relative;
  z-index: 1;
  background-color: #FFF;
  /* A background-color is required */
  min-height: 100vh;
  overflow: hidden; }

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
  overflow: hidden; }

.slideout-open .slideout-menu {
  display: block; }

.js-search-toggle, .js-slideout-toggle {
  transition: all .5s;
  opacity: 1; }

.slideout-open .js-slideout-toggle, .slideout-open .js-search-toggle {
  transition: all .5s;
  opacity: 0; }

.slideout__header {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  max-width: 1800px;
  width: 100%;
  z-index: 1;
  border-bottom: 1px solid #bababa;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px; }

.slideout__nav {
  padding-left: 20px;
  padding-right: 20px; }

.slideout__logo {
  height: 25px;
  color: #FFA328;
  width: 160px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: block;
  margin-top: 4px; }
  @media (max-width: 949px) {
    .slideout__logo {
      margin-top: 0; } }

.slideout__close {
  cursor: pointer;
  position: absolute;
  right: 17px;
  top: 17px; }

/**
 * Page sections
 * =================================
 */
.page-section {
  padding-top: 60px; }
  .page-section header {
    max-width: 600px;
    margin: 0pt auto;
    padding-bottom: 60px; }
    @media (max-width: 949px) {
      .page-section header {
        padding-bottom: 40px; } }
    .page-section header p {
      font-size: 24px;
      font-weight: 300;
      color: #646464;
      line-height: 1.4; }
    @media (max-width: 949px) {
      .page-section header p {
        font-size: 20px;
        font-weight: 300;
        color: #646464;
        line-height: 1.4; } }
  .page-section--shaded {
    background-color: #fafafa; }
  .page-section--hero {
    padding-top: 60px; }
    @media (max-width: 949px) {
      .page-section--hero {
        padding-top: 60px; } }
  .page-section p:last-child {
    margin-bottom: 0px; }
  .page-section-border--top {
    border-top: 1px solid #bababa; }
  .page-section--baseline {
    padding-bottom: 60px; }
  .page-section--baseline-double {
    padding-bottom: 90px; }
  .page-section--baseline-large {
    padding-bottom: 90px; }
    @media (max-width: 949px) {
      .page-section--baseline-large {
        padding-bottom: 15px !important; } }
  .page-section--double-gutters {
    padding-top: 90px !important; }
    .page-section--double-gutters .g--baseline > .g-col {
      padding-bottom: 90px !important; }
  .page-section--triple-gutters {
    padding-top: 110px !important; }
    .page-section--triple-gutters .g--baseline > .g-col {
      padding-bottom: 110px !important; }
  .page-section--gyniilearn {
    background: #F76E83; }
    .page-section--gyniilearn h1 {
      color: white !important;
      margin-bottom: 15px; }
    .page-section--gyniilearn p {
      color: white !important;
      line-height: 1.2 !important; }
    .page-section--gyniilearn header {
      max-width: 100%;
      margin: 0pt auto;
      padding-bottom: 80px; }
    .page-section--gyniilearn .l-container {
      overflow: hidden; }
  .page-section--gyniilearn-o {
    background: #FFA328; }
    .page-section--gyniilearn-o header {
      padding-bottom: 80px; }
  @media (max-width: 949px) {
    .page-section--gyniilearn, .page-section--hero {
      padding-top: 40px !important; } }
  .page-section--gyniilearn h1, .page-section--hero h1 {
    font-size: 50px !important; }
  .page-section--gyniilearn p.sub, .page-section--hero p.sub {
    font-size: 50px !important; }
  .page-section--gyniilearn p, .page-section--hero p {
    font-size: 24px !important; }
  @media (max-width: 949px) {
    .page-section--gyniilearn h1, .page-section--hero h1 {
      font-size: 40px !important; }
    .page-section--gyniilearn p.sub, .page-section--hero p.sub {
      font-size: 40px !important; }
    .page-section--gyniilearn p, .page-section--hero p {
      font-size: 22px !important; } }
  @media (max-width: 400px) {
    .page-section--gyniilearn h1, .page-section--hero h1 {
      font-size: 30px !important; }
    .page-section--gyniilearn p.sub, .page-section--hero p.sub {
      font-size: 30px !important; }
    .page-section--gyniilearn p, .page-section--hero p {
      font-size: 20px !important; } }
  .page-section--gyniilearn {
    padding-top: 40px !important; }
    .page-section--gyniilearn header {
      padding-bottom: 60px !important; }
    @media (max-width: 949px) {
      .page-section--gyniilearn {
        padding-top: 40px !important; } }
    .page-section--gyniilearn h1 {
      font-size: 34px !important; }
    .page-section--gyniilearn p.sub {
      font-size: 30px !important; }
    .page-section--gyniilearn p {
      font-size: 24px !important; }
    @media (max-width: 949px) {
      .page-section--gyniilearn h1 {
        font-size: 32px !important; }
      .page-section--gyniilearn p.sub {
        font-size: 30px !important; }
      .page-section--gyniilearn p {
        font-size: 22px !important; } }
    @media (max-width: 400px) {
      .page-section--gyniilearn h1 {
        font-size: 30px !important; }
      .page-section--gyniilearn p.sub {
        font-size: 30px !important; }
      .page-section--gyniilearn p {
        font-size: 20px !important; } }

.home-hero {
  background: #FFA328;
  padding-top: 60px;
  padding-bottom: 90px;
  text-align: center; }
  @media (max-width: 949px) {
    .home-hero {
      padding-top: 40px;
      padding-bottom: 80px; } }
  .home-hero h1, .home-hero p {
    color: white !important; }
  .home-hero h1 {
    font-size: 60px !important; }
    @media (max-width: 949px) {
      .home-hero h1 {
        font-size: 40px !important; } }
    @media (max-width: 400px) {
      .home-hero h1 {
        font-size: 30px !important; } }
  .home-hero p {
    font-size: 25px;
    line-height: 30px; }
    @media (max-width: 400px) {
      .home-hero p {
        font-size: 20px;
        line-height: 20px; } }
  .home-hero__svg svg {
    width: 100%; }
  @media (max-width: 949px) {
    .home-hero__svg--desktop {
      display: none; } }
  @media (min-width: 950px) {
    .home-hero__svg--desktop {
      display: block; } }
  @media (max-width: 949px) {
    .home-hero__svg--mobile {
      display: block;
      padding-top: 20px;
      padding-bottom: 50px;
      padding-left: 10%;
      padding-right: 10%; } }
  @media (min-width: 950px) {
    .home-hero__svg--mobile {
      display: none; } }
  .home-hero__headline {
    padding-left: 10%;
    padding-right: 15%; }
    @media (max-width: 949px) {
      .home-hero__headline {
        padding-left: 0px;
        padding-right: 0px; } }
  .home-hero__form {
    width: 100%;
    border: 2px solid white; }
    .home-hero__form .form-input, .home-hero__form select {
      background: transparent !important;
      color: white !important;
      border: 0px solid white !important;
      border-right: 2px solid white !important;
      padding-right: 50px; }
    .home-hero__form .g-col {
      padding-bottom: 0px !important; }
    .home-hero__form ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #ffffff; }
    .home-hero__form ::-moz-placeholder {
      /* Firefox 19+ */
      color: #ffffff; }
    .home-hero__form :-ms-input-placeholder {
      /* IE 10+ */
      color: #ffffff; }
    .home-hero__form :-moz-placeholder {
      /* Firefox 18- */
      color: #ffffff; }
    .home-hero__form .form-controlGroup-inputWrapper--select::after {
      color: #ffffff; }

@media (min-width: 950px) and (max-width: 1299px) {
  .home-hero__form .form-input {
    border: 0px solid white !important;
    border-bottom: 2px solid white !important; }
  .home-hero__form .home-hero__submit {
    height: 64px; }
  .home-hero__form .g-postcode {
    flex-basis: 100% !important;
    max-width: 100% !important; }
  .home-hero__form .g-treatment {
    flex-basis: 80% !important;
    max-width: 80% !important; }
    .home-hero__form .g-treatment .form-input {
      border: 0px solid white !important;
      border-right: 2px solid white !important; }
  .home-hero__form .g-submit {
    flex-basis: 20% !important;
    max-width: 20% !important; } }
    @media (max-width: 799px) {
      .home-hero__form .form-input {
        border: 0px solid white !important;
        border-bottom: 2px solid white !important; }
      .home-hero__form .home-hero__submit {
        height: 64px; }
      .home-hero__form .g-postcode {
        flex-basis: 100% !important;
        max-width: 100% !important; }
      .home-hero__form .g-treatment {
        flex-basis: 80% !important;
        max-width: 80% !important; }
        .home-hero__form .g-treatment .form-input {
          border: 0px solid white !important;
          border-right: 2px solid white !important; }
      .home-hero__form .g-submit {
        flex-basis: 20% !important;
        max-width: 20% !important; }
      .home-hero__form .home-hero__submit {
        height: 59px; } }
  .home-hero__submit {
    height: 100%;
    background-position: center center;
    background-size: 25px;
    background-repeat: no-repeat;
    background-image: url("../img/search-icon.svg");
    background-color: transparent; }

.g--gutterdouble {
  margin-left: -20px;
  margin-right: -20px; }

.g--gutterdouble > .g-col {
  padding-left: 20px;
  padding-right: 20px; }

h1 {
  font-size: 50px !important; }

h2 {
  font-size: 50px !important; }

h3 {
  font-size: 35px !important; }

@media (max-width: 949px) {
  h1 {
    font-size: 40px !important; }
  h2 {
    font-size: 40px !important; }
  h3 {
    font-size: 30px !important; } }

@media (max-width: 400px) {
  h1 {
    font-size: 35px !important; }
  h2 {
    font-size: 30px !important; }
  h3 {
    font-size: 25px !important; } }

.about-left-image {
  padding-left: 40px !important; }
  @media (max-width: 949px) {
    .about-left-image {
      padding-left: 0px !important; } }

.about-right-image {
  padding-right: 40px  !important; }
  @media (max-width: 949px) {
    .about-right-image {
      padding-left: 0px !important;
      padding-right: 0px !important; } }

/**
 * Page sections
 * =================================
 */
.full-width-hero {
  width: 100%;
  background-image: url(../img/hero-about.png);
  background-size: 130%;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 13%;
  margin-bottom: 80px; }
  @media (max-width: 949px) {
    .full-width-hero {
      background-size: 200%;
      padding-bottom: 20%; } }
  @media (min-width: 1251px) {
    .full-width-hero {
      background-size: 100%;
      padding-bottom: 10%; } }
  @media (max-width: 400px) {
    .full-width-hero {
      background-size: 250%;
      padding-bottom: 25%; } }
  @media (max-width: 949px) {
    .full-width-hero .nav-item {
      display: none; } }

.full-width-hero--gynii {
  width: 100%;
  background-image: url(../img/gyniilearnhero.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 13%;
  margin-left: 0%;
  margin-bottom: 80px;
  margin-top: -50px; }
  @media (max-width: 400px) {
    .full-width-hero--gynii {
      background-image: url(../img/gyniilearnhero--mobile.svg);
      padding-bottom: 40%; } }

@media (min-width: 951px) {
  .g-content-l {
    width: 85%; } }

.g--reverse {
  -webkit-flex-direction: row-reverse;
  /* Safari 6.1+ */
  display: flex;
  flex-direction: row-reverse; }
  @media (min-width: 951px) {
    .g--reverse .g-content-l {
      float: right;
      margin-top: 5%; } }

.g--baseline > .g-col {
  padding-bottom: 60px; }

.image-block {
  width: 100%;
  display: block; }

@media (max-width: 949px) {
  .g-col--spacer {
    display: none; } }

.content-l-c {
  width: 65%;
  margin: 0pt auto; }
  .content-l-c h4 {
    margin-bottom: 20px; }
  @media (max-width: 400px) {
    .content-l-c {
      width: 100%; } }

.team-members header {
  margin-bottom: 20px; }

.heading-flush {
  margin: 0px; }

.right-border {
  border-right: 1px solid #bababa; }

.gutter-half {
  margin-bottom: 30px; }

.gutter-bottom {
  margin-bottom: 20px; }

@media (max-width: 949px) {
  .image-block-negative {
    margin-top: -50px; } }

.Aligner {
  display: flex;
  align-items: center;
  justify-content: center; }

.Aligner-item {
  flex: 1; }

.Aligner-item--top {
  align-self: flex-start; }

.Aligner-item--bottom {
  align-self: flex-end; }

.Aligner-item--fixed {
  flex: none;
  max-width: 50%; }

.Aligner > p {
  flex: 1; }

.Media {
  display: flex;
  align-items: flex-start; }

.Media-figure {
  margin-right: 1em; }

.Media-body {
  flex: 1; }

/**
 * Team member
 * 1. Tile
 * 2. Overlay
 * 3. Full page
 * =================================
 */
.team-member-tile {
  outline: none;
  display: block;
  color: #646464 !important;
  font-size: 19px;
  transition: all .2s ease-in-out; }
  .team-member-tile:hover {
    z-index: 0;
    position: relative; }
  .team-member-tile__name {
    font-weight: 900;
    display: block;
    width: 100%;
    margin-top: 15px; }
  .team-member-tile__title {
    font-weight: 300;
    display: block;
    width: 100%;
    margin-top: 5px; }
  .team-member-tile__button {
    margin-top: 20px;
    margin-bottom: 40px; }
    @media (max-width: 540px) {
      .team-member-tile__button {
        padding-right: 10px;
        padding-left: 10px;
        font-size: 14px; } }
    @media (max-width: 949px) {
      .team-member-tile__button {
        margin-bottom: 15px; } }
  .team-member-tile__photo {
    width: 60%;
    padding-bottom: 60%;
    background-size: cover;
    display: inline-block;
    border-radius: 50%; }
    @media (max-width: 400px) {
      .team-member-tile__photo {
        width: 80%;
        padding-bottom: 80%; } }

.team-member-overlay {
  position: relative;
  background: #FFF;
  padding: 40px;
  width: auto;
  max-width: 800px;
  margin: 20px auto;
  /* Sketch Styles: */
  background: #FAFAFA;
  border: 1px solid #D8D8D8;
  box-shadow: 0 0 5px 0 #D8D8D8;
  border-radius: 2px;
  font-size: 19px; }
  @media (max-width: 400px) {
    .team-member-overlay {
      padding: 30px; } }
  .team-member-overlay__name {
    font-weight: 500;
    width: 100%; }
  .team-member-overlay__title {
    font-weight: 300;
    width: 100%; }
  .team-member-overlay__description {
    padding-top: 15px;
    font-weight: 300;
    width: 100%; }
  .team-member-overlay__photo {
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    display: inline-block;
    border-radius: 50%; }
    @media (max-width: 400px) {
      .team-member-overlay__photo {
        text-align: center;
        margin-bottom: 20px; } }
  .team-member-overlay__link {
    padding-top: 10px; }
    .team-member-overlay__link a {
      color: #646464 !important;
      text-decoration: underline;
      font-weight: 300; }

.team-bio {
  padding-left: 30px !important; }

/**
 * Nav(s)
 * Main - dropdown 
 * Mobile - slide out single level 
 * =================================
 */
.nav {
  overflow: visible;
  /**
	 * Main - dropdown 
	 */
  /**
	 * Mobile - slideout 
	 */ }
  .nav a {
    font-weight: 300; }
  .nav .nav--main {
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;
    float: right;
    /* Show dropdown menu on hover */
    /* Hide show header nav items / mobile items (burgers) */ }
    .nav .nav--main .dropdown {
      margin-left: 30px; }
    .nav .nav--main .nav__item {
      position: relative;
      margin-left: 40px;
      padding-bottom: 30px;
      margin-bottom: -30px; }
    .nav .nav--main .nav__link {
      color: #FFA328;
      font-size: 18px;
      padding-top: 10px;
      padding-bottom: 10px; }
      .nav .nav--main .nav__link:hover:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #FFA328;
        margin-bottom: -1px; }
      .nav .nav--main .nav__link.active:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #FFA328;
        margin-bottom: -1px; }
    .nav .nav--main .sub {
      position: relative;
      margin: 3em;
      padding: 1em;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4); }
    .nav .nav--main .sub::before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      top: 0px;
      left: 20px;
      box-sizing: border-box;
      background: white;
      transform-origin: 0 0;
      transform: rotate(-45deg);
      box-shadow: 0px 0px 5px 0 #D8D8D8; }
    .nav .nav--main .sub::after {
      content: "";
      position: absolute;
      width: 50px;
      height: 25px;
      top: 0px;
      background: white;
      left: 20px; }
    .nav .nav--main .sub {
      display: none;
      position: absolute;
      top: calc(100%);
      left: -20px;
      padding: 0;
      margin: 0px;
      padding: 20px;
      background: white;
      background: #FFFFFF;
      box-shadow: 0 0 5px 0 #D8D8D8;
      border-radius: 2px;
      font-size: 16px;
      width: 250px; }
      .nav .nav--main .sub__item {
        color: #a0a0a0;
        border-bottom: 1px solid #bbbbbb;
        width: 100%;
        padding-bottom: 5px;
        margin-bottom: 10px;
        padding-top: 5px; }
      .nav .nav--main .sub__link {
        color: #bbbbbb; }
      .nav .nav--main .sub__item:hover {
        border-bottom: 1px solid #FFA328; }
      .nav .nav--main .sub__item:hover .sub__link {
        color: #FFA328; }
    .nav .nav--main li:hover > ul {
      display: block; }
    @media (max-width: 949px) {
      .nav .nav--main .nav__item {
        display: none; } }
    .nav .nav--main .nav__item--submenu {
      margin-left: 0 !important; }
    @media (max-width: 949px) {
      .nav .nav--main .nav__item--mobile {
        display: inline-block;
        margin-left: 20px;
        margin-right: 0px;
        margin-top: 3px;
        margin-bottom: -33px; } }
    @media (min-width: 950px) {
      .nav .nav--main .nav__item--mobile {
        display: none; } }
  .nav .nav--mobile {
    color: #1B978E !important; }
    .nav .nav--mobile .nav__item {
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 20px;
      padding-bottom: 0px;
      display: block; }
    .nav .nav--mobile .nav__link {
      color: #646464;
      font-size: 18px;
      width: 100%;
      display: block; }
      .nav .nav--mobile .nav__link:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: transparent;
        bottom: -10px; }
    .nav .nav--mobile .nav__link:hover {
      color: #FFA328;
      font-size: 18px; }
      .nav .nav--mobile .nav__link:hover:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #FFA328;
        bottom: -10px; }

/**
 * Social strip
 * =================================
 */
#social-panel {
  padding-top: 90px !important; }

.social-strip {
  padding-bottom: 90px !important; }
  @media (max-width: 949px) {
    .social-strip .text-right,
    .social-strip .text-left {
      text-align: center !important; } }
  .social-strip--small {
    padding-bottom: 0px !important; }
    .social-strip--small .l-list--centred {
      text-align: left !important; }
  .social-strip .l-list--centred {
    margin: 0px; }
    .social-strip .l-list--centred li {
      padding-left: 10px;
      padding-right: 10px; }
    .social-strip .l-list--centred .linkedin-logo, .social-strip .l-list--centred .link--twitter, .social-strip .l-list--centred .link--facebook, .social-strip .l-list--centred .link--instagram, .social-strip .l-list--centred .link--google {
      display: inline-block; }
    .social-strip .l-list--centred .link--twitter:hover #FOOTER {
      fill: #55acee;
      transition: all .2s ease-in-out; }
    .social-strip .l-list--centred .link--facebook:hover .facebook-logo {
      transition: all .2s ease-in-out;
      fill: #3b5998; }
    .social-strip .l-list--centred .link--linkedin:hover #ABOUT-US---ROLLOVER {
      transition: all .2s ease-in-out;
      fill: #007bb5; }
    .social-strip .l-list--centred .link--instagram:hover #FOOTER {
      transition: all .2s ease-in-out;
      fill: #8a3ab9; }
    .social-strip .l-list--centred .link--google:hover #FOOTER {
      transition: all .2s ease-in-out;
      fill: #dd4b39; }

.rating__social .social-strip {
  padding-bottom: 0px !important; }

.share-header {
  padding-top: 20px; }

.learn {
  padding-top: 50px;
  padding-bottom: 30px; }
  @media (max-width: 599px) {
    .learn {
      padding-top: 30px; } }
  .learn__search {
    padding-bottom: 70px; }
  .learn__search--limited {
    padding-bottom: 30px; }
  .learn .back-link {
    padding-bottom: 50px; }
  .learn .learn__title {
    font-weight: bold; }
  .learn .learn-search__form {
    overflow: hidden; }
  .learn .learn-search__left {
    width: calc(100% - 130px);
    float: left;
    margin-right: 20px; }
  .learn .learn-search__submit {
    width: 110px;
    float: left;
    height: 66.5px; }
    @media (max-width: 949px) {
      .learn .learn-search__submit {
        height: 59px; } }
  .learn__section {
    overflow: hidden;
    margin-bottom: 70px; }
    .learn__section .l-container--full {
      display: flex; }
      @media (max-width: 949px) {
        .learn__section .l-container--full {
          display: block; } }
    .learn__section--image {
      width: 20%;
      border: 0px solid black;
      text-align: center;
      padding-right: 40px; }
      @media (max-width: 949px) {
        .learn__section--image {
          width: 100%; } }
      .learn__section--image .learn__icon {
        width: 50%;
        max-width: 100px; }
      .learn__section--image .learn__arrow {
        margin-top: 60px; }
      @media (max-width: 949px) {
        .learn__section--image .learn__icon {
          margin-bottom: 40px; }
        .learn__section--image .learn__arrow {
          display: none; } }
    .learn__section--list {
      float: left;
      width: 80%;
      border: 0px solid black;
      padding-right: 40px;
      padding-right: 0px;
      width: 100%; }
      .learn__section--list .learn__title {
        font-size: 26px !important;
        line-height: 1.4 !important;
        display: block;
        margin-bottom: 20px;
        color: #FFA328; }
      .learn__section--list > ul {
        list-style: none;
        counter-reset: mycounter;
        padding: 0; }
      .learn__section--list > ul > li > a {
        position: relative;
        margin-left: 38px;
        padding: 5px 0; }
      .learn__section--list ul > li > a:before {
        content: counter(mycounter);
        counter-increment: mycounter;
        position: absolute;
        top: 50%;
        left: -38px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        border-radius: 50%;
        color: #FFA328;
        background: whitesmoke;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        margin-top: -12.5px; }
      .learn__section--list .learn__link {
        font-size: 20px !important;
        color: #000000;
        line-height: 24px !important;
        display: inline-block;
        margin-bottom: 15px;
        font-weight: normal;
        margin-bottom: 0px;
        display: block;
        padding-right: 50px;
        position: relative; }
        .learn__section--list .learn__link:after {
          content: '';
          width: 15px;
          height: 15px;
          background-image: url("../img/down-arrow.svg");
          background-position: center right;
          background-size: 15px;
          background-repeat: no-repeat;
          right: 5px;
          top: 5px;
          position: absolute;
          -moz-transition: all 0.5s ease;
          -webkit-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease; }
      .learn__section--list .js-accordian-open:after {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg); }
      .learn__section--list .learn__link:hover {
        color: #777777; }
      .learn__section--list .learn__link.tooltip-active {
        color: #777777; }
      .learn__section--list .learn__accordian {
        display: none;
        overflow: hidden; }
        .learn__section--list .learn__accordian__image {
          float: right;
          width: 120px;
          padding-left: 20px;
          text-align: center; }
        .learn__section--list .learn__accordian__title {
          width: calc(100% - 120px);
          float: left; }
        .learn__section--list .learn__accordian__body {
          float: left;
          width: 100%; }
          .learn__section--list .learn__accordian__body p {
            margin-bottom: 20px; }
          .learn__section--list .learn__accordian__body p:last-child {
            margin-bottom: 0px; }
        .learn__section--list .learn__accordian__wrapper {
          float: left;
          width: calc(100% - 120px); }
        .learn__section--list .learn__accordian__wrapper--full {
          float: left;
          width: 100%; }
        .learn__section--list .learn__accordian__readmore {
          margin-top: 15px;
          float: left;
          width: 100%; }
      .learn__section--list ul li {
        border-bottom: 1px solid #D8D8D8;
        padding-bottom: 15px;
        padding-top: 15px; }
      .learn__section--list .learn__accordian {
        margin-top: 20px;
        margin-bottom: 20px; }

.learn_wrapper {
  position: relative;
  padding-bottom: 100px; }

.learn__tooltip__wrapper {
  display: none;
  position: absolute;
  width: 100%;
  border: 0px solid black;
  padding-left: 40px;
  right: 0px;
  transition: all .5s ease-out 0s;
  top: 0;
  /* start out at position 0 */
  z-index: 5;
  display: none !important; }
  .learn__tooltip__wrapper .learn__tooltip__image {
    float: right;
    width: 100px;
    padding-left: 20px; }
  .learn__tooltip__wrapper .learn__tooltip__title {
    color: #F76E83;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 20px;
    width: 100%;
    font-weight: bold; }
  .learn__tooltip__wrapper .learn__tooltip__body {
    width: calc(100% - 120px);
    float: left; }
    .learn__tooltip__wrapper .learn__tooltip__body p {
      margin-bottom: 0px;
      font-size: 18px !important;
      font-weight: normal;
      line-height: 24px !important; }
    .learn__tooltip__wrapper .learn__tooltip__body--noimage {
      width: calc(100%); }
    .learn__tooltip__wrapper .learn__tooltip__body p {
      margin-bottom: 20px; }
    .learn__tooltip__wrapper .learn__tooltip__body p:last-child {
      margin-bottom: 0px; }
  .learn__tooltip__wrapper .learn__tooltip__readmore {
    width: calc(100% - 120px);
    float: left;
    margin-top: 15px; }

.learn__tooltip {
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
  padding: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 30px;
  background: white;
  background: #FFFFFF;
  box-shadow: 0 0 5px 0 #D8D8D8;
  border-radius: 2px;
  font-size: 16px;
  width: 100%; }

.learn__tooltip:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  left: 50%;
  margin-left: -15px;
  box-sizing: border-box;
  background: white;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: 0px 0px 5px 0 #D8D8D8; }

.learn__tooltip:after {
  content: "";
  position: absolute;
  /*width: 25px;
    height: 50px;
    top:65px;*/
  background: white;
  width: 50px;
  height: 25px;
  top: 0px;
  margin-left: -20px;
  left: 50%; }

.form-input {
  font-weight: 300; }

.overflow-hidden {
  overflow: hidden; }

.notknown {
  box-sizing: border-box;
  background: white;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: 0px 0px 5px 0 #D8D8D8; }

/*.learn__tooltip::after{
    content: "";
    position: absolute;
    width: 25px;
    height: 50px;
    top:65px;
    background:white;
    left: 0px;
}*/
.form-input {
  font-weight: 300; }

.overflow-hidden {
  overflow: hidden; }

.learn__heading {
  display: none;
  margin-bottom: 10px;
  font-size: 16px;
  color: #aaaaaa;
  line-height: 20px; }
  @media (max-width: 819px) {
    .learn__heading {
      display: block; } }

.specialist__overview .overview__rating--full {
  text-align: left;
  line-height: 1.2 !important; }

.noimage .learn__tooltip__body {
  width: 100%; }

.noimage .learn__tooltip__image {
  display: none; }

.profile-search__form {
  overflow: hidden; }

.profile-search__left {
  width: calc(50% - 55px);
  float: left;
  margin-right: 20px; }
  @media (max-width: 599px) {
    .profile-search__left {
      width: 100%;
      margin-bottom: 15px; } }

.profile-search__right {
  width: calc(50% - 55px);
  float: left;
  margin-right: 20px; }
  @media (max-width: 599px) {
    .profile-search__right {
      width: calc(100% - 90px);
      margin-bottom: 10px; } }

.profile-search__submit {
  width: 70px;
  float: left;
  height: 66.5px; }
  @media (max-width: 949px) {
    .profile-search__submit {
      height: 60px; } }

.profile {
  padding-top: 50px;
  padding-bottom: 50px;
  /* Filters */ }
  @media (max-width: 599px) {
    .profile {
      padding-top: 30px; } }
  .profile__search {
    padding-bottom: 30px; }
    @media (max-width: 599px) {
      .profile__search {
        padding-bottom: 5px; } }
  .profile__filters {
    margin-bottom: 40px; }
    @media (max-width: 540px) {
      .profile__filters {
        margin-bottom: 0; } }
    .profile__filters .profile-filters {
      color: #a0a0a0;
      padding-top: 0px;
      padding-bottom: 0px;
      text-align: center; }
      .profile__filters .profile-filters span {
        font-weight: 500; }
      .profile__filters .profile-filters label {
        display: inline-block;
        border-bottom: solid 2px transparent;
        cursor: pointer; }
      .profile__filters .profile-filters label, .profile__filters .profile-filters span {
        margin-top: 10px;
        margin-left: 30px;
        margin-right: 30px; }
        @media (max-width: 949px) {
          .profile__filters .profile-filters label, .profile__filters .profile-filters span {
            margin-left: 20px;
            margin-right: 20px; } }
      .profile__filters .profile-filters input[type="radio"] {
        display: none; }
      .profile__filters .profile-filters input[type="radio"]:checked + label {
        border-bottom: solid 2px #FFA328; }
      .profile__filters .profile-filters input[type="radio"]:hover + label {
        border-bottom: solid 2px #aaaaaa; }
  .profile__overview {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .profile__overview {
        width: 100%; } }
    .profile__overview .overview__rating {
      width: 310px;
      float: left;
      padding-right: 80px;
      text-align: center; }
      @media (max-width: 949px) {
        .profile__overview .overview__rating {
          width: 100%;
          padding-right: 0px; }
          .profile__overview .overview__rating .rating__inner {
            max-width: 250px;
            margin: 0pt auto;
            margin-bottom: 30px; } }
      .profile__overview .overview__rating .rating__logo {
        display: block; }
      .profile__overview .overview__rating .rating__rating {
        display: block;
        margin-bottom: 10px; }
        .profile__overview .overview__rating .rating__rating span {
          font-weight: bold; }
      .profile__overview .overview__rating .rating__stars {
        display: block; }
    .profile__overview .overview__details {
      width: calc(100% - 530px);
      float: left;
      padding-right: 80px; }
      @media (max-width: 949px) {
        .profile__overview .overview__details {
          width: 100%;
          padding-right: 0px; } }
      .profile__overview .overview__details .overview__name {
        margin-bottom: 20px;
        font-weight: 900;
        display: block;
        color: #222222;
        font-size: 30px !important;
        /*@include media("<mid") {
                    text-align: center;
                }*/ }
      .profile__overview .overview__details .overview__link {
        margin-bottom: 20px;
        display: block;
        color: #222222; }
    .profile__overview .overview__book {
      width: 220px;
      float: left;
      padding-top: 20px;
      padding-bottom: 40px; }
      .profile__overview .overview__book img {
        max-width: 100px; }
      @media (max-width: 540px) {
        .profile__overview .overview__book {
          padding-bottom: 0; } }
      @media (max-width: 949px) {
        .profile__overview .overview__book {
          width: 100%; } }
  .profile__factsheet {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .profile__factsheet {
        width: 100%; } }
    .profile__factsheet .factsheet__map {
      padding-bottom: 60px; }
      .profile__factsheet .factsheet__map img {
        width: 100%;
        display: block;
        max-width: 300px;
        margin: 0pt auto; }
    .profile__factsheet .factsheet__address {
      padding-bottom: 80px; }
      @media (max-width: 540px) {
        .profile__factsheet .factsheet__address {
          padding-bottom: 30px; } }
      .profile__factsheet .factsheet__address p {
        white-space: pre-wrap; }
  .profile__success {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .profile__success {
        width: 100%; } }
  .profile__specialist {
    margin: 0pt auto;
    width: 100%;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 40px; }
    .profile__specialist .l-container {
      max-width: 1200px; }
    @media (max-width: 949px) {
      .profile__specialist {
        width: 100%; } }
  .profile__photos {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .profile__photos {
        width: 100%; } }
  .profile__prices {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1000px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .profile__prices {
        width: 100%; } }
  .profile__maps {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .profile__maps {
        width: 100%; } }
  .profile__FAQs {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1000px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .profile__FAQs {
        width: 100%; } }

.similar-clinics {
  padding-bottom: 50px; }
  .similar-clinics h3 {
    color: #FFA328;
    font-weight: bold;
    margin-bottom: 70px; }
  .similar-clinics .similar-clinic {
    width: 90%;
    max-width: 250px !important;
    margin: 0pt auto;
    display: block;
    transition: all .2s ease-in-out; }
    .similar-clinics .similar-clinic:hover {
      z-index: 0;
      position: relative; }
  .similar-clinics .similar-clinic__image img {
    width: 100%; }
  .similar-clinics .similar-clinic__name {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px; }
  .similar-clinics .similar-clinic__rating {
    margin: 0pt auto;
    width: 90%;
    max-width: 200px; }

.faq-table {
  margin-bottom: 40px; }
  .faq-table h3 {
    color: #FFA328;
    font-weight: bold;
    margin-bottom: 50px; }
  .faq-table__question p:last-child {
    margin-bottom: 0px; }
  .faq-table__answer {
    margin-bottom: 40px; }
  .faq-table__prefix {
    display: block;
    float: left;
    padding-right: 5px;
    font-weight: 500; }

.back-link {
  padding-top: 0px;
  padding-bottom: 10px; }
  .back-link a {
    color: #000000;
    font-size: 16px !important;
    text-decoration: underline; }

.tooltip_content__title {
  margin-bottom: 10px;
  font-size: 18px !important;
  font-weight: bold; }

.tooltip_content__body {
  margin-bottom: 10px; }
  .tooltip_content__body p {
    font-size: 18px !important; }

.tooltip_content__link {
  font-weight: normal !important;
  color: #222222 !important;
  font-size: 16px !important;
  text-decoration: underline !important; }

.img-80 {
  max-width: 80% !important;
  margin: 0pt auto !important; }

.img-90 {
  max-width: 80% !important;
  margin: 0pt auto !important; }

.img-80 {
  max-width: 80% !important;
  margin: 0pt auto !important; }

.img-60 {
  max-width: 60% !important;
  margin: 0pt auto !important; }

.img-90 {
  max-width: 80% !important;
  margin: 0pt auto !important; }

.pre-wrap p {
  white-space: pre-wrap;
  margin-bottom: 0px; }

.image-and-content {
  width: 100%;
  margin-bottom: 50px; }
  @media (max-width: 540px) {
    .image-and-content {
      margin-bottom: 20px; } }
  .image-and-content__inner {
    overflow: hidden; }
  .image-and-content__content {
    float: left;
    width: calc( 100% - 150px); }
    @media (max-width: 499px) {
      .image-and-content__content {
        width: 100%; } }
    .image-and-content__content h3 {
      font-size: 26px !important;
      font-weight: bold;
      margin-bottom: 10px !important; }
    .image-and-content__content ul {
      margin-left: 20px !important; }
  .image-and-content__image {
    float: left;
    width: 150px;
    padding-right: 40px; }
    .image-and-content__image img {
      width: 100%;
      display: block; }
    @media (max-width: 499px) {
      .image-and-content__image {
        width: 100%;
        padding-right: 0px; }
        .image-and-content__image img {
          width: 20%;
          max-width: 40px !important;
          margin: 0px !important;
          display: block;
          margin-bottom: 20px !important; } }

.do-not-show {
  display: none !important; }

.do-not-show {
  display: none !important; }

.form-input--select {
  line-height: 22px !important; }

.rating__logo img {
  max-height: 175px; }

.specialist {
  padding-top: 50px;
  /* Filters */ }
  @media (max-width: 599px) {
    .specialist {
      padding-top: 30px; } }
  .specialist__search {
    padding-bottom: 30px; }
    @media (max-width: 599px) {
      .specialist__search {
        padding-bottom: 5px; } }
  .specialist__heading {
    text-align: center;
    font-weight: bold;
    color: #ffa328;
    margin-bottom: 80px !important; }
    @media (max-width: 540px) {
      .specialist__heading {
        margin-bottom: 40px !important; } }
  .specialist .specialist-search__form {
    overflow: hidden; }
  .specialist .specialist-search__left {
    width: calc(50% - 55px);
    float: left;
    margin-right: 20px; }
    @media (max-width: 599px) {
      .specialist .specialist-search__left {
        width: 100%;
        margin-bottom: 15px; } }
  .specialist .specialist-search__right {
    width: calc(50% - 55px);
    float: left;
    margin-right: 20px; }
    @media (max-width: 599px) {
      .specialist .specialist-search__right {
        width: calc(100% - 90px);
        margin-bottom: 10px; } }
  .specialist .specialist-search__submit {
    width: 70px;
    float: left;
    height: 66.5px; }
    @media (max-width: 949px) {
      .specialist .specialist-search__submit {
        height: 60px; } }
  .specialist__filters {
    margin-bottom: 40px; }
    .specialist__filters .specialist-filters {
      color: #a0a0a0;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center; }
      .specialist__filters .specialist-filters span {
        font-weight: 500; }
      .specialist__filters .specialist-filters label {
        display: inline-block;
        border-bottom: solid 2px transparent;
        cursor: pointer; }
      .specialist__filters .specialist-filters label, .specialist__filters .specialist-filters span {
        margin-top: 10px;
        margin-left: 30px;
        margin-right: 30px; }
        @media (max-width: 949px) {
          .specialist__filters .specialist-filters label, .specialist__filters .specialist-filters span {
            margin-left: 20px;
            margin-right: 20px; } }
      .specialist__filters .specialist-filters input[type="radio"] {
        display: none; }
      .specialist__filters .specialist-filters input[type="radio"]:checked + label {
        border-bottom: solid 2px #FFA328; }
      .specialist__filters .specialist-filters input[type="radio"]:hover + label {
        border-bottom: solid 2px #aaaaaa; }
  .specialist__overview {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1400px;
    padding-top: 30px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .specialist__overview {
        width: 100%; } }
    .specialist__overview .overview__extra {
      margin-bottom: 25px !important; }
      .specialist__overview .overview__extra p {
        margin-bottom: 15px !important; }
      @media (max-width: 540px) {
        .specialist__overview .overview__extra {
          margin-bottom: 0; } }
    .specialist__overview .overview__rating {
      width: 310px;
      float: left;
      padding-right: 80px;
      text-align: center; }
      .specialist__overview .overview__rating--full {
        text-align: left;
        line-height: 1.5 !important; }
        .specialist__overview .overview__rating--full h4 {
          line-height: 2 !important;
          margin: 0px; }
        .specialist__overview .overview__rating--full ul {
          margin-bottom: 0px; }
      @media (max-width: 949px) {
        .specialist__overview .overview__rating {
          width: 100%;
          padding-right: 0px; }
          .specialist__overview .overview__rating .rating__inner {
            max-width: 250px;
            margin: 0pt auto;
            margin-bottom: 30px; } }
      .specialist__overview .overview__rating .rating__social {
        margin-top: 30px; }
    .specialist__overview .overview__details {
      width: calc(100% - 530px);
      float: left;
      padding-right: 80px; }
      .specialist__overview .overview__details--full {
        width: calc(100% - 330px); }
      @media (max-width: 949px) {
        .specialist__overview .overview__details {
          width: 100%;
          padding-right: 0px; } }
      .specialist__overview .overview__details .overview__name {
        margin-bottom: 15px;
        font-weight: bold;
        display: block;
        color: #222222;
        font-size: 30px !important; }
      .specialist__overview .overview__details .overview__link {
        margin-bottom: 20px;
        display: block;
        color: #222222; }
      .specialist__overview .overview__details .overview__qualifications {
        margin-bottom: 20px;
        display: block;
        color: #222222; }
        .specialist__overview .overview__details .overview__qualifications .qualification {
          text-decoration: underline;
          display: inline-block;
          margin-right: 10px; }
        .specialist__overview .overview__details .overview__qualifications ul {
          margin: 0px !important; }
        .specialist__overview .overview__details .overview__qualifications li {
          display: inline-block !important; }
    .specialist__overview .overview__book {
      width: 220px;
      float: left;
      padding-top: 20px;
      padding-bottom: 40px; }
      .specialist__overview .overview__book img {
        max-width: 100px; }
      @media (max-width: 540px) {
        .specialist__overview .overview__book {
          padding-bottom: 0; } }
      @media (max-width: 949px) {
        .specialist__overview .overview__book {
          width: 100%; } }
  .specialist__specialist {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .specialist__specialist {
        width: 100%; } }
    .specialist__specialist .specialist__bio {
      padding-bottom: 40px; }
      @media (max-width: 540px) {
        .specialist__specialist .specialist__bio {
          padding-bottom: 0; }
          .specialist__specialist .specialist__bio:last-child {
            padding-bottom: 40px; } }
      .specialist__specialist .specialist__bio h3 {
        font-size: 26px !important;
        font-weight: bold;
        margin-bottom: 10px !important; }
      .specialist__specialist .specialist__bio h4 {
        margin-bottom: 5px; }
    .specialist__specialist .specialist__list {
      padding-top: 30px;
      margin: 0px !important; }
      .specialist__specialist .specialist__list h4 {
        font-size: 18px !important;
        font-weight: bold;
        margin-bottom: 10px !important; }
    .specialist__specialist .specialist__address {
      padding-bottom: 40px; }
  .specialist__success {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .specialist__success {
        width: 100%; } }
  .specialist__specialist {
    margin: 0pt auto;
    width: 100%;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 540px) {
      .specialist__specialist {
        padding-top: 10px; } }
    .specialist__specialist .l-container {
      max-width: 1200px; }
    @media (max-width: 949px) {
      .specialist__specialist {
        width: 100%; } }
  .specialist__photos {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .specialist__photos {
        width: 100%; } }
  .specialist__prices {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1000px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .specialist__prices {
        width: 100%; } }
  .specialist__maps {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1400px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .specialist__maps {
        width: 100%; } }
  .specialist__FAQs {
    margin: 0pt auto;
    overflow: hidden;
    width: 100%;
    max-width: 1000px;
    padding-top: 40px;
    padding-bottom: 40px; }
    @media (max-width: 949px) {
      .specialist__FAQs {
        width: 100%; } }

.other-specialists {
  padding-bottom: 50px;
  margin-top: -130px; }
  .other-specialists h3 {
    color: #FFA328;
    font-weight: bold;
    margin-bottom: 70px; }
  .other-specialists .similar-clinic {
    width: 90%;
    max-width: 250px !important;
    margin: 0pt auto;
    display: block;
    transition: all .2s ease-in-out; }
    .other-specialists .similar-clinic:hover {
      z-index: 0;
      position: relative; }
  .other-specialists .similar-clinic__image img {
    width: 100%; }
  .other-specialists .similar-clinic__name {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px; }
  .other-specialists .similar-clinic__rating {
    margin: 0pt auto;
    width: 90%;
    max-width: 200px; }

.other-team-members {
  padding-bottom: 50px;
  margin-top: -40px; }
  .other-team-members h3 {
    color: #FFA328;
    font-weight: bold;
    margin-bottom: 70px; }
  .other-team-members .similar-clinic {
    width: 90%;
    max-width: 250px !important;
    margin: 0pt auto;
    display: block;
    transition: all .2s ease-in-out; }
    .other-team-members .similar-clinic:hover {
      transform: scale(1.1);
      z-index: 0;
      position: relative; }
  .other-team-members .similar-clinic__image img {
    width: 100%; }
  .other-team-members .similar-clinic__name {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    margin-top: 10px;
    margin-bottom: 10px; }
  .other-team-members .similar-clinic__rating {
    margin: 0pt auto;
    width: 90%;
    max-width: 200px; }

.faq-table {
  margin-bottom: 40px; }
  .faq-table h3 {
    color: #FFA328;
    font-weight: bold;
    margin-bottom: 50px; }
  .faq-table__question {
    font-weight: bold; }
    .faq-table__question p:last-child {
      margin-bottom: 0px; }
    .faq-table__question p {
      font-weight: bold;
      font-size: 22px !important; }
    .faq-table__question .faq-table__prefix {
      line-height: 33px !important;
      font-weight: bold !important; }
  .faq-table__answer {
    margin-bottom: 40px; }
  .faq-table__prefix {
    display: block;
    float: left;
    padding-right: 5px;
    font-weight: 300 !important; }

.print-page {
  font-size: 16px;
  color: #333333 !important;
  text-decoration: underline !important; }

.read_more p {
  font-size: 17px; }

.read_more a {
  color: #333333 !important;
  text-decoration: underline !important; }

.read_more--large p {
  font-size: 1rem; }

.publications {
  margin: 0px !important; }

.publication__image img {
  max-width: 150px;
  width: 100%;
  margin-bottom: 8px; }

.specialist__bio {
  margin-bottom: 40px; }
  .specialist__bio > p:last-child {
    margin-bottom: 0px; }
  .specialist__bio > ul:last-child {
    margin-bottom: 0px !important; }

.bio-contacts .bio-languages {
  margin: 0px !important; }

.rating__specialist__photo {
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  border-radius: 50%; }

.search-results {
  max-width: 1000px;
  margin: 0pt auto;
  margin-top: 40px;
  margin-bottom: 100px; }
  .search-results h2 {
    font-size: 18px !important;
    color: #000000 !important;
    font-weight: 300 !important;
    margin-bottom: 100px; }
  .search-results h3 {
    font-size: 22px !important;
    color: #000000;
    margin-bottom: 10px; }
  .search-results p {
    color: #000000; }
  .search-results a {
    color: #000000 !important;
    margin-top: -10px;
    display: block; }
  .search-results a:hover {
    color: #F76E83 !important;
    opacity: 1;
    text-decoration: underline; }
  .search-results .search-result {
    margin-bottom: 70px; }

.article-page, .rte-content {
  max-width: 1000px;
  margin: 0pt auto;
  margin-top: 20px;
  margin-bottom: 100px; }
  .article-page h2, .rte-content h2 {
    font-size: 30px !important;
    color: #000000 !important;
    font-weight: bold !important;
    margin-bottom: 25px; }
  .article-page h3, .rte-content h3 {
    font-size: 26px !important;
    color: #000000;
    margin-bottom: 25px; }
  .article-page p, .rte-content p {
    color: #000000;
    margin-bottom: 25px; }
  .article-page a, .rte-content a {
    color: #F76E83 !important;
    margin-top: -10px; }
  .article-page a:hover, .rte-content a:hover {
    color: #000000 !important;
    opacity: 1;
    text-decoration: underline; }
  .article-page .search-result, .rte-content .search-result {
    margin-bottom: 70px; }

.appointment-form {
  min-height: 100%;
  background: #fafafa;
  padding-top: 50px; }
  .appointment-form__inner {
    width: 90%;
    margin: 0pt auto; }
  .appointment-form table {
    width: 300px; }
  .appointment-form header {
    padding-bottom: 50px; }
    .appointment-form header h1 {
      font-size: 30px !important;
      color: #000000;
      font-weight: normal;
      margin-bottom: 20px !important; }
    .appointment-form header p {
      font-size: 18px !important;
      color: #000000;
      font-weight: 300; }
  .appointment-form form {
    overflow: hidden; }
    .appointment-form form textarea {
      height: 150px;
      padding-top: 28px; }
    .appointment-form form h2 {
      font-size: 16px !important;
      font-weight: 300 !important;
      color: #000000;
      font-weight: normal !important;
      margin-bottom: 10px; }
    .appointment-form form label {
      font-size: 16px;
      font-weight: 300 !important;
      color: #aaaaaa;
      margin-bottom: 10px;
      font-weight: normal;
      margin-top: 0px; }
    .appointment-form form input {
      color: #aaaaaa; }
  .appointment-form__left {
    float: left;
    width: 32%; }
    @media (max-width: 949px) {
      .appointment-form__left {
        width: 100%; } }
  .appointment-form__right {
    float: right;
    width: 56%; }
    @media (max-width: 949px) {
      .appointment-form__right {
        width: 100%; } }
  .appointment-form__submit {
    float: left;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%; }
    .appointment-form__submit button {
      max-width: 400px;
      display: inline-block; }
    @media (max-width: 949px) {
      .appointment-form__submit button {
        max-width: 100%; } }
  .appointment-form footer {
    padding-bottom: 50px;
    padding-top: 50px;
    text-align: left;
    font-weight: 300 !important;
    color: #000000;
    font-weight: normal;
    margin-bottom: 10px; }
    .appointment-form footer p {
      font-size: 16px !important; }
  .appointment-form .logo-and-address {
    overflow: hidden;
    margin-top: 20px; }
    .appointment-form .logo-and-address__image {
      float: left;
      width: 240px;
      margin-top: 5px; }
      .appointment-form .logo-and-address__image img {
        width: 100%;
        display: block; }
    .appointment-form .logo-and-address__content {
      padding-left: 290px; }
    @media (max-width: 599px) {
      .appointment-form .logo-and-address .logo-and-address__image {
        width: 140px; }
      .appointment-form .logo-and-address .logo-and-address__content {
        padding-left: 0px;
        width: 100%;
        float: left;
        margin-top: 20px; } }

.field-validation-error {
  font-size: 14px;
  color: red;
  padding-top: 5px;
  display: block; }

.contact-box-label {
  float: left;
  width: 100%;
  margin-top: 50px; }

.contact-box-heading p {
  float: left;
  width: 100%;
  margin-top: 0px;
  font-size: 30px !important;
  line-height: 44px;
  margin-bottom: 10px;
  font-weight: 300;
  color: #666666 !important; }
  @media (max-width: 949px) {
    .contact-box-heading p {
      font-size: 25px !important;
      line-height: 40px;
      margin-bottom: 0px; } }
  @media (max-width: 400px) {
    .contact-box-heading p {
      font-size: 20px !important;
      line-height: 30px; } }

@media (max-width: 949px) {
  .contact-team {
    margin-top: -30px; } }

.contact-box {
  float: left;
  width: 50%;
  display: block;
  margin-top: 20px; }
  @media (max-width: 499px) {
    .contact-box {
      width: 100%; } }
  .contact-box--full {
    width: 100%; }
  .contact-box p {
    display: block;
    width: 100%;
    min-height: 40px; }
  .contact-box__image {
    float: left;
    width: 40px;
    height: 40px;
    margin-top: 5px; }
    .contact-box__image img {
      width: 100%;
      display: block; }
  .contact-box__content {
    padding-left: 60px;
    padding-top: 5px; }
  .contact-box a {
    color: #000000; }
    .contact-box a:hover {
      text-decoration: underline; }

.g-col-filler {
  display: block;
  height: 100%; }

.newsletter-opt-in {
  display: flex;
  align-items: center;
  margin-top: 10px; }

/**
 * Form
 * =================================
 */
.has-floating-label {
  position: relative;
  /*
    label.error {
        position: absolute;
        bottom: 10px;
        left: 18px;
        color: $red;
        font-size:1rem;

    }*/ }
  .has-floating-label .form__floating-label {
    position: absolute;
    left: 18px;
    top: 19px;
    pointer-events: none;
    transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1);
    font-size: 1rem; }
    @media (max-width: 949px) {
      .has-floating-label .form__floating-label {
        top: 15px; } }
  .has-floating-label input.js-hide-label ~ .form__floating-label,
  .has-floating-label textarea.js-hide-label ~ .form__floating-label {
    top: 8px;
    left: 18px;
    font-size: 13px;
    height: 19px; }
  .has-floating-label input[type=text] {
    padding-top: 29px;
    padding-bottom: 11px; }

.form--white label {
  color: #ffffff; }

.form--white option {
  color: #000000 !important; }

.form--white .has-floating-label .form__floating-label {
  position: absolute;
  left: 18px;
  top: 18px;
  font-size: 1rem; }
  @media (max-width: 949px) {
    .form--white .has-floating-label .form__floating-label {
      left: 18px;
      top: 17px;
      font-size: 17px; } }

.form__floating-label {
  width: 90%;
  overflow: hidden;
  text-align: left;
  height: 30px; }
  @media (max-width: 949px) {
    .form__floating-label {
      height: 30px; } }

.label-cbx {
  user-select: none;
  cursor: pointer;
  margin-bottom: 0; }

.label-cbx input:checked + .checkbox {
  border-color: #20C2E0; }

.label-cbx input:checked + .checkbox svg path {
  fill: #20C2E0; }

.label-cbx input:checked + .checkbox svg polyline {
  stroke-dashoffset: 0; }

.label-cbx:hover .checkbox svg path {
  stroke-dashoffset: 0; }

.label-cbx .checkbox {
  position: relative;
  top: 2px;
  float: left;
  margin-right: 8px;
  width: 20px;
  height: 20px;
  border: 2px solid #C8CCD4;
  border-radius: 3px; }

.label-cbx .checkbox svg {
  position: absolute;
  top: -2px;
  left: -2px; }

.label-cbx .checkbox svg path {
  fill: none;
  stroke: #20C2E0;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 71px;
  stroke-dashoffset: 71px;
  transition: all 0.6s ease; }

.label-cbx .checkbox svg polyline {
  fill: none;
  stroke: #FFF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 18px;
  stroke-dashoffset: 18px;
  transition: all 0.3s ease; }

.label-cbx > span {
  pointer-events: none;
  vertical-align: middle; }

.cntr {
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
  text-align: center; }

.invisible {
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0; }

/* Tootipster bundle */
.tooltipster-fall, .tooltipster-grow.tooltipster-show {
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); }

.tooltipster-base {
  display: flex;
  pointer-events: none;
  position: absolute; }

.tooltipster-box {
  flex: 1 1 auto; }

.tooltipster-content {
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  overflow: auto; }

.tooltipster-ruler {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden; }

.tooltipster-fade {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity; }

.tooltipster-fade.tooltipster-show {
  opacity: 1; }

.tooltipster-grow {
  -webkit-transform: scale(0, 0);
  -moz-transform: scale(0, 0);
  -o-transform: scale(0, 0);
  -ms-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-backface-visibility: hidden; }

.tooltipster-grow.tooltipster-show {
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); }

.tooltipster-swing {
  opacity: 0;
  -webkit-transform: rotateZ(4deg);
  -moz-transform: rotateZ(4deg);
  -o-transform: rotateZ(4deg);
  -ms-transform: rotateZ(4deg);
  transform: rotateZ(4deg);
  -webkit-transition-property: -webkit-transform,opacity;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform; }

.tooltipster-swing.tooltipster-show {
  opacity: 1;
  -webkit-transform: rotateZ(0);
  -moz-transform: rotateZ(0);
  -o-transform: rotateZ(0);
  -ms-transform: rotateZ(0);
  transform: rotateZ(0);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 1);
  -webkit-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -moz-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -ms-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  -o-transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4);
  transition-timing-function: cubic-bezier(0.23, 0.635, 0.495, 2.4); }

.tooltipster-fall {
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  -ms-transition-property: top;
  transition-property: top;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); }

.tooltipster-fall.tooltipster-initial {
  top: 0 !important; }

.tooltipster-fall.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  top: 0 !important;
  opacity: 0; }

.tooltipster-slide {
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  -ms-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.15); }

.tooltipster-slide.tooltipster-initial {
  left: -40px !important; }

.tooltipster-slide.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  left: 0 !important;
  opacity: 0; }

@keyframes tooltipster-fading {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.tooltipster-update-fade {
  animation: tooltipster-fading .4s; }

@keyframes tooltipster-rotating {
  25% {
    transform: rotate(-2deg); }
  75% {
    transform: rotate(2deg); }
  100% {
    transform: rotate(0); } }

.tooltipster-update-rotate {
  animation: tooltipster-rotating .6s; }

@keyframes tooltipster-scaling {
  50% {
    transform: scale(1.1); }
  100% {
    transform: scale(1); } }

.tooltipster-update-scale {
  animation: tooltipster-scaling .6s; }

.tooltipster-sidetip .tooltipster-box {
  background: #565656;
  border: 2px solid #000;
  border-radius: 4px; }

.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
  margin-top: 8px; }

.tooltipster-sidetip.tooltipster-left .tooltipster-box {
  margin-right: 8px; }

.tooltipster-sidetip.tooltipster-right .tooltipster-box {
  margin-left: 8px; }

.tooltipster-sidetip.tooltipster-top .tooltipster-box {
  margin-bottom: 8px; }

.tooltipster-sidetip .tooltipster-content {
  color: #fff;
  line-height: 18px;
  padding: 6px 14px; }

.tooltipster-sidetip .tooltipster-arrow {
  overflow: hidden;
  position: absolute; }

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow {
  height: 10px;
  margin-left: -10px;
  top: 0;
  width: 20px; }

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  right: 0;
  top: 0;
  width: 10px; }

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
  height: 20px;
  margin-top: -10px;
  left: 0;
  top: 0;
  width: 10px; }

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
  bottom: 0;
  height: 10px;
  margin-left: -10px;
  width: 20px; }

.tooltipster-sidetip .tooltipster-arrow-background, .tooltipster-sidetip .tooltipster-arrow-border {
  height: 0;
  position: absolute;
  width: 0; }

.tooltipster-sidetip .tooltipster-arrow-background {
  border: 10px solid transparent; }

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #565656;
  left: 0;
  top: 3px; }

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #565656;
  left: -3px;
  top: 0; }

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #565656;
  left: 3px;
  top: 0; }

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #565656;
  left: 0;
  top: -3px; }

.tooltipster-sidetip .tooltipster-arrow-border {
  border: 10px solid transparent;
  left: 0;
  top: 0; }

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #000; }

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
  border-left-color: #000; }

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #000; }

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #000; }

.tooltipster-sidetip .tooltipster-arrow-uncropped {
  position: relative; }

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -10px; }

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
  left: -10px; }

/* Tooltipster theme */
.tooltipster-sidetip.tooltipster-light .tooltipster-box {
  font-weight: bold !important;
  border-radius: 0px;
  border: 1px solid #ccc;
  background: #fafafa; }

.tooltipster-sidetip.tooltipster-light .tooltipster-content {
  color: #666; }

.tooltipster-sidetip.tooltipster-light .tooltipster-arrow {
  height: 9px;
  margin-left: -9px;
  width: 18px; }

.tooltipster-sidetip.tooltipster-light.tooltipster-left .tooltipster-arrow, .tooltipster-sidetip.tooltipster-light.tooltipster-right .tooltipster-arrow {
  height: 18px;
  margin-left: 0;
  margin-top: -9px;
  width: 9px; }

.tooltipster-sidetip.tooltipster-light .tooltipster-arrow-background {
  border: 9px solid transparent; }

.tooltipster-sidetip.tooltipster-light.tooltipster-bottom .tooltipster-arrow-background {
  border-bottom-color: #fafafa;
  top: 1px; }

.tooltipster-sidetip.tooltipster-light.tooltipster-left .tooltipster-arrow-background {
  border-left-color: #fafafa;
  left: -1px; }

.tooltipster-sidetip.tooltipster-light.tooltipster-right .tooltipster-arrow-background {
  border-right-color: #fafafa;
  left: 1px; }

.tooltipster-sidetip.tooltipster-light.tooltipster-top .tooltipster-arrow-background {
  border-top-color: #fafafa;
  top: -1px; }

.tooltipster-sidetip.tooltipster-light .tooltipster-arrow-border {
  border: 9px solid transparent; }

.tooltipster-sidetip.tooltipster-light.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: #ccc; }

.tooltipster-sidetip.tooltipster-light.tooltipster-left .tooltipster-arrow-border {
  border-left-color: #ccc; }

.tooltipster-sidetip.tooltipster-light.tooltipster-right .tooltipster-arrow-border {
  border-right-color: #ccc; }

.tooltipster-sidetip.tooltipster-light.tooltipster-top .tooltipster-arrow-border {
  border-top-color: #ccc; }

.tooltipster-sidetip.tooltipster-light.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -9px; }

.tooltipster-sidetip.tooltipster-light.tooltipster-right .tooltipster-arrow-uncropped {
  left: -9px; }

/**
 * Partials
 */
/**
 * Masthead
 * =================================
 */
.site__wrapper {
  max-width: 1800px;
  margin: 0pt auto; }

@media (max-width: 950px) {
  .sticky .site__wrapper main {
    padding-top: 0px; } }

@media (min-width: 951px) {
  .sticky .site__wrapper main {
    padding-top: 70px; } }

.logo:hover svg, .js-slideout-toggle:hover svg {
  opacity: 0.7; }

.masthead {
  border-bottom: 1px solid #bababa;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  max-width: 1800px;
  width: 100%;
  position: relative;
  z-index: 5; }
  .masthead-logo {
    color: #FFA328;
    width: 110px;
    height: 25px;
    display: block;
    margin-top: 4px; }
    @media (max-width: 949px) {
      .masthead-logo {
        margin-top: 2px;
        width: 90px;
        height: 25px;
        padding-top: 3px;
        padding-bottom: 2px; } }
  @media (min-width: 951px) {
    .masthead.sticky {
      position: fixed;
      z-index: 999; } }
  @media (max-width: 949px) {
    .masthead {
      padding-top: 15px;
      padding-bottom: 15px; } }

@keyframes slide-down {
  0% {
    opacity: 1;
    transform: translateY(-100%); }
  100% {
    opacity: 1;
    transform: translateY(0); } }

/* Themes */
.pink-theme .masthead {
  background-color: #F76E83;
  border-bottom: #ffffff; }

.pink-theme .masthead-logo__inner {
  fill: #ffffff; }

.pink-theme .nav--main > .nav__item > .nav__link {
  color: #ffffff; }

.pink-theme .nav--main > .nav__item > .nav__link:hover:after {
  background-color: #ffffff !important; }

.pink-theme .svg-burger__inner {
  stroke: #ffffff; }

.pink-theme .svg-search__inner {
  fill: #ffffff !important; }

.pink-theme .nav--mobile .nav__link:hover {
  color: #F76E83 !important; }
  .pink-theme .nav--mobile .nav__link:hover:after {
    content: '';
    background-color: #F76E83 !important; }

.pink-theme .sub__item:hover {
  border-bottom: 1px solid #F76E83 !important; }

.pink-theme .sub__item:hover .sub__link {
  color: #F76E83 !important; }

.pink-theme h1, .pink-theme h2 {
  color: #F76E83; }

.pink-theme footer a:hover {
  color: #F76E83 !important;
  text-decoration: none; }

.pink-theme .nav__link.active:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ffffff !important;
  margin-bottom: -1px; }

/* Themes */
.orange-theme .masthead {
  background-color: #FFA328;
  border-bottom: #ffffff; }

.orange-theme .masthead-logo__inner {
  fill: #ffffff; }

.orange-theme .nav--main > .nav__item > .nav__link {
  color: #ffffff; }

.orange-theme .nav--main > .nav__item > .nav__link:hover:after {
  background-color: #ffffff !important; }

.orange-theme .svg-burger__inner {
  stroke: #ffffff; }

.orange-theme .svg-search__inner {
  fill: #ffffff !important; }

.orange-theme .nav--mobile .nav__link:hover {
  color: #FFA328 !important; }
  .orange-theme .nav--mobile .nav__link:hover:after {
    content: '';
    background-color: #FFA328 !important; }

.orange-theme .sub__item:hover {
  border-bottom: 1px solid #FFA328 !important; }

.orange-theme .sub__item:hover .sub__link {
  color: #FFA328 !important; }

.orange-theme h1, .orange-theme h2 {
  color: #FFA328; }

.orange-theme .nav__link.active:after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #ffffff !important;
  margin-bottom: -1px; }

@media (max-width: 950px) {
  .js-search-toggle {
    display: none !important; }
  .nav__item.nav__item--mobile {
    float: right; } }

.header--extended {
  max-width: 800px !important; }

.label-inline p {
  display: inline-block !important;
  line-height: 1.4;
  font-size: 18px; }

/**
 * Footer
 * =================================
 */
.footer {
  background-color: #fafafa;
  padding-top: 60px; }
  .footer h3 {
    font-weight: bold;
    line-height: 1.4; }
  .footer a {
    color: black;
    font-weight: 300;
    text-decoration: underline; }
    .footer a:hover {
      color: #FFA328; }
  .footer p {
    line-height: 1.4;
    margin-bottom: 20px; }
  .footer .l-container > .g > .g-col {
    padding-bottom: 60px; }
  .footer-base {
    padding-top: 30px;
    border-top: 1px solid #bababa; }
    .footer-base .g-col {
      padding-bottom: 30px; }
    .footer-base-trademark {
      color: #bababa;
      font-weight: 300; }
    .footer-base-links {
      float: right; }
      .footer-base-links a {
        margin-left: 30px;
        margin-right: 0px;
        color: #bababa;
        text-decoration: none;
        font-weight: 300; }
      @media (max-width: 949px) {
        .footer-base-links {
          float: left; }
          .footer-base-links a {
            margin-right: 30px;
            margin-left: 0px; } }
  .footer .contact-header {
    margin-top: -10px; }
  .footer .has-floating-label .form__floating-label {
    font-size: 16px;
    top: 20px; }
    @media (min-width: 1201px) {
      .footer .has-floating-label .form__floating-label {
        font-size: 1rem;
        top: 19px; } }
    @media (max-width: 949px) {
      .footer .has-floating-label .form__floating-label {
        font-size: 1rem;
        top: 17px; } }

/**
 * Browser upgrade message
 * =================================
 */
.browserupgrade {
  margin: .2em 0;
  background: #ccc;
  color: #000;
  padding: .2em 0; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: white !important;
  opacity: .9; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
  padding: 20px !important; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 1100px;
    height: 100%; }
  .mfp-iframe-holder .mfp-close {
    z-index: 200000;
    color: black !important;
    padding-right: 30px;
    top: 10px;
    position: absolute; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #fafafa;
    background-image: url("../img/ajax-loader.gif");
    background-position: center center;
    background-repeat: no-repeat;
    background: #FAFAFA;
    border: 1px solid #D8D8D8;
    box-shadow: 0px 0px 5px 0px #d8d8d8;
    border-radius: 2px; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding: 0px !important; } }

/* 

====== Zoom effect ======

*/
.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8); }
  .mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out; }
  .mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1); }
  .mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-zoom-in.mfp-removing .mfp-with-anim {
    transform: scale(0.8);
    opacity: 0; }
  .mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0; }

/* 

====== Newspaper effect ======

*/
.mfp-newspaper {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-newspaper .mfp-with-anim {
    opacity: 0;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.5s;
    transform: scale(0) rotate(500deg); }
  .mfp-newspaper.mfp-bg {
    opacity: 0;
    transition: all 0.5s; }
  .mfp-newspaper.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1) rotate(0deg); }
  .mfp-newspaper.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-newspaper.mfp-removing .mfp-with-anim {
    transform: scale(0) rotate(500deg);
    opacity: 0; }
  .mfp-newspaper.mfp-removing.mfp-bg {
    opacity: 0; }

/* 

====== Move-horizontal effect ======

*/
.mfp-move-horizontal {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-move-horizontal .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s;
    transform: translateX(-50px); }
  .mfp-move-horizontal.mfp-bg {
    opacity: 0;
    transition: all 0.3s; }
  .mfp-move-horizontal.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: translateX(0); }
  .mfp-move-horizontal.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-move-horizontal.mfp-removing .mfp-with-anim {
    transform: translateX(50px);
    opacity: 0; }
  .mfp-move-horizontal.mfp-removing.mfp-bg {
    opacity: 0; }

/* 

====== Move-from-top effect ======

*/
.mfp-move-from-top {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-move-from-top .mfp-content {
    vertical-align: top; }
  .mfp-move-from-top .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s;
    transform: translateY(-100px); }
  .mfp-move-from-top.mfp-bg {
    opacity: 0;
    transition: all 0.2s; }
  .mfp-move-from-top.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: translateY(0); }
  .mfp-move-from-top.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-move-from-top.mfp-removing .mfp-with-anim {
    transform: translateY(-50px);
    opacity: 0; }
  .mfp-move-from-top.mfp-removing.mfp-bg {
    opacity: 0; }

/* 

====== 3d unfold ======

*/
.mfp-3d-unfold {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-3d-unfold .mfp-content {
    perspective: 2000px; }
  .mfp-3d-unfold .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform-style: preserve-3d;
    transform: rotateY(-60deg); }
  .mfp-3d-unfold.mfp-bg {
    opacity: 0;
    transition: all 0.5s; }
  .mfp-3d-unfold.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: rotateY(0deg); }
  .mfp-3d-unfold.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-3d-unfold.mfp-removing .mfp-with-anim {
    transform: rotateY(60deg);
    opacity: 0; }
  .mfp-3d-unfold.mfp-removing.mfp-bg {
    opacity: 0; }

/* 

====== Zoom-out effect ======

*/
.mfp-zoom-out {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-zoom-out .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    transform: scale(1.2); }
  .mfp-zoom-out.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out; }
  .mfp-zoom-out.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1); }
  .mfp-zoom-out.mfp-ready.mfp-bg {
    opacity: 0.8; }
  .mfp-zoom-out.mfp-removing .mfp-with-anim {
    transform: scale(1.3);
    opacity: 0; }
  .mfp-zoom-out.mfp-removing.mfp-bg {
    opacity: 0; }

/* 

====== "Hinge" close effect ======

*/
@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  100% {
    transform: translateY(700px);
    opacity: 0; } }

.hinge {
  animation-duration: 1s;
  animation-name: hinge; }

.mfp-with-fade .mfp-content, .mfp-with-fade.mfp-bg {
  opacity: 0;
  transition: opacity .5s ease-out; }

.mfp-with-fade.mfp-ready .mfp-content {
  opacity: 1; }

.mfp-with-fade.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-fade.mfp-removing.mfp-bg {
  opacity: 0; }

/**
 * Views
 */
.listings-search__form {
  overflow: hidden; }

.listings-search__left {
  width: calc(50% - 75px);
  float: left;
  margin-right: 20px; }
  @media (max-width: 599px) {
    .listings-search__left {
      width: 100%;
      margin-bottom: 15px; } }

.listings-search__right {
  width: calc(50% - 75px);
  float: left;
  margin-right: 20px; }
  @media (max-width: 599px) {
    .listings-search__right {
      width: calc(100% - 130px);
      margin-bottom: 10px; } }

.listings-search__submit {
  width: 110px;
  float: left;
  height: 66.5px; }
  @media (max-width: 949px) {
    .listings-search__submit {
      height: 59px; } }

.listings {
  padding-top: 50px;
  padding-bottom: 30px;
  /* Filters */
  /* Results */
  /* Pagination */ }
  @media (max-width: 599px) {
    .listings {
      padding-top: 30px; } }
  .listings__search {
    padding-bottom: 30px; }
    @media (max-width: 599px) {
      .listings__search {
        padding-bottom: 5px; } }
  .listings .is-sticky .listings__filters {
    border-bottom: 1px solid #bababa;
    background: #fafafa; }
  .listings__filters {
    margin-bottom: 20px;
    background: white;
    z-index: 999999 !important;
    margin-bottom: 20px; }
    .listings__filters .compare-filters {
      color: #a0a0a0;
      padding-top: 10px;
      padding-bottom: 20px;
      text-align: center; }
      .listings__filters .compare-filters span {
        font-weight: 500; }
      .listings__filters .compare-filters label {
        display: inline-block;
        border-bottom: solid 2px transparent;
        cursor: pointer; }
      .listings__filters .compare-filters label, .listings__filters .compare-filters span {
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 10px; }
        @media (max-width: 949px) {
          .listings__filters .compare-filters label, .listings__filters .compare-filters span {
            margin-left: 20px;
            margin-right: 20px; } }
      .listings__filters .compare-filters input[type="radio"] {
        display: none; }
      .listings__filters .compare-filters input[type="radio"]:checked + label {
        border-bottom: solid 2px #FFA328; }
      .listings__filters .compare-filters input[type="radio"]:hover + label {
        border-bottom: solid 2px #aaaaaa; }
  .listings__results {
    margin-top: 40px; }
    .listings__results .listing-result {
      min-height: 240px !important;
      position: relative;
      line-height: 1.3;
      border: 3px solid #ffffff;
      padding: 25px;
      padding-bottom: 40px;
      overflow: hidden;
      box-shadow: 0px 0px 8px 0px #d8d8d8;
      margin-bottom: 35px; }
      .listings__results .listing-result.active {
        border: 3px solid #3ADBB2; }
      .listings__results .listing-result .toggle {
        width: 50px;
        height: 100px;
        float: left;
        position: relative;
        opacity: 0; }
        .listings__results .listing-result .toggle .control-group {
          position: absolute;
          top: calc(50% - 10px);
          left: 0px; }
      .listings__results .listing-result .toggle.show_toggle {
        opacity: 1 !important; }
      .listings__results .listing-result .rating {
        width: 300px;
        min-width: 300px;
        float: left;
        padding-right: 60px;
        text-align: center; }
        .listings__results .listing-result .rating span {
          font-weight: 600; }
        .listings__results .listing-result .rating__rating {
          padding-top: 10px;
          padding-bottom: 10px; }
        .listings__results .listing-result .rating__stars {
          padding-left: 10px;
          padding-right: 10px;
          padding-top: 10px;
          max-width: 200px;
          margin: 0pt auto;
          margin-bottom: 30px; }
      .listings__results .listing-result .details {
        float: left;
        width: calc(100% - 550px);
        min-width: calc(100% - 550px);
        padding-right: 30px; }
        .listings__results .listing-result .details__name {
          font-size: 24px;
          font-weight: 500;
          padding-bottom: 15px; }
        .listings__results .listing-result .details__discount {
          color: #FFA328;
          padding-bottom: 10px;
          font-weight: 600; }
        .listings__results .listing-result .details__partner {
          font-weight: 300;
          color: #F76E83;
          font-size: 18px;
          display: inline-block;
          text-transform: uppercase; }
        .listings__results .listing-result .details__clinic {
          padding-right: 30px;
          font-weight: 900; }
        .listings__results .listing-result .details__phone {
          padding-bottom: 10px; }
        .listings__results .listing-result .details__address {
          padding-bottom: 10px; }
        .listings__results .listing-result .details__treatments {
          padding-bottom: 10px;
          padding-left: 10px; }
        .listings__results .listing-result .details__nhs {
          padding-top: 20px; }
        .listings__results .listing-result .details .patients {
          float: left;
          padding-right: 20px;
          width: 240px; }
          .listings__results .listing-result .details .patients .Media-body {
            flex-basis: auto !important;
            flex: 0; }
          .listings__results .listing-result .details .patients .Media-figure {
            margin-right: 0px;
            margin-left: 20px; }
      .listings__results .listing-result .ctas {
        width: 200px;
        float: right;
        min-width: 200px; }
        @media (min-width: 900px) {
          .listings__results .listing-result .ctas {
            position: absolute;
            right: 25px;
            bottom: 40px; } }
        .listings__results .listing-result .ctas__distance {
          color: #F76E83;
          font-weight: 900;
          font-size: 17px;
          padding-top: 15px;
          padding-bottom: 15px;
          background-image: url("../img/location.png");
          background-position: center right;
          background-size: contain;
          background-repeat: no-repeat; }
        .listings__results .listing-result .ctas__discount {
          display: none !important;
          color: #FFA328;
          padding-top: 20px;
          text-align: center;
          font-weight: 600; }
        .listings__results .listing-result .ctas__book-appointment {
          padding-top: 30px; }
        .listings__results .listing-result .ctas__nhs {
          display: none !important; }
      .listings__results .listing-result .success {
        width: calc( 100% - 350px);
        min-width: calc( 100% - 350px);
        float: right; }
        .listings__results .listing-result .success__name {
          font-size: 24px;
          font-weight: 500;
          padding-bottom: 15px; }
        .listings__results .listing-result .success__title {
          font-weight: 300;
          padding-top: 15px; }
          .listings__results .listing-result .success__title p {
            margin: 0px; }
        .listings__results .listing-result .success__partner {
          font-weight: 300;
          color: #F76E83;
          font-size: 18px;
          display: inline-block;
          text-transform: uppercase; }
        .listings__results .listing-result .success__updated {
          padding-bottom: 20px;
          font-weight: 300;
          color: #000000;
          font-size: 14px;
          padding-left: 10px; }
        .listings__results .listing-result .success__clinic {
          padding-right: 30px;
          font-weight: 900; }
        .listings__results .listing-result .success__charts {
          padding-bottom: 20px; }
        .listings__results .listing-result .success__footer {
          padding-bottom: 20px; }
          .listings__results .listing-result .success__footer .success__key {
            width: 200px;
            float: left; }
            @media (max-width: 1099px) {
              .listings__results .listing-result .success__footer .success__key {
                width: 100%;
                padding-bottom: 20px; } }
            .listings__results .listing-result .success__footer .success__key .success__key__rate {
              padding-left: 25px;
              background-image: url("../img/grey_key.svg");
              background-position: center left;
              background-size: 15px;
              background-repeat: no-repeat; }
            .listings__results .listing-result .success__footer .success__key .success__key__rate--orange {
              padding-left: 25px;
              background-image: url("../img/orange_key.svg");
              background-position: center left;
              background-size: 15px;
              background-repeat: no-repeat; }
              .listings__results .listing-result .success__footer .success__key .success__key__rate--orange[data-label-value="0 0 0"] {
                display: none; }
          .listings__results .listing-result .success__footer .success__buttons {
            width: 400px;
            float: right; }
            @media (max-width: 1099px) {
              .listings__results .listing-result .success__footer .success__buttons {
                width: 100%; } }
            .listings__results .listing-result .success__footer .success__buttons a {
              width: calc(50% - 5px);
              float: left;
              display: inline-block;
              margin: 0px !important; }
            .listings__results .listing-result .success__footer .success__buttons a:last-child {
              float: right; }
      .listings__results .listing-result .price {
        width: calc( 100% - 350px);
        min-width: calc( 100% - 350px);
        float: right; }
        .listings__results .listing-result .price__name {
          font-size: 24px;
          font-weight: 500;
          padding-bottom: 15px; }
        .listings__results .listing-result .price__partner {
          font-weight: 300;
          color: #F76E83;
          font-size: 18px;
          display: inline-block;
          text-transform: uppercase; }
        .listings__results .listing-result .price__clinic {
          padding-right: 30px;
          font-weight: 900; }
        .listings__results .listing-result .price__tables {
          padding-bottom: 0px;
          margin-left: -8px;
          margin-top: -8px; }
        .listings__results .listing-result .price__footer {
          padding-bottom: 20px; }
          .listings__results .listing-result .price__footer .price__buttons {
            width: 400px;
            float: right; }
            @media (max-width: 1099px) {
              .listings__results .listing-result .price__footer .price__buttons {
                width: 100%; } }
            .listings__results .listing-result .price__footer .price__buttons a {
              width: calc(50% - 5px);
              float: left;
              display: inline-block;
              margin: 0px !important; }
            .listings__results .listing-result .price__footer .price__buttons a:last-child {
              float: right; }
      @media (max-width: 899px) {
        .listings__results .listing-result {
          display: block; }
          .listings__results .listing-result .toggle {
            display: none !important; }
          .listings__results .listing-result .rating {
            width: 35%;
            min-width: 35%;
            padding: 0px;
            padding-right: 10px; }
          .listings__results .listing-result .details, .listings__results .listing-result .success, .listings__results .listing-result .price {
            width: 65%;
            min-width: 65%;
            padding: 0px;
            padding-left: 10px; }
          .listings__results .listing-result .ctas {
            width: 100%;
            padding: 0px; }
            .listings__results .listing-result .ctas__distance {
              display: none; }
            .listings__results .listing-result .ctas__book-appointment a {
              width: calc(50% - 5px);
              float: left;
              display: inline-block;
              margin: 0px !important; }
            .listings__results .listing-result .ctas__book-appointment a:last-child {
              float: right; } }
      @media (max-width: 599px) {
        .listings__results .listing-result {
          padding: 15px; }
          .listings__results .listing-result .rating {
            width: 100%;
            min-width: 100%;
            padding: 0px; }
          .listings__results .listing-result .details {
            width: 100%;
            min-width: 100%;
            padding: 0px; }
            .listings__results .listing-result .details__discount {
              display: none !important; }
            .listings__results .listing-result .details__nhs {
              display: none !important; }
          .listings__results .listing-result .success, .listings__results .listing-result .price {
            width: 100%;
            min-width: 100%;
            padding: 0px; }
          .listings__results .listing-result .success-bottom, .listings__results .listing-result .price-bottom {
            width: 100%;
            min-width: 100%;
            float: left;
            padding: 0px;
            padding-top: 20px; }
          .listings__results .listing-result .ctas__book-appointment a {
            font-size: 14px; }
          .listings__results .listing-result .ctas__discount {
            display: block !important; }
          .listings__results .listing-result .ctas__nhs {
            display: block !important;
            width: 100%;
            float: left; }
          .listings__results .listing-result .price__buttons a, .listings__results .listing-result .success__buttons a {
            font-size: 14px; }
          .listings__results .listing-result .patients {
            float: left;
            width: 50%;
            padding-bottom: 20px;
            padding-top: 20px;
            width: calc(50% - 10px); }
          .listings__results .listing-result .patients:last-child {
            float: right;
            width: calc(50% - 10px);
            padding-bottom: 20px;
            padding-top: 20px; } }
  .listings__pagination {
    padding: 20px;
    padding-bottom: 50px;
    padding-top: 50px;
    background-color: #fafafa;
    text-align: center;
    margin-top: 50px; }
    .listings__pagination ul {
      margin: 0px !important; }
    .listings__pagination li {
      font-size: 16px;
      display: inline-block;
      padding-left: 5px;
      padding-right: 5px;
      overflow: hidden; }
      .listings__pagination li a {
        height: 40px;
        width: 40px;
        display: block;
        padding-top: 7px;
        color: #aaaaaa;
        overflow: hidden;
        border-radius: 50%; }
      .listings__pagination li a.active {
        background: #FFA328;
        color: #ffffff; }
      .listings__pagination li a:hover {
        background-color: transparent;
        border: 1px solid #aaaaaa;
        color: #aaaaaa;
        padding-top: 6px; }
    .listings__pagination .double-prev {
      background-image: url("../img/double-left.svg");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-size: 12px; }
    .listings__pagination .single-prev {
      background-image: url("../img/left.svg");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-size: 7px; }
    .listings__pagination .double-next {
      background-image: url("../img/double-right.svg");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-size: 12px; }
    .listings__pagination .single-next {
      background-image: url("../img/right.svg");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-size: 7px; }
    .listings__pagination .dot-divider {
      background-image: url("../img/dot_divider.svg");
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      background-size: 15px;
      width: 30px;
      height: 40px;
      display: inline-block; }

.help-tip {
  position: absolute;
  left: 35px;
  top: -21px;
  background: #3ADBB2;
  color: #ffffff;
  border: 3px solid #3ADBB2;
  font-size: 17px;
  padding: 10px;
  width: 280px;
  box-shadow: 0 0 12px 0 #D8D8D8;
  z-index: 99; }

.help-tip:after {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #3ADBB2;
  border-width: 10px;
  margin-top: -10px; }

.control {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 18px; }

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #ffffff;
  border: 1px solid #a0a0a0;
  border-radius: 50%; }

.control--radio .control__indicator {
  border-radius: 50%; }

.control input:disabled ~ .control__indicator {
  background: #3ADBB2;
  opacity: 0.6;
  pointer-events: none; }

.control__indicator:after {
  content: '';
  position: absolute;
  display: none; }

.control input:checked ~ .control__indicator:after {
  display: block; }

.control--checkbox .control__indicator:after {
  left: 3px;
  top: 3px;
  width: 12px;
  height: 12px;
  background: #3ADBB2;
  border-radius: 50%; }

.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b; }

.control--radio .control__indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #fff; }

.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b; }

.select {
  position: relative;
  display: inline-block;
  margin-bottom: 15px;
  width: 100%; }

.select select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
  outline: 0;
  border: 0;
  border-radius: 0;
  background: #e6e6e6;
  color: #7b7b7b;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none; }

.select select::-ms-expand {
  display: none; }

.select select:hover,
.select select:focus {
  color: #000;
  background: #ccc; }

.select select:disabled {
  opacity: 0.5;
  pointer-events: none; }

.select__arrow {
  position: absolute;
  top: 16px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 8px 5px 0 5px;
  border-color: #7b7b7b transparent transparent transparent; }

.select select:hover ~ .select__arrow,
.select select:focus ~ .select__arrow {
  border-top-color: #000; }

.select select:disabled ~ .select__arrow {
  border-top-color: #ccc; }

.chart-horizontal {
  width: 100%;
  height: 120px;
  position: relative;
  margin-bottom: 10px; }
  .chart-horizontal__inner {
    position: absolute;
    width: 100%;
    height: calc( 100% - 30px);
    left: 0px;
    top: 0px; }
  .chart-horizontal__bar-top {
    position: absolute;
    left: 0%;
    top: 20%;
    width: 100%;
    height: 20%; }
    .chart-horizontal__bar-top .chart-horizontal__bar {
      width: 50%;
      background: #A0A0A0;
      height: 100%;
      border-radius: 0px 10px 10px 0px; }
  .chart-horizontal__bar-bottom {
    position: absolute;
    left: 0%;
    top: 60%;
    width: 100%;
    height: 20%; }
    .chart-horizontal__bar-bottom .chart-horizontal__bar {
      width: 50%;
      background: #FFA328;
      height: 100%;
      border-radius: 0px 10px 10px 0px; }
  .chart-horizontal__percentage {
    position: absolute;
    left: 0%;
    top: 0px;
    width: 60px;
    padding-left: 10px;
    font-size: 16px; }
    .chart-horizontal__percentage[data-label-value="0"] {
      display: none; }
  .chart-horizontal__label {
    position: absolute;
    bottom: 0px; }
  .chart-horizontal__grid-line-1 {
    position: absolute;
    top: 0px;
    left: 20%;
    width: 1px;
    height: calc( 100% - 30px);
    background-color: #d8d8d8; }
  .chart-horizontal__grid-line-2 {
    position: absolute;
    top: 0px;
    left: 40%;
    width: 1px;
    height: calc( 100% - 30px);
    background-color: #d8d8d8; }
  .chart-horizontal__grid-line-3 {
    position: absolute;
    top: 0px;
    left: 60%;
    width: 1px;
    height: calc( 100% - 30px);
    background-color: #d8d8d8; }
  .chart-horizontal__grid-line-4 {
    position: absolute;
    top: 0px;
    left: 80%;
    width: 1px;
    height: calc( 100% - 30px);
    background-color: #d8d8d8; }

.chart-vertical {
  width: 100%;
  height: 250px;
  position: relative;
  margin-bottom: 10px; }
  .chart-vertical__inner {
    position: absolute;
    width: 100%;
    height: calc( 100% - 50px);
    left: 0px;
    top: 0px; }
  .chart-vertical__bar-top {
    position: absolute;
    left: 35%;
    bottom: 0%;
    width: 10%;
    height: 100%;
    max-width: 20px; }
    .chart-vertical__bar-top .chart-vertical__bar {
      height: 50%;
      background: #A0A0A0;
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      bottom: 0px;
      position: absolute; }
  .chart-vertical__bar-bottom {
    position: absolute;
    bottom: 0%;
    left: 55%;
    height: 100%;
    width: 10%;
    max-width: 20px; }
    .chart-vertical__bar-bottom .chart-vertical__bar {
      height: 50%;
      background: #FFA328;
      width: 100%;
      border-radius: 10px 10px 0px 0px;
      bottom: 0px;
      position: absolute; }
  .chart-vertical__percentage {
    position: absolute;
    left: 0%;
    bottom: 0px;
    width: 100%;
    text-align: center;
    margin-left: -3px;
    font-size: 16px;
    padding-bottom: 3px; }
    @media (max-width: 949px) {
      .chart-vertical__percentage {
        font-size: 13px; } }
    .chart-vertical__percentage[data-label-value="0"] {
      display: none; }
  .chart-vertical__label {
    position: absolute;
    bottom: 0px;
    width: 100%;
    text-align: center; }
  .chart-vertical__grid-line-1 {
    position: absolute;
    left: 0px;
    top: 20%;
    height: 1px;
    width: calc( 100%);
    background-color: #d8d8d8; }
  .chart-vertical__grid-line-2 {
    position: absolute;
    left: 0px;
    top: 40%;
    height: 1px;
    width: calc( 100%);
    background-color: #d8d8d8; }
  .chart-vertical__grid-line-3 {
    position: absolute;
    left: 0px;
    top: 60%;
    height: 1px;
    width: calc( 100%);
    background-color: #d8d8d8; }
  .chart-vertical__grid-line-4 {
    position: absolute;
    left: 0px;
    top: 80%;
    height: 1px;
    width: calc( 100%);
    background-color: #d8d8d8; }
  .chart-vertical__grid-line-5 {
    position: absolute;
    left: 0px;
    top: 100%;
    height: 1px;
    width: calc( 100%);
    background-color: #d8d8d8; }

.pricing-table__wrapper {
  margin-bottom: 80px; }
  .pricing-table__wrapper h3 {
    color: #FFA328;
    font-weight: bold;
    margin-bottom: 40px; }

.faq-table__wrapper {
  margin-bottom: 80px; }
  .faq-table__wrapper h3 {
    color: #FFA328;
    font-weight: bold;
    margin-bottom: 40px; }

.pricing-table {
  background: transparent;
  border: 0px solid white; }
  @media (max-width: 400px) {
    .pricing-table {
      font-size: .9rem; } }
  .pricing-table .price-cell {
    width: 150px;
    min-width: 80px;
    text-align: right; }
    @media (max-width: 400px) {
      .pricing-table .price-cell {
        width: 150px;
        min-width: 60px; } }
  .pricing-table .discounted-price {
    color: #FFA328;
    font-weight: 900; }
  .pricing-table .original-price {
    text-decoration: line-through;
    color: #A0A0A0; }
  .pricing-table .no-strike {
    text-decoration: none !important; }
  .pricing-table .price-cell--sub {
    font-size: 15px;
    display: block; }
    @media (max-width: 400px) {
      .pricing-table .price-cell--sub {
        font-size: 13px;
        display: block;
        line-height: 20px; } }
  .pricing-table .price-cell--bg {
    position: relative; }
    .pricing-table .price-cell--bg:before {
      content: '';
      background: white;
      position: absolute;
      left: -8px;
      top: 0px;
      width: calc(100% + 16px);
      height: 100%; }
    .pricing-table .price-cell--bg span {
      position: relative;
      white-space: normal; }
  .pricing-table tr {
    background: url(../img/line.png) !important;
    background-image: url(../img/line.png) !important;
    background-repeat: no-repeat !important;
    background-position: 0px 23px !important; }
    @media (max-width: 1099px) {
      .pricing-table tr {
        background: none !important; } }
  @media (max-width: 999px) {
    .pricing-table td {
      display: block;
      width: 100% !important; } }

.treatments .g {
  padding-bottom: 10px; }

.text-advert {
  text-align: center;
  max-width: 700px;
  margin: 0pt auto;
  padding-bottom: 100px; }
  .text-advert h3 {
    font-weight: bold !important;
    color: #F76E83; }
  .text-advert .btn--secondary {
    padding-left: 90px;
    padding-right: 90px; }

.chart__key {
  width: 100%;
  float: left; }
  @media (max-width: 1099px) {
    .chart__key {
      width: 100%;
      padding-bottom: 20px; } }
  .chart__key .chart__key__rate {
    padding-left: 25px;
    background-image: url("../img/grey_key.svg");
    background-position: center left;
    background-size: 15px;
    background-repeat: no-repeat; }
  .chart__key .chart__key__rate--orange {
    padding-left: 25px;
    background-image: url("../img/orange_key.svg");
    background-position: center left;
    background-size: 15px;
    background-repeat: no-repeat; }

.chart_inner-wrapper {
  width: 50%;
  float: left; }
  @media (max-width: 949px) {
    .chart_inner-wrapper {
      width: 100%; } }

.chart {
  float: left;
  width: 33.333%; }
  @media (max-width: 949px) {
    .chart {
      width: 33.33%; } }
  @media (max-width: 400px) {
    .chart {
      width: 50%; } }

.chart__wrapper--5 .chart {
  float: left;
  width: 16.666%; }
  @media (max-width: 949px) {
    .chart__wrapper--5 .chart {
      width: 33.33%; } }
  @media (max-width: 400px) {
    .chart__wrapper--5 .chart {
      width: 50%; } }

.chart__name {
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 15px; }

.chart__title {
  font-weight: 300;
  padding-top: 15px; }
  .chart__title p {
    margin: 0px; }

.chart__partner {
  font-weight: 300;
  color: #F76E83;
  font-size: 18px;
  display: inline-block;
  text-transform: uppercase; }

.chart__updated {
  padding-bottom: 20px;
  font-weight: 300;
  color: #000000;
  font-size: 14px; }

.chart__clinic {
  padding-right: 30px;
  font-weight: 900; }

.chart__charts {
  padding-bottom: 20px; }

.chart__footer {
  padding-bottom: 20px; }

.chart__wrapper .chart__title {
  width: 100%;
  float: left;
  font-size: 22px !important;
  margin-bottom: 10px;
  font-weight: bold;
  color: #000000; }

.chart__wrapper .chart__date {
  width: 100%;
  float: left;
  font-size: 18px !important;
  margin-bottom: 10px;
  font-weight: 300;
  color: #000000; }

.chart__wrapper .chart__sub {
  width: 100%;
  float: left;
  font-size: 20px !important;
  margin-bottom: 10px;
  font-weight: 300;
  color: #000000;
  margin-bottom: 20px; }

.chart__wrapper .chart__footer {
  width: 100%;
  float: left;
  margin-top: 10px;
  margin-bottom: 100px; }

.chart__wrapper .chart__commentry {
  width: 40%;
  float: left;
  padding-left: 40px; }
  .chart__wrapper .chart__commentry h4 {
    margin-bottom: 10px; }
  @media (max-width: 949px) {
    .chart__wrapper .chart__commentry {
      width: 100%;
      padding-left: 0px;
      padding-top: 20px; } }

.chart__wrapper .chart__commentry--right {
  float: right !important;
  margin-bottom: 70px; }

.chart__wrapper .chart__commentry--full {
  width: 100%;
  float: left;
  margin-top: 20px;
  margin-bottom: 70px; }
  .chart__wrapper .chart__commentry--full h4 {
    margin-bottom: 10px; }

.chart__wrapper .chart__footer--full {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  margin-top: 40px; }
  .chart__wrapper .chart__footer--full .chart__key {
    text-align: center; }
    .chart__wrapper .chart__footer--full .chart__key > div {
      display: inline-block;
      padding-right: 10px;
      padding-left: 30px; }

.chart__wrapper .chart__footer--right {
  margin-bottom: 70px; }
  @media (max-width: 949px) {
    .chart__wrapper .chart__footer--right {
      margin-bottom: 20px !important; } }

.photo-gallery {
  width: 100%; }
  .photo-gallery img {
    width: 100%;
    dispay: block; }

.address-grid {
  text-align: left; }
  .address-grid .address-block {
    min-height: 60px;
    padding-left: 130px;
    background-image: url("../img/map__icon__1.png");
    background-position: 40px top;
    background-size: 60px 60px;
    background-repeat: no-repeat;
    padding-right: 40px;
    margin-bottom: 20px; }
    @media (max-width: 400px) {
      .address-grid .address-block {
        padding-left: 70px;
        background-size: 30px 30px;
        background-position: 20px top; } }
  .address-grid .address__icon-3 {
    background-image: url("../img/location-grey.svg"); }
  .address-grid .address__icon-2 {
    background-image: url("../img/car.svg"); }
  .address-grid .address__icon-1 {
    background-image: url("../img/train.svg"); }
  .address-grid .address-block__station {
    padding-left: 30px;
    background-image: url("../img/underground.svg");
    background-position: left 5px;
    background-size: 20px 20px;
    background-repeat: no-repeat; }
    .address-grid .address-block__station span {
      font-size: 14px; }
  .address-grid .address-block__station.national__rail {
    background-image: url("../img/national_rail.svg"); }
  .address-grid__directions {
    text-align: left;
    padding-left: 40px; }
    @media (max-width: 949px) {
      .address-grid__directions {
        padding-top: 40px;
        padding-left: 0px; } }

.help-tooltip {
  display: flex;
  align-items: top;
  position: relative; }
  .help-tooltip--centered {
    justify-content: center; }
  .help-tooltip__icon {
    min-width: 20px;
    max-width: 20px;
    position: relative; }
    .help-tooltip__icon img {
      display: block; }
  .help-tooltip__content {
    padding-right: 10px; }
  .help-tooltip__tip {
    position: fixed;
    left: 0px;
    top: 0px;
    min-height: 200px;
    padding: 20px;
    background: white;
    width: 50vw;
    border: 1px solid black;
    max-width: 300px; }
  .help-tooltip__icon:hover img {
    opacity: 0.33; }

@media (max-width: 949px) {
  .help-tooltip__icon {
    display: none !important; }
  .help-tooltip--center {
    justify-content: center; } }

.tooltip_templates {
  display: none; }

.tooltip_templates__inner {
  max-width: 400px;
  padding: 10px;
  font-family: Lato, sans-serif !important;
  font-weight: bold !important;
  line-height: 1.3 !important; }
  .tooltip_templates__inner p:last-child {
    margin-bottom: 0px; }

.rating__rating .help-tooltip__icon {
  min-width: 22px;
  max-width: 22px;
  position: relative; }
  .rating__rating .help-tooltip__icon img {
    display: block; }

.address-grid--contacts {
  margin-bottom: 50px; }
  @media (max-width: 949px) {
    .address-grid--contacts {
      width: 100%;
      max-width: 400px;
      margin: 0pt auto; } }

#map {
  width: 100%;
  height: 600px; }

.profile__compare {
  text-align: center;
  padding-bottom: 70px;
  padding-top: 20px; }
  .profile__compare a {
    width: 500px;
    max-width: 90%; }

.contact-heading {
  margin-top: -25px; }

.fixed-compare {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: white;
  border-top: 1px solid #aaaaaa;
  padding-top: 10px;
  z-index: 2000; }

.fixed-compare-button {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: white;
  border-top: 1px solid #aaaaaa;
  padding-top: 30px;
  padding-bottom: 30px;
  z-index: 2000; }
  .fixed-compare-button a {
    box-shadow: 0 0 12px 0 #D8D8D8; }

#listings-select {
  margin-top: 40px;
  margin-bottom: 40px; }
  @media (max-width: 552px) {
    #listings-select {
      margin-bottom: 0px; } }

.listing-disclaimer {
  font-weight: 300;
  color: #000000;
  font-size: 14px !important;
  position: absolute;
  bottom: 10px;
  right: 25px; }

.listing-disclaimer p {
  font-weight: 300;
  color: #000000;
  font-size: 14px !important;
  margin: 0px;
  padding: 0px; }

.explainer-strip {
  margin-bottom: 20px; }
  .explainer-strip__inner {
    text-align: center;
    font-size: 15px; }
    .explainer-strip__inner p {
      margin-bottom: 0px;
      color: #F76E83; }

.inline-hide-on-mobile {
  display: none !important; }

@media (min-width: 551px) {
  .inline-hide-on-mobile {
    display: inline-block !important; } }

.listings__results .listing-result {
  padding-bottom: 40px !important; }

.author-center {
  padding: 10px;
  text-align: center; }
  .author-center .author h5 {
    font-size: 0.8rem; }

/**
 * Global styles
 */
/**
 * Global app/site styles
 * =================================
 */
body {
  background-color: #ffffff;
  min-width: 320px; }

/**
 * Default layout container
 */
.l-container {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto; }

.l-container-contact {
  width: 90%;
  max-width: 1100px;
  margin: 0 auto; }
  .l-container-contact h1 {
    margin-bottom: 30px; }

.l-container-restricted {
  width: 90%;
  max-width: 1000px;
  margin: 0 auto; }

.l-container-huge {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto; }

/**
 * Helper/utility classes
 */
/**
 * Helper classes
 * =================================
 * Non-semantic helper classes
 */
/**
 * Clearfix
 * http://www.cssmojo.com/latest_new_clearfix_so_far/
 * See mixins/_utility.scss for a mixin version of this
 */
.clearfix::after {
  content: "";
  display: table;
  clear: both; }

.ir {
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  direction: ltr;
  display: block;
  overflow: hidden;
  text-align: left;
  text-indent: -999em; }
  .ir br {
    display: none; }

.is-hidden {
  display: none !important;
  visibility: hidden !important; }

.is-shown {
  display: block; }

.is-visuallyHidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .is-visuallyHidden.focusable:active, .is-visuallyHidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto; }

.is-invisible {
  visibility: hidden; }

.l-floatLeft {
  float: left; }

.l-floatRight {
  float: right; }

.l-clear {
  clear: both;
  float: none; }

.l-mb0 {
  margin-bottom: 0 !important; }

.l-mt0 {
  margin-top: 0 !important; }

* {
  -webkit-tap-highlight-color: transparent !important; }

/* A hack for HTML5 contenteditable attribute on mobile */
textarea[contenteditable] {
  -webkit-appearance: none; }

.promote-layer {
  /*
	This may promote the layer to a composited
	layer.

	Replace with will-change when available

	#perfmatters
	*/
  backface-visibility: hidden; }

.transition {
  transition: all 300ms cubic-bezier(0.77, 0, 0.175, 1); }

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  touch-action: manipulation; }

.muted {
  opacity: .5; }

.svg-full {
  width: 100%;
  height: 100px;
  max-width: 80px !important; }

.svg-plus {
  width: 50%;
  height: 50%; }

@media (max-width: 949px) {
  .hide-mid {
    display: none !important; } }

.feature__text {
  min-width: 135px; }

.l-container--r {
  max-width: 70%; }

.feature-strip {
  max-width: 1000px;
  margin: 0pt auto; }
  .feature-strip .feature-strip__icon {
    width: 24%;
    float: left; }
  .feature-strip .feature-strip__plus {
    width: 7%;
    float: left;
    margin-left: 3%;
    margin-right: 4%; }
  .feature-strip > div {
    padding-bottom: 40px; }
  @media (max-width: 849px) {
    .feature-strip {
      max-width: 270px;
      margin: 0pt auto; }
      .feature-strip .feature-strip__plus {
        display: none; }
      .feature-strip .feature-strip__icon {
        width: 100%;
        float: left; } }

footer .has-floating-label .form__floating-label, .contact-strip__form .has-floating-label .form__floating-label, .learn-search__form .has-floating-label .form__floating-label, .listings-search__form .has-floating-label .form__floating-label, .listings__filters .has-floating-label .form__floating-label, .profile__filters .has-floating-label .form__floating-label {
  color: #aaaaaa; }

footer .form-input, .contact-strip__form .form-input, .learn-search__form .form-input, .listings-search__form .form-input, .listings__filters .form-input, .profile__filters .form-input {
  color: #aaaaaa !important; }

footer .form-controlGroup-inputWrapper--select .form-input--select, .contact-strip__form .form-controlGroup-inputWrapper--select .form-input--select, .learn-search__form .form-controlGroup-inputWrapper--select .form-input--select, .listings-search__form .form-controlGroup-inputWrapper--select .form-input--select, .listings__filters .form-controlGroup-inputWrapper--select .form-input--select, .profile__filters .form-controlGroup-inputWrapper--select .form-input--select {
  color: #aaaaaa; }

.form-input--select {
  padding-right: 50px; }

.contact-strip__form {
  width: 100%; }
  .contact-strip__form .form-input, .contact-strip__form select {
    background: transparent !important;
    color: #d8d8d8 !important;
    border: 1px solid #a0a0a0 !important; }
  .contact-strip__form .g-col {
    padding-bottom: 0px !important; }
  .contact-strip__form ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: 1px; }
  .contact-strip__form ::-moz-placeholder {
    /* Firefox 19+ */
    color: 1px; }
  .contact-strip__form :-ms-input-placeholder {
    /* IE 10+ */
    color: 1px; }
  .contact-strip__form :-moz-placeholder {
    /* Firefox 18- */
    color: 1px; }
  .contact-strip__form .form-controlGroup-inputWrapper--select::after {
    color: 1px; }

.contact-strip__submit {
  height: 100%;
  background-position: center center;
  background-size: 25px;
  background-repeat: no-repeat;
  background-image: url("../img/right-arrow.svg");
  background-color: transparent !important;
  border: 1px solid #aaaaaa !important; }
  .contact-strip__submit:hover {
    background-color: rgba(0, 0, 0, 0.05) !important; }

.search_submit {
  min-height: 50px;
  height: 100%;
  background-position: center center;
  background-size: 25px;
  background-repeat: no-repeat;
  background-image: url("../img/search-icon-grey.svg");
  background-color: transparent !important;
  border: 1px solid #aaaaaa !important; }
  .search_submit:hover {
    background-color: rgba(0, 0, 0, 0.05) !important; }

@media (max-width: 949px) {
  .half-baseline {
    padding-bottom: 20px !important; } }

.l-container--full {
  width: 100%; }

.contact-strip__submit {
  min-height: 59px; }

#how-we-can-help .l-container {
  overflow: hidden; }

.grey-link {
  color: #999 !important;
  text-decoration: underline !important; }

.limit-center {
  width: 100%;
  max-width: 720px;
  margin: 0pt auto;
  margin-bottom: -30px; }

.bold {
  font-weight: bold !important; }

.not-bold {
  font-weight: 300 !important; }

.specialist__bio a {
  color: #000000;
  text-decoration: underline; }

.image-and-content__content table tr td {
  padding: 0px !important; }

.image-and-content__content table tr {
  background: transparent !important; }

.sitemap {
  margin-top: 30px;
  margin-bottom: 60px; }

.sitemap h1 {
  font-size: 30px !important; }

.pre-wrap-all {
  white-space: pre-wrap; }

@media (min-width: 951px) and (max-width: 1199px) {
  .footer-form-left {
    width: 100%;
    min-width: 100%; }
  .footer-form-right {
    width: 100%;
    min-width: 100%; } }

.photo-gallery__wrapper {
  padding-left: 80px;
  padding-right: 80px;
  position: relative; }
  @media (max-width: 949px) {
    .photo-gallery__wrapper {
      padding-left: 0px;
      padding-right: 0px; } }

.photo-gallery__controls {
  list-style: none; }
  .photo-gallery__controls .prev {
    display: block;
    width: 50px;
    height: 50px;
    background: black;
    position: absolute;
    left: 0px;
    top: 50%;
    margin-top: -25px;
    background-position: center center;
    background-size: 15px;
    background-repeat: no-repeat;
    background-image: url("../img/arrow_grey.svg");
    background-color: transparent !important;
    cursor: pointer;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
    @media (max-width: 949px) {
      .photo-gallery__controls .prev {
        display: none !important; } }
  .photo-gallery__controls .next {
    display: block;
    width: 50px;
    height: 50px;
    background: black;
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -25px;
    background-position: center center;
    background-size: 15px;
    background-repeat: no-repeat;
    background-image: url("../img/arrow_grey.svg");
    background-color: transparent !important;
    cursor: pointer;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
    @media (max-width: 949px) {
      .photo-gallery__controls .next {
        display: none !important; } }

.g-recaptcha {
  margin-top: 30px; }

.show-on-mobile {
  display: block; }

.hide-on-mobile {
  display: none; }

@media (min-width: 551px) {
  .show-on-mobile {
    display: none !important; }
  .hide-on-mobile {
    display: block !important; } }

.negative-top {
  margin-top: -8px; }

/**
 * Print styles
 */
/**
 * Print styles
 * =================================
 */
@media print {
  * {
    transition: none !important;
    font-family: Arial !important; }
  *,
  *:before,
  *:after,
  *:first-letter {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important; }
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  /*
	 * Don't show links for images, or javascript/internal links
	 */
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group;
    /* h5bp.com/t */ }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: .5cm; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  #header, .social-strip, .specialist__search, .overview__book, .other-specialists, #social-panel, .footer, #masthead, .print-page, .rating__specialist__photo, .image-and-content__image, .back-link, .listings__search, .overview__rating {
    display: none !important; }
  .specialist__specialist .g-span7--mid, .specialist__specialist .g-span4--mid {
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: 100% !important; }
  .specialist__heading {
    display: none; }
  .specialist__specialist .specialist__heading {
    text-align: left; }
  .specialist__specialist .g-col--spacer {
    display: none; }
  .printable-image {
    display: block !important; }
  .specialist__section {
    margin-top: -40px !important; }
  .publication__image {
    display: none !important; }
  .printable-logo {
    display: block !important;
    margin-left: 35px;
    margin-top: 30px; }
  .specialist__specialist {
    display: block !important; }
  .specialist__specialist .g-col, .specialist__specialist .g {
    width: 100% !important;
    display: block !important;
    postion: relative; } }
